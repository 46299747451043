import React, { useEffect } from 'react';
import { SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Container } from '@mui/material';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	setStatus?: (status: SignupStatus)=>{}
}

export default function MailSent(props:Props) {
	const [t] = useTranslation('auth')

	useEffect(() => {
		props.setStatus("signup");
	}, [])

	return <>
		<Helmet>
			<title>{t('Account created')}</title>
		</Helmet>
		<Container className="auth-page" maxWidth="md">
			<h2>{t('Success!')}</h2>
			<p>{t('You will receive a confirmation email shortly.')}</p>
		</Container>
	</>
}