import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { PasswordStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Container, Grid } from '@mui/material';
import tKey from 'Common/tKey';
import React, { useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	setStatus?: (status: PasswordStatus)=>{}
}

export default function ForgotPasswordSent(props:Props) {
	const [t] = useTranslation('auth')
	const [tR] = useTranslation('routes')

	useEffect(() => {
		props.setStatus("reset");
	}, [])

	return <>
		<Helmet>
			<title>{t('Password was reset')}</title>
		</Helmet>

		<Container className="auth-page auth-page--forgot" maxWidth="md">
			<Container maxWidth="sm">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h2>{t('Your password was reset')}</h2>
					</Grid>
					<Grid item xs={12}>
						<p><Trans
							t={t}
							i18nKey={tKey('You can now <link>login with your new password</link>.')}
							components={{
								link: <Link to={tR('/auth/login')}/>
							}}
						/></p>
					</Grid>
				</Grid>
			</Container>
		</Container>
	</>
}