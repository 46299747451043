import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { OnChangeLockRatio } from '@af-create/Document/_actions/DocumentActions';
import LockRatioButton, { Props } from '../_components/LockRatioButton';

const mapStateToProps = (store:Immutable.Record<any>, props: Props) => ({
	lockRatio: store.getIn(['create', 'document', 'lockRatio']),
	isAttached: store.hasIn(['create', 'document', 'attachedModule']) && store.getIn(['create', 'document', 'attachedModule']) != null
});

const mapDispatchToProps = (dispatch: Dispatch, props: Props) => ({
	toggleLockRatio: (lockRatio: boolean) => dispatch(OnChangeLockRatio(lockRatio)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LockRatioButton);