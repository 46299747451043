import ModalContainer from '@af-common/Common/_containers/ModalContainer';
import { IUserCreditCardStore } from '@af-common/UserData/_stores/UserCreditCardsStore';
import { Button, Card, Grid, Typography, Skeleton } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import CreditCardContainer from '../_containers/CreditCardContainer';
import CreditCardFormContainer from '../_containers/CreditCardFormContainer';
import Loader from '@af-common/Common/_components/Loader';
import ReCaptcha from "@af-common/Common/_components/ReCaptcha";
import config from '../../../../../../config/config.json'


export type Props = {
	disabled?: boolean
	creditCards?: Immutable.OrderedMap<string, Immutable.Record<IUserCreditCardStore>>
	loading?: boolean
	cart_loading?: boolean
	getCreditCards?: Function
	callback?: () => void
	onToast?: Function
};

type State = {
	formOpened: boolean
	modalOpened: boolean
	disabled: boolean
}

export default class CreditCardSelector extends React.Component<Props, State> {
	private recaptchaRef:ReCaptcha;

	state = { formOpened: false, modalOpened: false, disabled: false}

	componentDidMount() {
		this.props.getCreditCards();
	}

	private onSelect = () => {
		this.setState({modalOpened: false, formOpened: false});
		if(this.props.callback) this.props.callback();
	}

	private selectorClick = () => {
		if(this.props.creditCards.size) this.setState({modalOpened: true})
		else this.setState({formOpened: true});
	}

	private onCardSubmit = (id: string) => {
		this.setState({disabled: true});
		this.recaptchaRef.execute(id);
	}

	private onCardPay = async (id: string) => {
		let recaptchaRef = this.recaptchaRef;
		let setState = this.setState.bind(this);
		let props = this.props;
		let onSelect = this.onSelect;
		let error = "";

		let recaptchaToken = recaptchaRef.getResponse();

		if(!recaptchaToken) error = "An error occurred";

		setState({disabled: true});

		let data = new FormData();
		data.append('recaptchaToken', recaptchaToken);
		data.append('id_card', id);

		let success = await fetch('ajax/checkout/stripe/pay-with-card', {method: 'POST', body: data})
			.then(response => {
				if(!response.ok) throw new Error("An error occurred");
				return response;
			})
			.then(response => response.json())
			.then(function(response:any) {
				setState({disabled: false});
				recaptchaRef.reset();
				if(!response.data.success){
					if(response.data.error) {
						props.onToast(response.data.error);
					} else {
						props.onToast("An error occurred");
					}
				} else onSelect();
			}).catch((e) => {
				setState({disabled: false});
				recaptchaRef.reset();
				props.onToast("An error occurred");
			});
	}

	render() {
		const disabled = this.props.disabled || this.state.disabled || this.props.loading || this.props.cart_loading;

		const items = this.props.creditCards.entrySeq().map((creditCard: [string, Immutable.Record<IUserCreditCardStore>], index: number) =>
			<CreditCardContainer key={creditCard[0]} noEdit id={creditCard[0]} displayMode={'browser'} action={{
				execute: () => this.onCardSubmit(creditCard[0]),
				name: "Select",
				icon: "done"
			}}/>
		);

		const contentStyle = disabled ? {opacity: 0.5, pointerEvents: 'none' as 'none'} : {};

		return <>
			<Grid item xs={12} className="item-selector item-selector--button">
				<Button disabled={disabled} color="primary" className="dark" onClick={this.selectorClick}>
					<span>Pay by Credit Card</span>
				</Button>
			</Grid>

			<ModalContainer darken opened={this.state.modalOpened} animate onOpen={() => this.setState({modalOpened: true})} onClose={() => this.setState({modalOpened: false})} zIndex={1000} content={
				<div className="library-browser select-cc-payment-modal">
					<Card>
						<Grid container className="library-browser-header">
							<Grid item xs={12}>
								<h3>Saved Credit Cards</h3>
							</Grid>
						</Grid>
						<div className="library-browser-body">
							<Grid container className="pagination-container" style={contentStyle}>
								<Grid item xs={12} className="pagination-container user-cards">
									<Grid container spacing={2}>
										{this.props.loading ? 
											<>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
											</> 
										: items.size ? <>
											<ReCaptcha 
												disabled={disabled}
												ref={e => this.recaptchaRef = e}
												siteKey={config.RECAPTCHA_KEY}
												action="Submit"
												onSubmit={this.onCardPay}
												noDisplay
											/>
											{items}
										</> :
											<Typography className="noitems" variant="body1">No Results</Typography>
										}
									</Grid>
								</Grid>
							</Grid>
						</div>
						<div className="library-browser-footer">
							<Button disabled={disabled} color="secondary" className="secondary-primary" style={{marginRight: '1rem'}} onClick={()=>{
								this.setState({
									formOpened: true
								})
							}}>Add New Card</Button>
							<Button disabled={disabled} color="secondary" className="modal-close">Cancel</Button>
						</div>
						{ disabled && <Loader noModal /> }
					</Card>
				</div>
			}/>

			<CreditCardFormContainer isPayment opened={this.state.formOpened} 
				onClose={()=>{
					this.setState({
						formOpened: false
					})
				}}
				callback={this.onSelect}
			/>
		</>
	}
}