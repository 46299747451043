import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import CartRouter, { Props } from '../_components/CartRouter';
import { GetUserCart } from '@af-common/UserData/_actions/UserCartActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	cart: store.getIn(['userData', 'userCart']),
	loggedIn: store.getIn(['userData', 'loggedIn'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getUserCart: () => dispatch(GetUserCart())
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(CartRouter);