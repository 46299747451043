import React, { ReactNode } from 'react';

type Props = {
    slug: string,
    title: ReactNode
    img: string,
    selected?: boolean,
    onClick?: () => void
}

export default function FabricThumbnail(props:Props) {
    return <div 
        key={props.slug}
        className={"fabric-type-item-wrap"+(props.selected ? " active":"")} 
        onClick={props.onClick}
    >
        <div className="fabric-type-item" style={{ backgroundImage: "linear-gradient(rgba(0, 0, 1, 0), rgba(0, 0, 0, 0.5)), url('" + props.img + "')"}}>
            <h3>
                <div className="align-titles">{props.title}</div>
            </h3>
        </div>
    </div>
}