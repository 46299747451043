import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Address, { Props } from '../_components/Address';
import { UpdateUserAddress, DeleteUserAddress } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	address: store.getIn(['userData', 'userAddresses', props.id]),
	countries: store.getIn(['UIData', 'countries']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	updateAddress: (data:FormData) => dispatch(UpdateUserAddress(data)),
	deleteAddress: (data:FormData) => dispatch(DeleteUserAddress(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Address);