import { IUserCreditCardStore } from '@af-common/UserData/_stores/UserCreditCardsStore';
import { Grid, Card, CardHeader, CardContent, Typography, CardActions, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
export type CreditCardDisplayMode = "account" | "browser" | "cart";

export type CreditCardAction = {
	execute: (id:string) => void,
	name: string,
	icon: string,
}

export type Props = {
	id: string
	creditCard?: Immutable.Record<IUserCreditCardStore>
	deleteCreditCard?: Function
	displayMode?: CreditCardDisplayMode
	action?: CreditCardAction
	noEdit?: boolean
};

type State = {
	deleteConfirm: boolean
}

export default class CreditCard extends React.Component<Props, State> {
	state = { deleteConfirm: false, updating: false }

	private delete = () => {
		let formData = new FormData();
		formData.append("id", this.props.id);
		this.props.deleteCreditCard(formData);
	}

	render = () => {
		const displayMode = this.props.displayMode || "account";
		let size = {};
		switch(displayMode){
			case "cart": size = { xs: 12, sm: 12, lg: 12 }; break;
			case "browser": size = { xs: 12, sm: 6, lg: 4 }; break;
			case "account": size = { xs: 12, sm: 6, lg: 4 }; break;
		}

		if(!this.props.creditCard) return <></>;

		const cardContent = <>
			<CardHeader />
			<CardContent>
				<Typography variant="h5">**** **** **** {this.props.creditCard.get('last4')}</Typography>
				<Typography variant="body2" color="textSecondary">Expires {this.props.creditCard.get('expiration')}</Typography>
				<br />
				<Typography variant="body2">{this.props.creditCard.get('name')}</Typography>
			</CardContent>
			<Divider />
			<CardActions>
				{this.props.noEdit ? <></> : <Button color="secondary" style={this.props.noEdit ? {width: '100%'} : {}} disabled={this.state.updating} size="small" onClick={() => this.setState({deleteConfirm: true})} startIcon={<i className="material-icons">delete</i>}>Delete</Button> }
				<img src={"static/img/account/" + this.props.creditCard.get('brand') + ".svg"} alt={this.props.creditCard.get('brand')} height="32px"/>
			</CardActions>
		</>

		return <>
			<Grid item {...size}>
				<Card className={"account-credit-card" + (this.props.creditCard.get("loading") ? " disabled" : "")}>
					{(this.props.displayMode == "browser" && this.props.action) ? <div className="account-credit-card-browser" onClick={() => this.props.action.execute(this.props.id)}>{cardContent}</div> : cardContent}
				</Card>
			</Grid>

			{ this.props.noEdit ? <></> :
				<Dialog
					open={this.state.deleteConfirm}
					onClose={()=>{this.setState({deleteConfirm: false})}}
					aria-labelledby="delete-credit-card-title"
					aria-describedby="delete-credit-card-description"
				>
					<DialogTitle id="delete-credit-card-title">{"Delete Credit Card"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="delete-credit-card-description">
							Are you sure that you want to delete this credit card?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button color="secondary" onClick={()=>{this.setState({deleteConfirm: false})}}>Cancel</Button>
						<Button color="secondary" className="secondary-primary" onClick={() => {
							this.delete();
							this.setState({deleteConfirm: false});
						}}>Confirm</Button>
					</DialogActions>
				</Dialog>
			}
		</>
	}
}