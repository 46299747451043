import React, { useCallback, useEffect } from 'react';
import { SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Container } from '@mui/material';
import LoginContentContainer from '../_containers/LoginContentContainer';
import { useNavigate } from 'react-router';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export type Props = {
	status?: SignupStatus
	setStatus?: (status: SignupStatus)=>{}
};

export default function Login(props:Props) {
	const [t] = useTranslation('auth')
	const [tR] = useTranslation('routes')
	const navigate = useNavigate()

	const handleRedirects = useCallback((status:SignupStatus) => {
		switch(status) {
			case "success":
				navigate(tR('/account'), { replace: true});
			break;
			case "validation":
				navigate(tR('/auth/account-not-validated'), { replace: true});
			break;
			case "mail":
				navigate(tR('/auth/signup/mail'), { replace: true});
			break;
			case "confirm":
				navigate(tR('/auth/signup/confirm'));
			break;
			case "exists":
				navigate(tR('/auth/signup/exists'));
			break;
		}
	}, [tR, navigate])

	useEffect(() => {
		props.setStatus("signup");
	}, [])

	useEffect(() => {
		handleRedirects(props.status);
	}, [props.status, handleRedirects])

	return <>
		<Helmet>
			<title>{t('Login')}</title>
		</Helmet>

		<Container maxWidth="md">
			{<LoginContentContainer />}
		</Container>
	</>
}