import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Addresses, { Props } from '../_components/Addresses';
import { GetUserAddresses } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	addresses: (store.getIn(['userData', 'userAddresses']) as Immutable.Map<any, Immutable.Record<any>>).filter((address) => {
		return !address.get('deleted') && !address.get('for_cart')
	}),
	loading: store.getIn(['UIData', 'loaders', 'addresses']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getAddresses: () => {dispatch(GetUserAddresses())}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Addresses);