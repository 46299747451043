import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import Stripe, { Props } from '../_components/Stripe';
import { Toast } from '@af-common/UIData/_actions/UIDataActions';
import { GetUserCreditCards } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	currency: store.getIn(['userData', 'userPrefs', 'currency']),
	lang: store.getIn(['userData', 'userPrefs', 'lang']),
	creditCards: store.getIn(['userData', 'userCreditCards']),
	loading: store.getIn(['UIData', 'loaders', 'creditCards']),
	cart_loading: store.getIn(['UIData', 'loaders', 'cart']),
	loggedIn: store.getIn(['userData', 'loggedIn']),
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
	onToast: (text:string) => {dispatch(Toast(text))},
	getCreditCards: () => {dispatch(GetUserCreditCards())}
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(Stripe);