import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import HIDContainer from '@af-create/HID/_containers/HIDContainer';
import Create, { Props } from '../_components/Create';
import HIDEvents from '@af-create/_events/CreateHIDEvents';
import * as HIDActions from '@af-create/HID/_actions/HIDActions';
import { OnCenterDocument } from '@af-create/Tools/_actions/ToolsActions';
import { OnViewerResize } from '@af-create/Viewer/_actions/ViewerActions';
import { OnWindowResize } from '@af-create/Window/_actions/WindowActions';
import { OnLayerListVisibilityToggle } from '@af-create/LayerList/_actions/LayerListActions';
import { OnModuleCreatorOpenToggle } from '@af-create/ModuleCreator/_actions/ModuleCreatorActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	isFullWidth				: store.getIn(['create', 'viewer', 'isFullWidth']),
	windowWidth				: store.getIn(['create', 'window', 'width']),
	windowHeight			: store.getIn(['create', 'window', 'height']),
	viewerWidth				: store.getIn(['create', 'viewer', 'width']),
	viewerHeight			: store.getIn(['create', 'viewer', 'height']),
	selectedLayerId			: store.getIn(['create', 'layerList', 'selectedLayerId']),
	documentStatus			: store.getIn(['create', 'document', 'status']),
	previewFabricEnabled	: store.getIn(['create', 'tools','previewFabric','enabled']),
	hasModules				: store.getIn(['create', 'moduleList', "modules"]).size > 0,
	moduleCreatorOpened		: store.getIn(['create', 'moduleCreator', 'opened']),
	layerListOpened			: store.getIn(['create', 'layerList', 'opened']),
});

const mapDispatchToProps = (dispatch: Dispatch, props: Props) => ({
	initLab: (callback: Function) => callback(dispatch),
	onWindowResize: (width: number, height: number) => dispatch(OnWindowResize(width, height)),
	onViewerResize: (width: number, height: number) => dispatch(OnViewerResize(width, height)),
	onCenterDocument: () => dispatch(OnCenterDocument()),
	onWindowBlur: () => {
		dispatch(HIDActions.OnSpaceUp());
		dispatch(HIDActions.OnModUp());
		dispatch(HIDActions.OnShiftUp());
		dispatch(HIDActions.OnAltUp());
	},
	onLayerListVisibilityToggle: () => dispatch(OnLayerListVisibilityToggle()),
	onModuleCreatorOpenToggle: () => dispatch(OnModuleCreatorOpenToggle())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(HIDContainer(Create, HIDEvents));