import * as React from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Container, Grid, Paper, Typography } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Helmet } from 'react-helmet';

export type Props = {}

export default class NotFound404 extends React.Component<Props> {
	render = () => <>
		<Helmet>
			<title>Page not found</title>
		</Helmet>
		<Header/>
		<TopBanner>
			<h1>Page not found</h1>
		</TopBanner>
		<Footer/>
	</>
}