import React, { useEffect } from 'react';
import { SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { Container, Button, Typography } from '@mui/material';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import WebsiteStore from 'WebsiteStore';
import { useSelector } from 'react-redux';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	status?: SignupStatus
	setStatus?: (status: SignupStatus)=>{}
	resendMail?: ()=>{}
}

export default function NotValidated(props:Props) {
	const [t] = useTranslation('auth')
	const [tR] = useTranslation('routes')
	const navigate = useNavigate()

	const validated = useSelector((state:WebsiteStore) => state.get('userData').get('validated'))
	useEffect(() => {
		if(validated) {
			navigate(tR('/account'))
		}
	},[validated, tR])

	const handleRedirects = () => {
		switch(props.status) {
			case "mail":
				navigate(tR('/auth/signup/mail'));
			break;
		}
	}

	useEffect(() => {
		props.setStatus("signup");
	}, [])

	useEffect(() => {
		handleRedirects();
	}, [props.status])

	return <>
		<Helmet>
			<title>{t('Account not validated')}</title>
		</Helmet>

		<Container className="auth-page auth-page--notvalidated" maxWidth="md">
			<h2>{t('Account not validated')}</h2>
			<Typography variant="body1">{t('You need to validate your email before you can access your account. You should have received an email when signing up. Click on the button below to resend the email with the validation link.')}</Typography>
			<Button onClick={props.resendMail} variant="contained" color="primary">{t('Resend Validation Email')}</Button>
		</Container>
	</>
}