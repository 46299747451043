import { Fabric, FabricCategory as Category } from '@af-common/UIData/_data/Fabrics';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Immutable from 'immutable';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import { useSearchParams } from 'react-router-dom';

export type FabricCategoryContentProps = {
	currentFabric: string
	onSelect: (slug:string) => void
}

export type Props = {
	category: Category
	categoryContent: React.ComponentType<FabricCategoryContentProps>
}

export default function FabricCategory(props:Props) {
	const [selected,setSelected] = useState<string|null>(null);
	const [navOffset,setNavOffset] = useState(0);
	const [searchParams,setSearchParams] = useSearchParams()

	const scrollerRef = useRef<HTMLDivElement>(null);

	const calcNavOffset = useCallback((newValue:number) => {
		setNavOffset(prevValue => {
			let scrollerWidth = scrollerRef.current ? scrollerRef.current.offsetWidth : 0;

			//Limit the scroll diff based on window size
			let diff = Math.min(Math.abs(prevValue - newValue), Math.floor(window.innerWidth / 240)*240);
			newValue = newValue < prevValue ? prevValue - diff : prevValue + diff;

			//Set left and right scroll limits
			newValue = Math.min(newValue, 0);
			newValue = Math.max(newValue, -Math.max(0, scrollerWidth - window.innerWidth + 30))

			return newValue
		});
	}, []);

	const onResize = useCallback(() => {
		calcNavOffset(navOffset)
	},[navOffset, calcNavOffset])

	const changeFabric = useCallback((key:string) => {
		if(props.category.fabrics.indexOf(key) == -1) key = props.category.default;
		setSelected(key);
	}, [props.category])

	useEffect(() => {
		if(searchParams.has('f')) {
			changeFabric(searchParams.get('f'));
		} else {
			changeFabric(props.category.default);
		}
	}, [props.category])

	useEffect(() => {
		

		if(document) {
			window.addEventListener('resize', onResize);
		}

		return () => {
			if(document) {
				window.removeEventListener('resize', onResize);
			}
		}
	}, [changeFabric, onResize])

	useEffect(() => {
		if(!selected) return;

		setSearchParams({
			f: selected
		}, {
			replace: true,
			preventScrollReset: true,
		})
	}, [selected])

	let CategoryContent = props.categoryContent;

	return <div className="fabric-page-container">
		<Header/>
		<CategoryContent
			currentFabric={selected} 
			onSelect={(slug) => changeFabric(slug)}
		/>
		<Footer/>
	</div>
}