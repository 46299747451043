import { createTheme } from "@mui/material";
import mediaQuery from 'css-mediaquery';

export default createTheme({
    palette: {
        primary: {main: '#1c1f59'},
        secondary: {main: '#ffae96'}
    },
    typography: {
        fontFamily: [
            'Open Sans',
            'sans-serif'
        ].join(',')
    },
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                ssrMatchMedia: (query) => ({
                    matches: mediaQuery.match(query, {
                        width: '1600px',
                    })
                })
            }
        }
    }
});