import * as React from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export default function AccountValidated() {
	const [tR] = useTranslation('routes');

	return <>
		<Helmet>
			<title>Account validated</title>
		</Helmet>

		<Container className="auth-page" maxWidth="md">
			<h2><T>Your account has been validated</T></h2>	
			<p><T>You can now proceed to <Link to={tR('/account')}>your account</Link>.</T></p>
		</Container>
	</>
}