import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FabricCategoryContentProps } from './FabricCategory';
import tKey from 'Common/tKey';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';

export default function Polyester(props: FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/polyester');

	return <>
		<Helmet>
			<title>{t('Custom printed Polyester')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of polyester fabrics.')} />
		</Helmet>

		<div className="top-banner-fabrics fabric-polyester">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Polyester Fabric</span> on demand, <br />no minimums!')}
									components={{
										span: <span />,
										br: <br />,
									}}
								/>
							</h1>
							<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br />
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="heavy_satin"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Heavy</family> Satin')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/poly-heavy-satin/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="swimsuit_knit"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Swimsuit</family> knit')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/poly-swimsuit-knit/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="peachskin_jersey"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Peachskin</family> jersey')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/poly-peachskin-jersey/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="velveteen"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Poly</family> Velveteen')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/poly-velveteen/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="poly_twill"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Poly</family> Twill')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/poly-twill/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="poly_canvas"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Poly</family> Canvas')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/poly-canvas/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="scuba_double_knit"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Double Knit</family> Scuba')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/scuba-double-knit/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="performance_spandex"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Performance</family> Spandex')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/performance-spandex/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="poly_chiffon"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Poly</family> Chiffon')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/poly-chiffon/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="spun_polyester"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Spun</family> Polyester')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/spun-polyester/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="minky"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Minky</family>')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/minky/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="double_minky"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Double</family> Minky')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/double-minky/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="silky_knit"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Silky</family> knit')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/silky-knit/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="matte_crepe"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Matte</family> Crepe')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/matte-crepe/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="satin_charmeuse"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Satin</family> Charmeuse')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/satin-charmeuse/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="glitter_performance_knit"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Glitter Performance</family> Knit')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/polyester/glitter-performance-knit/fabric-styled.jpg"
					/>
				</FabricThumbnailList>

			</Container>
		</section>

		<FabricInfo
			active={props.currentFabric === "heavy_satin"}
			slug="heavy_satin"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Heavy</family> Satin')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Heavy satin has a moderate-high surface sheen and sturdy construction that makes it ideal for structured formal garments and accessories. Prints on heavy satin are reflective so the color results are enhanced. Due to the tightly woven surface, satin is as durable as it is pretty!')}
			printDescription={t('All satin fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('58”/147 cm'),
				color: t('White'),
				weight: t('205g/m2'),
				shrinkage: t('none'),
				printThrough: t('Low, around 20%'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/poly-heavy-satin/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/poly-heavy-satin/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/poly-heavy-satin/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/poly-heavy-satin/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Bridal and formal wear.'),
					description: t(''),
				},
				{
					title: t('Accessories'),
					description: t('including bags and pocket squares'),
				},
				{
					title: t(''),
					description: t('Can be used for lining bags and jackets.'),
				},
				{
					title: t(''),
					description: t('Can be used as a sturdy banner material.'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "swimsuit_knit"}
			slug="swimsuit_knit"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Swimsuit</family> knit')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Swimsuit knit is super stretchy that retains its shape and the integrity of its print. The surface has a subtle sheen and is soft and smooth to the touch.')}
			printDescription={t('All swimsuit knit fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% ecopolyester/28% spandex'),
				width: t('58”/147 cm'),
				color: t('White'),
				weight: t('260g/m2'),
				shrinkage: t('none'),
				printThrough: t('Low, around 20%'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/poly-swimsuit-knit/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/poly-swimsuit-knit/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/poly-swimsuit-knit/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/poly-swimsuit-knit/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Stretchy apparel'),
					description: t('including swimwear, leggings and athletic wear.'),
				},
				{
					title: t(''),
					description: t('Swimsuit knit makes excellent compression and shape wear.'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "peachskin_jersey"}
			slug="peachskin_jersey"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Peachskin</family> jersey')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Peachskin is extremely soft to the touch, with a matte surface finish that somewhat mimics the appearance of suede. This stretchy fabric is not rigid and has a loose, casual hang, making it an excellent replacement for jersey.')}
			printDescription={t('All peachskin jersey fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('95% polyester/5% spandex'),
				width: t('58”/147 cm'),
				color: t('White'),
				weight: t('200g/m2'),
				shrinkage: t('none'),
				printThrough: t(''),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/poly-peachskin-jersey/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/poly-peachskin-jersey/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/poly-peachskin-jersey/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/poly-peachskin-jersey/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Lightweight, stretchy clothing and baby clothes.'),
				},
				{
					title: t(''),
					description: t('Garments that touch the skin.'),
				},
				{
					title: t(''),
					description: t('Excellent choice to use for lining.'),
				},
				{
					title: t('Home decor items'),
					description: t('including sheets and pillowcases.'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "velveteen"}
			slug="velveteen"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Poly</family> Velveteen')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Velveteen is a very heavyweight polyester fabric that adds a touch of luxury to any project. Prints on this fabric have a subtle sheen and are soft to the touch. Formal wear is given enhanced elegance with Velveteen.')}
			printDescription={t('All velveteen fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('52”/132 cm'),
				color: t('White'),
				weight: t('390g/m2'),
				shrinkage: t('2.5%'),
				printThrough: t('none'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/poly-velveteen/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/poly-velveteen/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/poly-velveteen/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/poly-velveteen/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Household decor and upholstery.'),
				},
				{
					title: t('Accessories'),
					description: t('including bags and shoes.'),
				},
				{
					title: t(''),
					description: t('Elegant dresses, suits and skirts.'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "poly_twill"}
			slug="poly_twill"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Poly</family> Twill')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('This unique, structured fabric is known for its diagonal weave, which gives the fabric strength and durability. It holds its shape well, while still allowing the item to move and flow.')}
			printDescription={t('All poly twill fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('58”/147 cm'),
				color: t('White'),
				weight: t('205g/m2'),
				shrinkage: t('none'),
				printThrough: t('Low, around 20%'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/poly-twill/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/poly-twill/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/poly-twill/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/poly-twill/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Home decor items'),
					description: t('including pillow cases, tote bags, wall hangings'),
				},
				{
					title: t('Semi structured clothing'),
					description: t('including skirts and hats.'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "poly_canvas"}
			slug="poly_canvas"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Poly</family> Canvas')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Poly Canvas is among our heaviest polyesters, with a distinct woven texture and a smooth, matte surface finish. It is a structured textile with high durability, making it a great choice for household decor.')}
			printDescription={t('All poly canvas fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('58”/147 cm'),
				color: t('White'),
				weight: t('360g/m2'),
				shrinkage: t('none'),
				printThrough: t('none'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/poly-canvas/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/poly-canvas/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/poly-canvas/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/poly-canvas/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Home decor items'),
					description: t('including upholstery projects, pillow cases, drapes'),
				},
				{
					title: t(''),
					description: t('Children’s toys and tote bags'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "scuba_double_knit"}
			slug="scuba_double_knit"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Double Knit</family> Scuba')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Scuba double knit is a very interesting fabric! It\'s 2 layers of tightly knit fabric joined in the middle. Due to the double layer, it has both structure and softness. You can use it to create sculptural garments which makes it a good choice for costuming. Since it\'s a sturdy knit, edges do not always need to be finished as long as they are cleanly cut.')}
			printDescription={t('All scuba double knit fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('95% polyester, 5% spandex'),
				width: t('58”/147 cm'),
				color: t('Soft white'),
				weight: t('400g/m2'),
				shrinkage: t('1-2% in length and width'),
				printThrough: t('none'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/scuba-double-knit/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/scuba-double-knit/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/scuba-double-knit/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/scuba-double-knit/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Masks, structured clothing, bags, accessories, costumes'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "performance_spandex"}
			slug="performance_spandex"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Performance</family> Spandex')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Our performance polyspandex is a fabric that never quits! With its high spandex content, it has excellent recovery and can be worn over and over without losing shape. The fabric naturally wicks moisture away from the body and is easy to care for. Our performance spandex is proudly made in Montreal using an ecopolyster fiber. Ecopolyester uses significantly less water and energy during production than traditional polyester.')}
			printDescription={t('All performance spandex fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('88% polyester, 12% spandex'),
				width: t('44”/112 cm'),
				color: t('Optic white'),
				weight: t('280g/m2'),
				shrinkage: t('1-2% in length and width'),
				printThrough: t('Low, roughly 10%'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/performance-spandex/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/performance-spandex/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/performance-spandex/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/performance-spandex/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Leggings, activewear, bodysuits, swimwear, costumes'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "poly_chiffon"}
			slug="poly_chiffon"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Poly</family> Chiffon')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('A favorite for scarves, polychiffon is a lightweight semi sheer fabric with a high print through. You’ll see your print almost 80% through to the back side. Polychiffon has a matte surface and beautiful drape which makes it a perfect match for high end projects.')}
			printDescription={t('All polyester chiffon fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('54”/137 cm'),
				color: t('Matt White'),
				weight: t('100 g/m2'),
				shrinkage: t('less than 1%'),
				printThrough: t('High'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/poly-chiffon/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/poly-chiffon/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/poly-chiffon/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/poly-chiffon/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Light clothing, scarves and accesories, flowy garments'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "spun_polyester"}
			slug="spun_polyester"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Spun</family> Polyester')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Spun polyester fabric is a 4 way medium weight stretch fabric with a cottony feel. Made from exceptional quality spun fibers, this fabric will withstand heavy use while still looking great. Use it for casual garments or accessories.')}
			printDescription={t('All spun polyester fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('92% polyester/ 8% spandex'),
				width: t('58”/ 147 cm'),
				color: t('Eggshell white'),
				weight: t('280 g/m2'),
				shrinkage: t('less than 1%'),
				printThrough: t('Low'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/spun-polyester/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/spun-polyester/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/spun-polyester/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/spun-polyester/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Heavy weight clothing, blankets, accessories'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "minky"}
			slug="minky"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Minky</family>')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('With a pile height of 2mm, this luxurious single-sided minky fabric feels and prints wonderfully. Use it for projects you want to snuggle up to or where added warmth is your goal. Blankets in this fabric are a must!')}
			printDescription={t('All minky fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('54”/ 137 cm'),
				color: t('Soft white'),
				weight: t('300 g/m2'),
				shrinkage: t('less than 1%'),
				printThrough: t('None'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/minky/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/minky/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/minky/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/minky/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Blankets, winter wear, accessories'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "double_minky"}
			slug="double_minky"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Double</family> Minky')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Double minky is the perfect fabric for projects that require extra comfort! Featuring solid grey minky on the back and a printable white layer on the front, double minky is a game changer for blankets. It’s heavy and warm due to the two layers of fabric fused together. Double minky is highly durable and can be washed over and over without losing it’s magical softness.')}
			printDescription={t('All double minky fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('54”/ 137 cm'),
				color: t('Optic white'),
				weight: t('550 g/m2'),
				shrinkage: t('less than 1%'),
				printThrough: t('None'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/double-minky/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/double-minky/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/double-minky/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/double-minky/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Blankets, winter wear, accessories'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "silky_knit"}
			slug="silky_knit"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Silky</family> knit')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('With a smooth silky feel, this 4 way stretch performance polyester is lightweight and made to move with you. The high spandex content means this fabric has excellent recovery and will bounce back even after heavy use. It’s perfect for performance wear and layering.')}
			printDescription={t('All silky knit fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('85% ecopolyester/ 15% spandex'),
				width: t('52”/132 cm'),
				color: t('Optic white'),
				weight: t('140 g/m2'),
				shrinkage: t('less than 1%'),
				printThrough: t('Medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/silky-knit/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/silky-knit/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/silky-knit/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/silky-knit/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Light clothing, swimwear (with lining) performance wear, draped clothing'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "matte_crepe"}
			slug="matte_crepe"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Matte</family> Crepe')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Matte crepe is a shine-free silk-like fabric that can be used for projects that demand flow and movement. It is opaqe enough for clothing but still lightweight enough to feel airy and comfortable. It’s an economical stand in for silk.')}
			printDescription={t('All matte crepe fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('55”/140 cm'),
				color: t('Bright white'),
				weight: t('90 g/m2'),
				shrinkage: t('less than 1%'),
				printThrough: t('Medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/matte-crepe/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/matte-crepe/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/matte-crepe/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/matte-crepe/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Light clothing, scarves and accesories, flowy garments, lining'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "satin_charmeuse"}
			slug="satin_charmeuse"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Satin</family> Charmeuse')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('If you’re looking for a vegan silk, look no further! Satin charmeuse is a lightweight flowy fabric with a high sheen that mimics silk charmeuse. It has fluid drape and prints are enhanced by the satiny surface which makes it perfect for formalwear, scarves, and lining.')}
			printDescription={t('All satin charmeuse fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('100% polyester'),
				width: t('58”/147 cm'),
				color: t('Bright white'),
				weight: t('95 g/m2'),
				shrinkage: t('less than 1%'),
				printThrough: t('Medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/satin-charmeuse/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/satin-charmeuse/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/satin-charmeuse/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/satin-charmeuse/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Light clothing, scarves and accesories, flowy garments, lining'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "glitter_performance_knit"}
			slug="glitter_performance_knit"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Glitter Performance</family> Knit')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Glitter performance knit is a stretch fabric that combines the versatility of performance fabrics with a whole lot of glamor! The glitter foil finish reflects light and enhances print details. This fabric has a high spandex content with excellent recovery. The glitter finish is highly durable and will withstand frequent use and washing.')}
			printDescription={t('All glitter performance knit fabrics are printed on-demand with our vibrant and permanent sublimation inks.')}
			specs={{
				content: t('88% polyester/12% spandex'),
				width: t('58”/147cm'),
				color: t('Glitter silver/light grey'),
				weight: t('295 g/m2'),
				shrinkage: t('none'),
				printThrough: t('Low 10%'),
			}}
			images={{
				front: "/static/img/pages/fabric/polyester/glitter-performance-knit/fabric-front.jpg",
				back: "/static/img/pages/fabric/polyester/glitter-performance-knit/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/polyester/glitter-performance-knit/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/polyester/glitter-performance-knit/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Dance wear including bodysuits and leggings, accessories, activewear and sportswear'),
				},
			]}
		/>


		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Polyester Fabric?')}</h3>
				<p>{t('Polyester is a broad term that is used to describe any synthetic fabric that is made up of esters. To be more specific, polyester must be 85% or more esters including dy-hydric alcohol and terephthalic acid. Polyester is a   polymer that can be manipulated in various ways to create a huge range of fabrics that are known as man-made synthetics.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{ top: '15rem' }}>
				<img src="/static/img/pages/fabric/polyester/bg-image-1.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('Polyester is an excellent insulator and is therefore used extensively in curtains and carpets to keep heat indoors.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('A Brief History...')}</h3>
				<p>{t('Polyester fiber got its first official patent in 1941 by British scientists John Rex Whinfield and James Tennant Dickson, who had revived previous US-based research that had grown stagnant. It wasn\'t until the 1970\'s, however, that the market for polyester really boomed largely due to the fashion trends presented in Hollywood films like Saturday Night Live! Polyester was touted as a miracle fabric, able to withstand extensive wear and tear while still looking presentable.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '58rem' }}>
				<img src="/static/img/pages/fabric/polyester/bg-image-2.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('One of the first and most popular uses for polyester fabric was to make the polyester suits that were at the height of fashion in the 1970s.')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{ top: '18rem' }}>
				<h3>{t('How It\'s Made')}</h3>
				<p>{t('Polyester can be produced in a few different ways. The most common method involves heating the long-chain polymers beyond their melting points then cooling them back into a solid. The liquid polymer is forced through a spigot similar to how a showerhead works to create the singular strand. Once through the spigot, the fiber is immediately cooled and returns to a solid. This process is known as polymerization and results in a fiber that is very strong. Depending on the intended end use for the fiber, it may be cut up, crimped, or manipulated in other ways to create textures and different surfaces on the finished fabric.')}</p>
			</div>

			<div className="floating-bubble floating-bubble-right" style={{ top: '20rem', borderRadius: '0 50% 50% 50%', marginLeft: '12rem' }}>
				<p>{t('Polyester has an extremely long lifespan as it is not susceptible to mold, mildew or bacterial deterioration.')}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/polyester/bg-image-3.jpg" />
			</div>

		</section>
	</>
}