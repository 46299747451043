import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import CartPaid, { Props } from '../_components/CartPaid';
import { CartStatus } from '@af-common/UIData/_actions/UIDataActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	status: store.getIn(['UIData', 'cartStatus'])
});

export default connect(
	mapStateToProps, null
)(CartPaid);