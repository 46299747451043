import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import Checkout, { Props } from '../_components/Checkout';
import { SaveCartAddress, GetUserCart } from '@af-common/UserData/_actions/UserCartActions';
import { SaveUserSecurity } from '@af-common/UserData/_actions/UserAccountActions';
import { EditCartStatus, CartStatus } from '@af-common/UIData/_actions/UIDataActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	cart: store.getIn(['userData', 'userCart']),
	cart_loading: store.getIn(['UIData', 'loaders', 'cart']),
	address_loading: store.getIn(['UIData', 'loaders', 'cart_address']),
	status: store.getIn(['UIData', 'cartStatus']),
	userEmail: store.getIn(['userData', 'userSecurity', 'email']),
	loggedIn: store.getIn(['userData', 'loggedIn']),
	fabrics: store.getIn(['UIData', 'fabrics']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	saveCartAddress: (fd:FormData) => dispatch(SaveCartAddress(fd)),
	getUserCart: () => dispatch(GetUserCart()),
	setStatus: (status:CartStatus) => {dispatch(EditCartStatus(status))},
	saveUserSecurity: (fd:FormData) => dispatch(SaveUserSecurity(fd)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Checkout);