import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import { Dispatch } from 'redux';
import { CreateUserCreditCard } from '@af-common/UserData/_actions/UserAccountActions';
import CreditCardForm, { Props } from '../_components/CreditCardForm';
import { Toast } from '@af-common/UIData/_actions/UIDataActions';
import { AjaxCallback } from '@af-common/Common/_actions/AjaxAction';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	loggedIn: store.getIn(['userData', 'loggedIn']),
	cart_loading: store.getIn(['UIData', 'loaders', 'cart']),
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
	onSubmit: (data:FormData, callback:AjaxCallback) => {dispatch(CreateUserCreditCard(data, callback))},
	onToast: (text:string) => {dispatch(Toast(text))}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditCardForm);