import React, { useState } from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Container, Grid, TextField, Button } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Toast } from '@af-common/UIData/_actions/UIDataActions';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import tKey from 'Common/tKey';

export default function ContactUs() {
	const dispatch = useDispatch();
	const [t] = useTranslation('pages/contact')
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [disabled, setDisabled] = useState(false)

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();

		setDisabled(true)

		if (validateForm()) {
			let fd = new FormData();
			fd.append('contact_name', name);
			fd.append('contact_email', email);
			fd.append('contact_message', message);

			let success = fetch('ajax/contact-us/send', { method: 'POST', body: fd })
				.then(response => response.json())
				.then(response => {
					if (response.data.success) {
						setName('')
						setEmail('')
						setMessage('')
						setDisabled(false)
						dispatch(Toast("Thank you! We'll be in touch."));

						window.fbq('track', 'Contact');

						window.gtag('event', 'generate_lead');
					} else {
						if (response.data.reason) {
							switch (response.data.reason) {
								case "notEmpty": dispatch(Toast(t('Please include your name and message.'))); break;
								case "email": dispatch(Toast(t('Invalid email.'))); break;
							}
						} else {
							dispatch(Toast("An error occurred"));
						}

						setDisabled(false)
					}
				});
		}
	}

	function validateField(field: string): boolean {
		let valid = false;
		switch (field) {
			case 'name':
				return !!name;
			case 'email':
				return !!email;
			case 'message':
				return !!message;
		}
		return valid;
	}

	function validateForm(): boolean {
		return validateField('name') &&
			validateField('email') &&
			validateField('message')
	};

	return <>
		<Helmet>
			<title>{t('Contact us')}</title>
			<meta name="description" content={t('Have questions about Art Fabrics and our fabric printing services? Send us an email!')} />
		</Helmet>

		<Header />

		<div className="contact-us">
			<Container maxWidth={"lg"}>
				<div className="contact-us-page">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<h2>Art Fabrics</h2>
							<p>
								<Trans
									t={t}
									i18nKey={tKey('5534 Ferrier Street<br />Mont-Royal, Quebec, Canada<br />H4P 1M2')}
									components={{
										br: <br />
									}}
								/>
							</p>
						</Grid>
						<Grid item xs={12}>
							<h2>{t('Send us an email')}</h2>
							<p>{t('For any questions, comments, feedback or custom inquiry.')}</p>
							<form onSubmit={(e: React.FormEvent) => onSubmit(e)}>
								<Grid container spacing={2}>
									<Grid item xs={12} lg={12}>
										<TextField
											value={name}
											onChange={(e) => setName(e.currentTarget.value)}
											label={t('Name')}
											variant="outlined"
											required
										/>
									</Grid>
									<Grid item xs={12} lg={12}>
										<TextField
											type="email"
											value={email}
											onChange={(e) => setEmail(e.currentTarget.value)}
											label={t('Email')}
											variant="outlined"
											required
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											value={message}
											onChange={(e) => setMessage(e.currentTarget.value)}
											label={t('Message')}
											variant="outlined"
											required
											multiline
											rows="10"
										/>
									</Grid>
									<Grid item xs={12}>
										<Button disabled={disabled} type="submit" variant="contained">{t('Send')}</Button>
									</Grid>
								</Grid>
							</form>
						</Grid>
					</Grid>
				</div>
			</Container>
		</div>
		<Footer />
	</>
}