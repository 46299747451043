import * as React from 'react';

export type Props = {
	bgImg?: string
	gradient?: string
	parallaxElement?: React.ReactNode
	parallaxFactor?: number
	style?: React.CSSProperties
	children?: React.ReactNode
}

type State = {
	topOffset: number
}

export default class TopBanner extends React.Component<Props, State> {
	static defaultProps = {
		bgImg: "/static/img/header-bg1.jpg",
		//gradient: "linear-gradient(90deg, rgba(233, 73, 115, 0.8) 0%, rgba(199, 151, 226, 0.8) 100%)",
		gradient: "linear-gradient(-90deg, rgba(199, 204, 118, 0.8) 0%, rgba(201, 97, 66, 0.5) 100%)",
		//gradient: "linear-gradient(90deg, rgba(241, 81, 120, 0.8) 0%, rgba(243, 219, 111, 0.8) 100%)",
		parallaxFactor: 1
	}
	
	state = {
		topOffset: 0
	}

	componentDidMount() {
		this.props.parallaxElement && window.addEventListener('scroll', this.onScroll.bind(this));
	}

	componentWillUnmount() {
		this.props.parallaxElement && window.removeEventListener('scroll', this.onScroll.bind(this));
	}

	private onScroll = (e:React.MouseEvent) => {
		this.setState({
			topOffset: window.scrollY * this.props.parallaxFactor
		});
	}

	private getUnderlay = (style:React.CSSProperties) => (
		this.props.bgImg !== "" ?
			<div className="header-underlay-container">
				<div className="top-banner header-underlay MuiContainer-maxWidthLg" style={style} />
			</div>
		:
			<div className="top-banner header-underlay" style={style} />
	)

	private getBanner = (style:React.CSSProperties) => (
		<div className={"top-banner" + (this.props.parallaxElement ? " has-parallax" : "")} style={style}>
			{ this.props.parallaxElement && <div className="parallax-element" style={{top: this.state.topOffset + 'px'}}>{this.props.parallaxElement}</div> }
			<div className="banner-content">{ this.props.children }</div>
		</div>
	)

	render = () => {
		let bgImg = this.props.bgImg;
		let gradient = this.props.gradient;
		
		if(bgImg !== TopBanner.defaultProps.bgImg && gradient == TopBanner.defaultProps.gradient) {
			gradient = "";
		}

		if(this.props.parallaxElement && gradient == TopBanner.defaultProps.gradient) {
			bgImg = "";
			gradient = "";
		}

		const style = bgImg !== "" ?
			{backgroundImage: (gradient ? gradient + "," : "") + "url('" + bgImg + "')"} :
			{backgroundImage: gradient}

		const underlayStyle = {...style}

		const bannerStyle = {...this.props.style,...style}

		return <>
			{this.getUnderlay(underlayStyle)}
			{this.getBanner(bannerStyle)}
		</>
	}
}