import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FabricCategoryContentProps } from './FabricCategory';
import tKey from 'Common/tKey';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';

export default function Silk(props: FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/silk');

	return <>
		<Helmet>
			<title>{t('Custom printed Silk')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of natural silk fabrics.')} />
		</Helmet>

		<div className="top-banner-fabrics fabric-silk">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Silk Fabric</span> on demand, <br />no minimums!')}
									components={{
										span: <span />,
										br: <br />,
									}}
								/>
							</h1>
							<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br />
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="silk_twill"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Silk</family> Twill')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/silk/silk-twill/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="silk_habotai"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Silk</family> Habotai')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/silk/silk-habotai/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="silk_charmeuse"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Silk</family> Charmeuse')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/silk/silk-charmeuse/fabric-styled.jpg"
					/>
					{ /*<FabricThumbnail
						slug="silk_chiffon"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Silk</family> Chiffon')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/silk/silk-chiffon/fabric-styled.jpg"
					/>*/ }
					<FabricThumbnail
						slug="silk_modal"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Silk</family> Modal')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/silk/silk-modal/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="cotton_silk"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton</family> Silk')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-silk/fabric-styled.jpg"
					/>
				</FabricThumbnailList>

			</Container>
		</section>


		<FabricInfo
			active={props.currentFabric === "silk_twill"}
			slug="silk_twill"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Silk</family> Twill')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Silk twill is a luxurious fabric. At 14mm, this fabric is a medium weight silk and the denser weave provides a beautiful, even surface for the ink to be absorbed. Silk twill has a matte finish so there is only a moderate shine to this fabric. The twill weave features the characteristic diagonals and is both delicate and strong. You’ll see why silk twill is the popular choice for Hermes silk scarves! The drape and dimension of silk twill will suit any high end project.')}
			printDescription={t('All silk twill fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% silk in twill weave'),
				width: t('52”/ 132 cm'),
				color: t('Eggshell white'),
				weight: t('120 g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Moderate, roughly 50%'),
			}}
			images={{
				front: "/static/img/pages/fabric/silk/silk-twill/fabric-front.jpg",
				back: "/static/img/pages/fabric/silk/silk-twill/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/silk/silk-twill/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/silk/silk-twill/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Bridal & Formal wear'),
					description: t('Scarves, ties and other accessories.'),
				},
				{
					title: t(''),
					description: t('Silk can be an option for luxury home decor products like pillows and curtains.'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "silk_habotai"}
			slug="silk_habotai"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Silk</family> Habotai')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Silk habotai is a basic weave silk and one of the thinnest fabrics we offer. Due to its lightness and delicate structure, inks penetrate almost 90% of the way through the silk which means your print looks almost as vibrant from the back as the front! Since silk is priced by weight, 8mm silk habotai is our most affordable silks and yet has one of the most desirable print results with the high print through. Depending on your print, this fabric will vary in its transparency so make sure to keep this in mind for clothing projects.')}
			printDescription={t('All silk habotai fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% silk in plain weave'),
				width: t('52”/132 cm'),
				color: t('Ivory white'),
				weight: t('90 g/m2'),
				shrinkage: t(''),
				printThrough: t('High. Around 80-90% depending on the ink colors'),
			}}
			images={{
				front: "/static/img/pages/fabric/silk/silk-habotai/fabric-front.jpg",
				back: "/static/img/pages/fabric/silk/silk-habotai/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/silk/silk-habotai/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/silk/silk-habotai/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Bridal and formal wear.'),
					description: t('Perfect for scarves and items where you want to see the print from both sides.'),
				},
				{
					title: t(''),
					description: t('Semi transparent clothing'),
				},
				{
					title: t(''),
					description: t('Luxury home decor'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "silk_charmeuse"}
			slug="silk_charmeuse"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Silk</family> Charmeuse')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Silk charmeuse is one of the best known types of silk. It is shiny on the surface and matte underneath. The high shine enhances prints and the fabric has high drapability. Silk charmeuse flows beautifully and has a liquid-like texture which makes it feel wonderful to wear and a popular choice for slips and formal dresses.')}
			printDescription={t('All silk charmeuse fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% silk'),
				width: t('54”/137cm'),
				color: t('Eggshell white'),
				weight: t('105g/m2'),
				shrinkage: t('Less than 1% in length and width'),
				printThrough: t('Medium, roughly 50%'),
			}}
			images={{
				front: "/static/img/pages/fabric/silk/silk-charmeuse/fabric-front.jpg",
				back: "/static/img/pages/fabric/silk/silk-charmeuse/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/silk/silk-charmeuse/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/silk/silk-charmeuse/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves, formal wear, bridal, accessories, blouses, slips'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "silk_chiffon"}
			slug="silk_chiffon"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Silk</family> Chiffon')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Silk chiffon is our airiest silk. It has a loose weave which makes it very lightweight with high print through. Prints on silk chiffon are almost as visible on the backside as on the front, but this also makes it more seethru so not a great choice for clothing. Chiffon has a rougher textured matte surface and high drapability.')}
			printDescription={t('All silk chiffon fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% silk'),
				width: t('54”/137cm'),
				color: t('Eggshell white'),
				weight: t('100g/m2'),
				shrinkage: t('Less than 1% in length and width'),
				printThrough: t('High, roughly 85%'),
			}}
			images={{
				front: "/static/img/pages/fabric/silk/silk-chiffon/fabric-front.jpg",
				back: "/static/img/pages/fabric/silk/silk-chiffon/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/silk/silk-chiffon/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/silk/silk-chiffon/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves, formal wear, bridal, accessories'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "silk_modal"}
			slug="silk_modal"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Silk</family> Modal')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('The combination of modal and silk that make up this fabric is a match made in heaven! The modal is sourced from beechwood trees and when combined with silk gives the fabric strength while remaining lightweight. This fabric has a fluid drape, easy to work with, and easy to care for.')}
			printDescription={t('All modal silk fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('85% beechwood modal/ 15% silk'),
				width: t('52”/ 132 cm'),
				color: t('Off-white'),
				weight: t('95 g/m2'),
				shrinkage: t('2-3% in length and width'),
				printThrough: t('Medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/silk/silk-modal/fabric-front.jpg",
				back: "/static/img/pages/fabric/silk/silk-modal/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/silk/silk-modal/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/silk/silk-modal/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves and accessories, layered clothing, flowy garments'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cotton_silk"}
			slug="cotton_silk"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton</family> Silk')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Gorgeous, strong, and lightweight, cotton silk features the best properties of both cotton and silk! Enjoy the high breathability, stableness, and slight surface lustre of this silk cotton blend fabric. This fabric has some crispness but still drapes well which makes it perfect for summer dresses and blouses.')}
			printDescription={t('All cotton silk fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('75% cotton/ 25% silk'),
				width: t('52”/132 cm'),
				color: t('off white'),
				weight: t('95 g/m2'),
				shrinkage: t('2-3% in length and width'),
				printThrough: t('medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-silk/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-silk/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-silk/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-silk/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves and accessories, layered clothing, flowy garments'),
				},
			]}
		/>


		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Silk Fabric?')}</h3>
				<p>{t('Silk is a natural protein fiber, cultivated from the cocoons of certain insect larvae. While a variety of insects produce silk to form their cocoons, only the silk of moth caterpillars is used to make silk fabric. The mulberry silkworm Bombyx mori produces the most well-known silk today. Raw silk threads from the cocoons are spun into silk yarn, which is then woven into silk fabric. Silk fabric is known for its shimmering appearance due to its triangular prism-like fiber structure. The shape allows for incoming light to refract at different angles and gives different visual dimension and richness to the fabric surface.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{ top: '15rem' }}>
				<img src="/static/img/pages/fabric/silk/bg-image-1.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('Silk, specifically 100% silk twill - is the most popular fabric used in the scarves of the luxury apparel brand Hermès.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('A Brief History...')}</h3>
				<p>{t('The production of silk both began and was exclusive to China for the early part of its history. With the opening of the Silk Road silk became available to much of the world. The Silk Road was a trade route system that connected the Eastern World and the Western World from the 2nd century BC to the 18th century. While it refers predominantly to the export of silk from China, it also resulted in the exchange of many other goods - from religion to culture - forming-long distance political and economic relationships that were previously not possible. Yet even after the opening of the Silk Road, China remained nearly the sole provider of silk world wide for another thousand years. Silk production gradually spread to Japan, the Byzantine Empire, Arabia, and even Western Europe, at which point Chinese silk lost much of its demand outside the luxury market. Today, however, China has regained its position as the world\'s largest producer of silk.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '58rem' }}>
				<img src="/static/img/pages/fabric/silk/bg-image-2.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('According to Chinese Mythology, 14-year-old empress Leizu discovered silk when a silkworm cocoon fell into her teacup.')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{ top: '18rem' }}>
				<h3>{t('How It\'s Made')}</h3>
				<p>{t('The production of silk fabric, known as sericulture, is a complex and labor-intensive process, hence making it an expensive fabric to buy. The process involves feeding silkworm larvae mulberry leaves until they have grown to a sufficient size to begin forming themselves cocoons. The cocooning process takes about 2-3 days, with the resulting amount of usable silk from each cocoon being quite small. About 2500 silkworms are needed to make 1 pound of raw silk. Once the cocoons are fully formed they are boiled, killing the silkworm pupa and allowing the silk fibers that made up the cocoon to unravel. These filaments, known as raw silk, are wound onto a reel to be spun into silk yarn.')}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/silk/bg-image-3.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right" style={{ top: '20rem', borderRadius: '0 50% 50% 50%', marginLeft: '12rem' }}>
				<p>{t('Silk is the strongest natural fiber currently known to man, with a tensile strength equal to steel.')}</p>
			</div>
		</section>
	</>
}