import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FabricCategoryContentProps } from './FabricCategory';
import tKey from 'Common/tKey';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';

export default function Hemp(props: FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/hemp');

	return <>
		<Helmet>
			<title>{t('Custom printed Hemp')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of natural hemp fabrics.')} />
		</Helmet>

		<div className="top-banner-fabrics fabric-hemp">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Hemp Fabric</span> on demand, <br />no minimums!')}
									components={{
										span: <span />,
										br: <br />,
									}}
								/>
							</h1>
							<Link to={tR('/create')}>
								<Button className="home--cta">{t('Start designing')}</Button>
							</Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br />
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="organic_cotton_hemp"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Organic Cotton</family> Hemp')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-styled.jpg"
					/>
				</FabricThumbnailList>

			</Container>
		</section>

		<FabricInfo
			active={props.currentFabric === "organic_cotton_hemp"}
			slug="organic_cotton_hemp"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Organic Cotton</family> Hemp')}
				components={{
					family: <div className="fabric-family"/>
				}}
			/>}
			description={t('Looking for a basic that’s far from basic! Organic cotton hemp feels similar in weight and structure to quilting cotton, but has a lovely composition and tighter weave. It’s a perfect twist when you want to get away from basics. The hemp fibers create small slub textures in the fabric so your print surface has enhanced dimension. It’s easy to manipulate and sew. Use it as an alternative to quilting cotton where you want ease of use but also a bit of structure.')}
			printDescription={t('All cotton hemp fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('76% organic cotton/24% hemp'),
				width: t('54”/137 cm'),
				color: t('Slightly off white'),
				weight: t('150g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low, roughly 25%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Men\'s clothing'),
					description: t('including dress shirts.'),
				},
				{
					title: t('Women\'s clothing'),
					description: t('including blouses, dresses, and lightweight pants and skirts.'),
				},
				{
					title: t('Quilting projects'),
					description: t('and lightweight home decor.'),
				},
				{
					title: t(''),
					description: t('Would be great as a lining fabric.'),
				},
			]}
		/>

		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Hemp Fabric?')}</h3>
				<p>{t('Hemp fabric is a natural textile that, like all other natural fabrics, is made primarily from a single plant. The plant fibre used to make hemp textile comes from the Cannabis sativa plant, also known as industrial hemp. While cannabis as a drug - ie. marajuana - is also derived from the Cannabis sativa plant, marajuana and industrial hemp are distinct strains with differing properties and uses. Hemp fibre has a wide variety of uses outside the world of textiles, however fabric made from industrial hemp has become very popular in the textile industry due to its versatility and likeness to linen. Hemp is often blended with other natural fibres to make beautiful, natural blends with a variety of functions and features, such as our organic cotton hemp blend.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{ top: '15rem' }}>
				<img src="/static/img/pages/fabric/hemp/bg-image-1.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('Unlike its cannabis cousin, marijuana, hemp contains almost no THC, the psychoactive component in marijuana that gets you high.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('A Brief History...')}</h3>
				<p>{t('Based on an archeological site on an island near Japan which dates back to about 8000 BC, Hemp is thought to be one of the earliest plants to be cultivated in human history. From around 5000 BC the Chinese began using hemp fibres to imprint pottery, and later began weaving them into clothing, shoes, rope and even an early form of paper. It was the Spanish that brought hemp to The Americas in the 1500s, after which George Washington was largely responsible for its cultivation through North America. Industrial hemp is now grown in over 30 countries around the world.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '58rem' }}>
				<img src="/static/img/pages/fabric/hemp/bg-image-2.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('It wasn’t until 1998 that it became federally legal to grow industrial hemp in Canada, and not until 2018 in The United States.')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{ top: '18rem' }}>
				<h3>{t('How It\'s Made')}</h3>
				<p>{t('The first step in the textile making process is the harvesting of the hemp plant. It’s the long fibre strands in the stalk of the plant that eventually make up the fabric, so after harvesting these fibres are separated from the bark. The separation process is called “retting”, and the resulting fibres are long and durable. These fibres are then spun together to produce one continuous thread, much like the process of creating linen. Once the thread has been spun it can then be woven into hemp fabrics or hemp fabric blends. The resulting fabrics can be extremely diverse, depending on the blend that’s created. Pure hemp fabric, however, is light and airy, much like linen textile.')}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/hemp/bg-image-3.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right" style={{ top: '20rem', borderRadius: '0 50% 50% 50%', marginLeft: '12rem' }}>
				<p>{t('Industrial hemp is considered a carbon negative raw material.')}</p>
			</div>
		</section>
	</>
}