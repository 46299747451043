import * as React from 'react';
import { Route, Outlet } from 'react-router-dom';
import Footer from '../../Common/_components/Footer';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import Header from '../../Common/_components/Header';

export type Props = {
	loggedIn?: boolean
	validated?: boolean
	location?: any
};

export default class Auth extends React.Component<Props> {
	render = () => <>
		<Header />

		<div className="auth">
			<Outlet/>
		</div>
		<Footer />
	</>
}