import * as React from 'react';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { ButtonBase } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { styled } from '@mui/material/styles';

export type Props = {
	lockRatio?: boolean
	isAttached?: boolean
	toggleLockRatio?: Function
}

const StyledLock = styled(ButtonBase)`
	opacity: ${props => props.disabled ? "0.5" : "1"};
`;

export default class LockRatioButton extends AwareComponent<Props> {
	render = () => {
		return (
			<div className="action-buttons">
				<StyledLock disabled={this.props.isAttached}>
					<div className="action-buttons-icon-square" onClick={() => this.props.toggleLockRatio(!this.props.lockRatio)}>
						{this.props.lockRatio && <LockIcon/>}
						{!this.props.lockRatio && <LockOpenIcon/>}
					</div>
				</StyledLock>
			</div>
		)
	}
}