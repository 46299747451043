import { Button, Container, Divider, Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Fabric } from '@af-common/UIData/_data/Fabrics';
import * as Immutable from 'immutable';
import Dinero from 'dinero.js';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import tKey from 'Common/tKey';
import FabricListCategory from './FabricListCategory';
import FabricListCategoryLink from './FabricListCategoryLink';

export type Props = {
	fabrics?: Immutable.OrderedMap<number, Fabric>
}

export default function FabricList() {
	const [t] = useTranslation('pages/fabrics');
	const [tR] = useTranslation('routes')

	return <>
		<Helmet>
			<title>{t('Fabrics & Prices')}</title>
			<meta name="description" content={t('See our complete list of natural and synthetic fabrics that we print on.')} />
		</Helmet>

		<div className="fabric-page-container">
			<Header light />
			<div className="fabrics--header-wrapper"><Container className="fabrics--header">
				<h1 className="fabrics--title">
					<Trans
						t={t}
						i18nKey={tKey('Get to know <span>our fabrics</span>')}
						components={{
							span: <span />
						}}
					/>
				</h1>
				<p className="fabrics--subtitle">
					<Trans
						t={t}
						i18nKey={tKey('<strong>Fabrics for every project!</strong> Browse our extensive array of fabrics, featuring a wide variety of fibers from sleek polyester to sumptuous cashmere. All fabrics are printed with permanent and vibrant wash-fast inks.')}
						components={{
							strong: <strong />
						}}
					/>
				</p>
				<p className="fabrics--subtitle">
					<Trans
						t={t}
						i18nKey={tKey('<strong>Priced for one-time purchases and for wholesale.</strong> Benefit from discounted pricing the more you order. Order multiple images on the same fabric and the total amount of meters will determine the interval price.')}
						components={{
							strong: <strong />
						}}
					/>
				</p>
				<div className="fabrics--nav">
					<FabricListCategoryLink slug="cotton" title={t('Cotton')}/>
					<FabricListCategoryLink slug="silk" title={t('Silk')}/>
					<FabricListCategoryLink slug="hemp" title={t('Hemp')}/>
					<FabricListCategoryLink slug="modal" title={t('Modal')}/>
					<FabricListCategoryLink slug="cashmere" title={t('Cashmere')}/>
					<FabricListCategoryLink slug="linen" title={t('Linen')}/>
					<FabricListCategoryLink slug="polyester" title={t('Polyester')}/>
					<FabricListCategoryLink slug="vegan-leather" title={t('Vegan Leather')}/>
				</div>
			</Container></div>
			<div className="fabrics--page">
				<FabricListCategory
					slug="cotton"
					url={tR('/fabrics/cotton')}
					title={t('Cotton')}
					img="/static/img/pages/fabric/cotton.svg"
					description={t('Experience the softness and breathability of cotton, custom printed with vibrant, permanent reactive inks. Reactive inks bond directly with the cotton fibers, ensuring long-lasting color that won’t fade, even after repeated washing.')}
					fabrics={[
						"cotton_canvas",
						"organic_cotton_hemp",
						"cotton_sateen_200tc",
						"cotton_sateen_600tc",
						"cotton_linen_canvas",
						"pebble_canvas",
						"quilting_cotton_organic",
						"cotton_double_gauze",
						"essential_cotton_linen",
						"cotton_silk",
					]}
				/>
				<FabricListCategory
					slug="silk"
					url={tR('/fabrics/silk')}
					title={t('Silk')}
					img="/static/img/pages/fabric/silk.svg"
					description={t('Unveil the timeless beauty of silk, custom printed with reactive inks for bold, enduring colors. This fabric’s natural luster and soft drape enhances any design, making it perfect for creating luxurious, high-end pieces that stand out.')}
					fabrics={[
						"silk_twill",
						"silk_habotai",
						"silk_charmeuse",
						"silk_chiffon",
						"silk_modal",
						"silk_cashmere",
						"cotton_silk",
					]}
				/>
				<FabricListCategory
					slug="hemp"
					url={tR('/fabrics/hemp')}
					title={t('Hemp')}
					img="/static/img/pages/fabric/hemp.svg"
					description={t('Embrace sustainability with durable, eco-friendly hemp fabric, custom printed with vibrant, long-lasting reactive inks. Known for its strength and breathability, hemp offers a natural, textured feel and is both breathable and strong.')}
					fabrics={[
						"organic_cotton_hemp",
					]}
				/>
				<FabricListCategory
					slug="modal"
					url={tR('/fabrics/modal')}
					title={t('Modal')}
					img="/static/img/pages/fabric/modal.svg"
					description={t('Indulge in the ultra-soft touch of modal-blended fabrics, featuring vibrant custom prints with reactive inks. The smooth texture and fluid drape of modal fibers make it perfect for scarves and lightweight clothing and accessories.')}
					fabrics={[
						"silk_modal",
						"cashmere_modal",
					]}
				/>
				<FabricListCategory
					slug="cashmere"
					url={tR('/fabrics/cashmere')}
					title={t('Cashmere')}
					img="/static/img/pages/fabric/cashemere.svg"
					description={t('Experience the ultimate in luxury with custom printed cashmere fabric, where exquisite softness meets permanent, vibrant prints. This opulent fiber provides unparalleled warmth and softness either on its own or when blended with other fibers.')}
					fabrics={[
						"cashmere_modal",
					]}
				/>
				<FabricListCategory
					slug="linen"
					url={tR('/fabrics/linen')}
					title={t('Linen')}
					img="/static/img/pages/fabric/linen.svg"
					description={t('Known for its breathable and crisp texture, linen fibers have unique properties when featured solely or when blended with other fibers. Linen drapes beautifully with a distinctive texture. Reactive prints on linen fibers are permanent and enhance the natural properties of the fabric.')}
					fabrics={[
						"essential_cotton_linen",
						"cotton_linen_canvas",
					]}
				/>
				<FabricListCategory
					slug="polyester"
					url={tR('/fabrics/polyester')}
					title={t('Polyester')}
					img="/static/img/pages/fabric/knit_polyester.svg"
					description={t('Discover the versatility of polyester fabrics printed with vibrant sublimation inks. Polyester fabrics are known for their durability and color-fastness, ensuring that your designs remain vivid and striking over time. Perfect for everything from activewear to home decor.')}
					fabrics={[
						"heavy_satin",
						"swimsuit_knit",
						"peachskin_jersey",
						"velveteen",
						"poly_twill",
						"poly_canvas",
						"scuba_double_knit",
						"performance_spandex",
						"poly_chiffon",
						"spun_polyester",
						"minky",
						"double_minky",
						"silky_knit",
						"matte_crepe",
						"satin_charmeuse",
						"glitter_performance_knit",
					]}
				/>
				<FabricListCategory
					slug="vegan-leather"
					url={tR('/fabrics/vegan-leather')}
					title={t('Vegan Leather')}
					img="/static/img/pages/fabric/vegan-leather.svg"
					description={t('Vegan leather offers a luxurious, cruelty-free alternative to traditional leather, crafted from high-quality synthetic or plant-based materials that capture the elegance and feel of genuine leather without using animal products. This fabric brings a refined, sophisticated touch for those seeking a more ethical and eco-friendly choice.')}
					fabrics={[
						"crinkle_vegan_leather",
						"fine_grain_vegan_leather",
						"pebble_vegan_leather",
					]}
				/>
			</div>
			<Footer />
		</div>
	</>
}