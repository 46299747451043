import { IUserAddressStore } from '@af-common/UserData/_stores/UserAddressesStore';
import { Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, Divider } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import AddressFormContainer from '../_containers/AddressFormContainer';
import Pre from '@af-common/Common/_containers/PrerequisiteContainer';
import { Country } from '@af-common/UIData/_stores/UIDataStore';

export type AddressDisplayMode = "account" | "browser" | "cart";

export type AddressAction = {
	execute: (id:Number) => void,
	name: string,
	icon: string,
}

export type Props = {
	id: Number
	address?: Immutable.Record<IUserAddressStore>
	countries?:  Immutable.Map<any, Country>
	updateAddress?: Function
	deleteAddress?: Function
	displayMode?: AddressDisplayMode
	action?: AddressAction
	noEdit?: boolean
};

type State = {
	editOpened: boolean
	deleteConfirm: boolean
	updating: boolean
}

export default class Address extends React.Component<Props, State> {
	state = { editOpened: false, deleteConfirm: false, updating: false }

	componentDidUpdate(_props:Props, _state:State) {
		if(this.state.updating && _state.updating)
			this.setState({updating: false});
	}

	private update = (updates: any) => {
		this.setState({updating: true});
		let formData = new FormData();
		formData.append("id", String(this.props.id));
		for(let key in updates) {
			if(!updates.hasOwnProperty(key)) continue;
			formData.append(key, updates[key]);
		}
		this.props.updateAddress(formData);
	}

	private delete = () => {
		let formData = new FormData();
		formData.append("id", String(this.props.id));
		this.props.deleteAddress(formData);
	}

	render() {
		const displayMode = this.props.displayMode || "account";
		let size = {};
		switch(displayMode){
			case "cart": size = { xs: 12, sm: 12, lg: 12 }; break;
			case "browser": size = { xs: 12, sm: 6, lg: 4 }; break;
			case "account": size = { xs: 12, sm: 6, lg: 4 }; break;
		}

		if(!this.props.address) return <></>;

		const cardContent = <>
			{ displayMode == "account" && !this.props.noEdit && <>
				<CardActions>
					<span className="account-address--chips">
						<Chip disabled={this.state.updating} onClick={() => {
							if(this.props.address.get('default_billing') == "0")
								this.update({"default_billing": "1"})
						}} size="small" label="Default Billing" color="primary" clickable variant={this.props.address.get('default_billing') == "0" ? "outlined" : "filled"} />&nbsp;
						<Chip disabled={this.state.updating} onClick={() => {
							if(this.props.address.get('default_shipping') == "0")
								this.update({"default_shipping": "1"})
						}} size="small" label="Default Shipping" color="primary" clickable variant={this.props.address.get('default_shipping') == "0" ? "outlined" : "filled"} />
					</span> 
				</CardActions>
				<Divider />
			</> }
			<CardHeader 
				title={<>
					<span>{this.props.address.get('title')}</span>
				</>}
			/>
			<CardContent>
				<Typography variant="body2" component={"b"}> 
					{this.props.address.get('name')}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					{this.props.address.get('address')}, {this.props.address.get('address2')}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					{this.props.address.get('city')}, {this.props.address.get('province')} {this.props.address.get('postal_code')}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					{this.props.countries.get(this.props.address.get('id_country'))?.get('name')}
				</Typography>
				<Typography variant="body2" color="textSecondary" component="p">
					{this.props.address.get('phone')}
				</Typography>
			</CardContent>
			{!this.props.noEdit ? <>
				<Divider />
				<CardActions>
					<Button color="secondary" disabled={this.state.updating} size="small" onClick={() => this.setState({editOpened: true})} startIcon={<i className="material-icons">edit</i>}>Edit</Button>
					{this.props.action ? 
						<Button color="secondary" style={this.props.noEdit ? {width: '100%'} : {}} disabled={this.state.updating} size="small" onClick={() => this.props.action.execute(this.props.id)} startIcon={<i className="material-icons">{this.props.action.icon}</i>}>{this.props.action.name}</Button>
						:
						<Button color="secondary" style={this.props.noEdit ? {width: '100%'} : {}} disabled={this.state.updating} size="small" onClick={() => this.setState({deleteConfirm: true})} startIcon={<i className="material-icons">delete</i>}>Delete</Button>
					}
				</CardActions>
			</> : <></> }
		</>

		const ready = this.props.countries && this.props.countries.count();

		return <Pre name="countries" ready={!!ready} content={() => <>
			<Grid item {...size}>
				<Card className={"account-address" + (this.props.address.get("loading") ? " disabled" : "")}>
					{(this.props.displayMode == "browser" && this.props.action) ? <Button className="account-address-browser" onClick={() => this.props.action.execute(this.props.id)}>{cardContent}</Button> : cardContent}
				</Card>
			</Grid>

			<AddressFormContainer id={this.props.id} opened={this.state.editOpened} onClose={()=>{
				this.setState({
					editOpened: false
				})
			}}/>

			<Dialog
				open={this.state.deleteConfirm}
				onClose={()=>{this.setState({deleteConfirm: false})}}
				aria-labelledby="delete-address-title"
				aria-describedby="delete-address-description"
			>
				<DialogTitle id="delete-address-title">{"Delete Address"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="delete-address-description">
						Are you sure that you want to delete this address?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={()=>{this.setState({deleteConfirm: false})}} color="primary">Cancel</Button>
					<Button onClick={() => {
						this.delete();
						this.setState({deleteConfirm: false});
					}} color="primary" className="secondary-primary">Confirm</Button>
				</DialogActions>
			</Dialog>
		</>} />
	}
}
