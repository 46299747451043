import { DeletePaginationItem, RenamePaginationItem } from '@af-common/UserData/_actions/UserDataActions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Pattern, { Props } from '../_components/Pattern';
import { OrderProduct } from '@af-common/UIData/_actions/UIDataActions';

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	onDelete: (fd:FormData) => dispatch(DeletePaginationItem(fd, 'patterns')),
	onRename: (fd:FormData) => dispatch(RenamePaginationItem(fd, 'patterns')),
	orderProduct: (id: string, productType: string) => dispatch(OrderProduct(id, productType))
});

export default connect(
	null,
	mapDispatchToProps
)(Pattern);