import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import tKey from 'Common/tKey';
import { FabricCategoryContentProps } from './FabricCategory';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';

export default function Cotton(props: FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/cotton');

	return <>
		<Helmet>
			<title>{t('Custom printed Cotton')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of natural cotton fabrics.')} />
		</Helmet>

		<div className="top-banner-fabrics fabric-cotton">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Cotton Fabric</span> on demand, <br />no minimums!')}
									components={{
										span: <span />,
										br: <br />,
									}}
								/>
							</h1>
							<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br />
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="cotton_canvas"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton</family> canvas')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-canvas/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="organic_cotton_hemp"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Organic Cotton</family> Hemp')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="cotton_sateen_200tc"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton</family> Sateen<sup>200TC</sup>')}
							components={{
								family: <div className="fabric-family" />,
								sup: <sup />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-sateen200/fabric-styled.jpg"
					/>
					{/*<FabricThumbnail
						slug="cotton_sateen_600tc"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton</family> Sateen<sup>600TC</sup>')}
							components={{
								family: <div className="fabric-family" />,
								sup: <sup />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-sateen600/fabric-styled.jpg"
					/>*/}
					<FabricThumbnail
						slug="cotton_linen_canvas"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton</family> Linen')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-styled.jpg"
					/>
					{/*<FabricThumbnail
						slug="pebble_canvas"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton Canvas</family> Pebble')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/pebble-canvas/fabric-styled.jpg"
					/>*/}
					<FabricThumbnail
						slug="quilting_cotton_organic"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Organic Cotton</family> Quilting')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/quilting-cotton-organic/fabric-styled.jpg"
					/>
					{/*<FabricThumbnail
						slug="cotton_double_gauze"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton Double</family> Gauze')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-double-gauze/fabric-styled.jpg"
					/>*/}
					<FabricThumbnail
						slug="essential_cotton_linen"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Essential Cotton</family> Linen')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/linen/essential-cotton-linen/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="cotton_silk"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton</family> Silk')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-silk/fabric-styled.jpg"
					/>

				</FabricThumbnailList>

			</Container>
		</section>

		<FabricInfo
			active={props.currentFabric === "cotton_canvas"}
			slug="cotton_canvas"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton</family> canvas')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Here comes the perfect cotton canvas to the rescue! Featuring a typical canvas textured surface, this 100% cotton canvas is even and smooth. The tight weave of this fabric means your print details are not lost in the texture, even though it\'s a heavy weight fabric. Cotton canvas has excellent structure so it can be used in projects that require shape and durability (think totes!). Your next high use home decor project would be a perfect match for cotton canvas!')}
			printDescription={t('All cotton canvas fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% cotton'),
				width: t('55”/140 cm'),
				color: t('Off white'),
				weight: t('275g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low, roughly 10%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-canvas/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-canvas/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-canvas/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-canvas/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Home decor'),
					description: t('including pillowcases, table clothes, curtains, and upholstery projects.'),
				},
				{
					title: t('Good for outerwear'),
					description: t('including coats and blazers.'),
				},
				{
					title: t('Can be used for all styles of bags and totes.'),
					description: t(''),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cotton_sateen_200tc"}
			slug="cotton_sateen_200tc"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton</family> Sateen<sup>200TC</sup>')}
				components={{
					family: <div className="fabric-family" />,
					sup: <sup />
				}}
			/>}
			description={t('Similar in weight to our 600TC cotton sateen, the 200TC cotton sateen is our budget friendly alternative also available in width width. Don’t let price stop you from making the bedding of your dreams! With a matte underneath and low sheen surface, cotton sateen is one of the basics you’ll want to live with everyday.')}
			printDescription={t('All cotton sateen fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% cotton'),
				width: t('94.5”/240 cm'),
				color: t('White'),
				weight: t('130 g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low, roughly 25%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-sateen200/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-sateen200/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-sateen200/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-sateen200/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Home decor'),
					description: t('including pillow shams, table clothes, duvet covers, sheets, and curtains.'),
				},
				{
					title: t('Clothing'),
					description: t('including dresses and childrens articles.'),
				},
				{
					title: t('Crafting and Quilting'),
					description: t(''),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cotton_sateen_600tc"}
			slug="cotton_sateen_600tc"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton</family> Sateen<sup>600TC</sup>')}
				components={{
					family: <div className="fabric-family" />,
					sup: <sup />
				}}
			/>}
			description={t('Cotton sateen is both a beautiful and highly functional fabric. The underside is matte while the top surface is smooth with a slight sheen that enhances your print results. At a 600 thread count, this fabric is tightly woven and you can feel the quality the moment you touch it. At a 600 thread count, each fiber in this fabric is actually 4 fibers twisted together to make one strong and continuous fiber, therefore producing the high thread count and exceptional feel.<br/><br/>Cotton sateen softens with every wash making it the perfect fabric for high use home decor products like bed sheets and duvet covers. You’ll surely fall in love with our wide width cotton sateen!')}
			printDescription={t('All cotton sateen fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% cotton'),
				width: t('94.5”/240 cm'),
				color: t('Optic White'),
				weight: t('140g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low, roughly 25%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-sateen600/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-sateen600/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-sateen600/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-sateen600/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Home decor'),
					description: t('including pillow shams, table clothes, duvet covers, sheets, and curtains.'),
				},
				{
					title: t('Clothing'),
					description: t('including dresses and childrens articles.'),
				},
				{
					title: t('Crafting and Quilting'),
					description: t(''),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cotton_linen_canvas"}
			slug="cotton_linen_canvas"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton</family> Linen')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Our cotton-linen blend is a medium weight, semi-structured fabric. It has large surface fibers so the canvas weave gives texture to your prints. It is the perfect choice when making tea towels, home decor items or loose-fitting garments. This fabric is highly absorbent. This fabric is not ideal for solid prints as it has some inherent flaws from seeds and thick fibers that may show small breaks in solid colors.')}
			printDescription={t('All cotton linen fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('68% cotton/32% linen'),
				width: t('57”/145cm'),
				color: t('White'),
				weight: t('280g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low, roughly 10%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Home decor'),
					description: t('including curtains, pillow cases, table cloths, and tea towels.'),
				},
				{
					title: t('Clothing'),
					description: t('including loose-fitting garments, skirts and slacks, overalls, blazers and light coats.'),
				},
				{
					title: t('Accessories'),
					description: t('including bags/totes, headwear, zipper pouches.'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "pebble_canvas"}
			slug="pebble_canvas"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton Canvas</family> Pebble')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Less structured than our cotton canvas, pebble canvas has a textured surface and drapes easier. This makes it a great fabric for tea towels or projects where movement is important! As a 100% cotton fabric, it has great absorbancy. Pebble canvas can be used for light-use upholsterty projects as it has not been tested for rub count.')}
			printDescription={t('All pebble canvas fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% cotton'),
				width: t('61”/155cm'),
				color: t('Off White'),
				weight: t('285g/m2'),
				shrinkage: t('2-3% in length and width'),
				printThrough: t('Low, roughly 10%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/pebble-canvas/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/pebble-canvas/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/pebble-canvas/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/pebble-canvas/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Bags, Jackets, heavy clothing, light-use upholstery, tea towels'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "quilting_cotton_organic"}
			slug="quilting_cotton_organic"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Organic Cotton</family> Quilting')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Quilting cotton is one of the fabric basics so we made sure ours is the best possible quality! Made from 100% organic cotton, our quilting cotton is perfect for quilting or clothing projects. It is easy to work with and exactly what you expect in a good quality cotton fabric.')}
			printDescription={t('All organic quilting cotton fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% cotton'),
				width: t('55”/140cm'),
				color: t('Soft White'),
				weight: t('135g/m2'),
				shrinkage: t('3-4% in length and width'),
				printThrough: t('Low, roughly 25%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/quilting-cotton-organic/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/quilting-cotton-organic/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/quilting-cotton-organic/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/quilting-cotton-organic/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Quilting, crafts, lightweight clothing, kids clothe'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cotton_double_gauze"}
			slug="cotton_double_gauze"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton Double</family> Gauze')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Cotton double gauze is one of those fabrics that you just want to touch! Like it\'s name suggests, it\'s composed of 2 layers of guaze woven together in the middle It provides coverage when used in clothing, but remains light and airy. Double gauze is commonly used for swaddling blankets and is popular for baby products.')}
			printDescription={t('All cotton double gauze fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('100% cotton'),
				width: t('57”/145cm'),
				color: t('Off White'),
				weight: t('135g/m2'),
				shrinkage: t('3-4% in length and width'),
				printThrough: t('Medium, roughly 40%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-double-gauze/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-double-gauze/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-double-gauze/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-double-gauze/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Lightweight clothing, scarves, baby gear, layered dresses'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "organic_cotton_hemp"}
			slug="organic_cotton_hemp"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Organic Cotton</family> Hemp')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Looking for a basic that’s far from basic! Organic cotton hemp feels similar in weight and structure to quilting cotton, but has a lovely composition and tighter weave. It’s a perfect twist when you want to get away from basics. The hemp fibers create small slub textures in the fabric so your print surface has enhanced dimension. It’s easy to manipulate and sew. Use it as an alternative to quilting cotton where you want ease of use but also a bit of structure.')}
			printDescription={t('All cotton hemp fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('76% organic cotton/24% hemp'),
				width: t('54”/137 cm'),
				color: t('Slightly off white'),
				weight: t('150g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low, roughly 25%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/organic-cotton-hemp/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Men\'s clothing'),
					description: t('including dress shirts.'),
				},
				{
					title: t('Women\'s clothing'),
					description: t('including blouses, dresses, and lightweight pants and skirts.'),
				},
				{
					title: t('Quilting projects'),
					description: t('and <strong>lightweight home decor</strong>.'),
				},
				{
					title: t('Would be great as a lining fabric.'),
					description: t(''),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "essential_cotton_linen"}
			slug="essential_cotton_linen"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Essential Cotton</family> Linen')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Just like the name says, this cotton linen blend is a staple! At an almost equal blend of cotton and linen, it is balanced and pleasing to the touch and has the classic linen look. The blend of linen and cotton means you get the best benefits of both fibers. Easy to work with and lightweight enough for most clothing basics, you’ll love working with this fabric.')}
			printDescription={t('All cotton linen fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('55% cotton/ 45% linen'),
				width: t('52”/132 cm'),
				color: t('light natural'),
				weight: t('140 g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('low'),
			}}
			images={{
				front: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-front.jpg",
				back: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Blouses, dresses, light clothing, light accessories and home decor'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cotton_silk"}
			slug="cotton_silk"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton</family> Silk')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Gorgeous, strong, and lightweight, cotton silk features the best properties of both cotton and silk! Enjoy the high breathability, stableness, and slight surface lustre of this silk cotton blend fabric. This fabric has some crispness but still drapes well which makes it perfect for summer dresses and blouses.')}
			printDescription={t('All cotton silk fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('75% cotton/ 25% silk'),
				width: t('52”/132 cm'),
				color: t('off white'),
				weight: t('95 g/m2'),
				shrinkage: t('2-3% in length and width'),
				printThrough: t('medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-silk/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-silk/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-silk/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-silk/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves and accessories, layered clothing, flowy garments'),
				},
			]}
		/>

		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Cotton Fabric?')}</h3>
				<p>{t('Cotton fabric is an all-natural fabric made from cotton yarn and is the most used natural fiber fabric in clothing today. The cotton yarn, which is spun from the cotton boll fiber (that’s not a spelling mistake!) of the cotton plant, is typically knitted or woven into cotton fabrics. As the cotton fabric is incredibly versatile, it can be woven into a variety of compositions - the 2 most common being plain weave and twill. Other common cotton fabrics include gauze, voile, velvet, and flannel. Cotton fibers can also be found in blends with other natural fibers as well as with synthetic fibers. Popular blends include poly/cotton, cotton/linen, and cotton/silk.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{ top: '15rem' }}>
				<img src="/static/img/pages/fabric/cotton/bg-image-1.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('Almost every part of the cotton plant is used after harvest - including the lint, seed, stalks, and hulls.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('A Brief History...')}</h3>
				<p>{t('Grown in tropical and subtropical regions all around the globe, cotton production accounts for roughly 2.5% of the world\'s arable land. With a rich and at times controversial history, cotton has been cultivated for thousands of years and has been used specifically for fabric since prehistoric times. However, it was not until the 5th century, when the cotton gin was first invented in India, that cotton became affordable and attained widespread use. Today cotton production is found mainly in the southern USA, Uzbekistan, China, and India.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '58rem' }}>
				<img src="/static/img/pages/fabric/cotton/bg-image-2.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('Roughly 60% of women\'s clothing and 75% of men\'s clothing is made from either 100% fabric or some kind of cotton blend.')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{ top: '18rem' }}>
				<h3>{t('How It\'s Made')}</h3>
				<p>{t('After the cotton bolls - which are actually the fruit of the cotton plant - are harvested from the field they undergo a process called “ginning”. Ginning separates the cotton lint from the cottonseed. Once separated, the cotton lint is pressed into massive bales which are then shipped to textile mills. At the textile mills, the lint bales are spun into a yarn that is then either woven or knitted into cotton fabric. Woven fabrics are made on looms where 2 sets of fibers are threaded crossways to create the structure of the fabric. Knitted fabrics are most commonly made on circular looms where are looped and secured to each other (essentially similar to knitting by hand). Knit fibers have ingrained stretch due to the loops they are made up from whereas woven fabrics require the addition of elastane (or spandex) to create the stretch.')}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/cotton/bg-image-3.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right" style={{ top: '20rem', borderRadius: '20px 50% 50% 50%', marginLeft: '12rem' }}>
				<p>{t('The cotton plant is both a fiber and a food crop, producing fruit known as bolls.')}</p>
			</div>
		</section>
	</>
}