import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CreditCard, { Props } from '../_components/CreditCard';
import { DeleteUserCreditCard } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	creditCard: store.getIn(['userData', 'userCreditCards', props.id])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	deleteCreditCard: (data:FormData) => dispatch(DeleteUserCreditCard(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditCard);