import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CreditCardSelector, { Props } from '../_components/CreditCardSelector';
import { GetUserCreditCards } from '@af-common/UserData/_actions/UserAccountActions';
import { Toast } from '@af-common/UIData/_actions/UIDataActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	creditCards: store.getIn(['userData', 'userCreditCards']),
	loading: store.getIn(['UIData', 'loaders', 'creditCards']),
	cart_loading: store.getIn(['UIData', 'loaders', 'cart']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getCreditCards: () => {dispatch(GetUserCreditCards())},
	onToast: (text:string) => {dispatch(Toast(text))}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditCardSelector);