import * as Immutable from 'immutable';
import * as React from 'react';
import AddressFormContainer from '../_containers/AddressFormContainer';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { IUserAddressStore } from '@af-common/UserData/_stores/UserAddressesStore';
import AddressContainer from '../_containers/AddressContainer';
import { Grid, Button, Typography, Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet';
import AddLocationIcon from '@mui/icons-material/AddLocation';

export type Props = {
	addresses: Immutable.OrderedMap<Number, Immutable.Record<IUserAddressStore>>
	loading?: boolean
	getAddresses?: Function
};

type State = {
	modalOpened: boolean
}

export default class Addresses extends AwareComponent<Props, State> {
	state = { modalOpened: false }

	componentDidMount() {
		this.props.getAddresses();
	}

	render = () => <>
		<Helmet>
			<title>Addresses</title>
		</Helmet>

		<div className="user-cards">
			<h2 className="account-header">
				Addresses
				<Button color="secondary" disabled={this.props.loading} style={{float:"right"}} onClick={()=>{
					this.setState({
						modalOpened: true
					})
				}} startIcon={<AddLocationIcon/>}>Add Address</Button>
			</h2>
			
			<Grid container spacing={2}>
				{this.props.loading ? 
					<>
						<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
						<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
						<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
					</> 
				: this.props.addresses.size ?
					this.props.addresses.entrySeq().map((address, id: number) => {
						return <AddressContainer id={address[0]} key={String(address[0])}/>
					})
				:
					<Typography className="noitems" variant="body1">No Results</Typography>
				}
			</Grid>
		</div>

		<AddressFormContainer opened={this.state.modalOpened} onClose={()=>{
			this.setState({
				modalOpened: false
			})
		}}/>
	</>
}