import React, { Suspense, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ToastContainer from '@af-common/UIData/_containers/ToastContainer';
import OrderModalContainer from '@af-common/Common/Ordering/_containers/OrderModalContainer';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import Script from '@af-common/Common/_components/Script';
import config from '../../../config/config.json';
import { Helmet } from 'react-helmet-async';
import mediaQuery from 'css-mediaquery';
import WebsiteTheme from 'WebsiteTheme';
import { useDispatch, useSelector } from 'react-redux';
import { EditLoader } from '@af-common/UIData/_actions/UIDataActions';
import { CssBaseline } from '@mui/material';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import i18next, { TFunction } from 'i18next';
import CreateContainer from './Create/_containers/CreateContainer';
import AuthContainer from './Auth/_containers/AuthContainer';
import CartRouterContainer from './Cart/_containers/CartRouterContainer';
import Fabrics from './Fabrics/_components/Fabrics';
import AboutUs from './Info/_components/AboutUs';
import ContactUs from './Info/_components/ContactUs';
import TermsOfService from './Info/_components/TermsOfService';
import PrivacyPolicy from './Info/_components/PrivacyPolicy';
import SublimationPrinting from './Info/_components/SublimationPrinting';
import ReactivePrinting from './Info/_components/ReactivePrinting';
import NotFound404 from './Common/_components/NotFound404';
import SamplePack from './Info/_components/SamplePack';
import FAQ from 'Help/_components/FAQ';
import { usePageTracking } from '_hooks/usePageTracking';
import Loader from '@af-common/Common/_components/Loader';
import Home from 'Home/Home';
import OrderSamplePack from 'Info/_components/OrderSamplePack';
import Account from './Account/_components/Account';
import FabricList from './Fabrics/_components/FabricList';
import FabricCategory from './Fabrics/_components/FabricCategory';
import { FabricCategories } from '@af-common/UIData/_data/Fabrics';
import Cotton from './Fabrics/_components/Cotton';
import Hemp from './Fabrics/_components/Hemp';
import Silk from './Fabrics/_components/Silk';
import Linen from './Fabrics/_components/Linen';
import Modal from './Fabrics/_components/Modal';
import Cashmere from './Fabrics/_components/Cashmere';
import Polyester from './Fabrics/_components/Polyester';
import VeganLeather from './Fabrics/_components/VeganLeather';
import SignupContainer from './Auth/_containers/SignupContainer';
import ConfirmAccountContainer from './Auth/_containers/ConfirmAccountContainer';
import LoginContainer from './Auth/_containers/LoginContainer';
import NotValidatedContainer from './Auth/_containers/NotValidatedContainer';
import MailSentContainer from './Auth/_containers/MailSentContainer';
import AccountExistsContainer from './Auth/_containers/AccountExistsContainer';
import AccountValidated from './Auth/_components/AccountValidated';
import InvalidToken from './Auth/_components/InvalidToken';
import ForgotPasswordContainer from './Auth/_containers/ForgotPasswordContainer';
import ForgotPasswordSentContainer from './Auth/_containers/ForgotPasswordSentContainer';
import PasswordResetDoneContainer from './Auth/_containers/PasswordResetDoneContainer';
import PasswordResetContainer from './Auth/_containers/PasswordResetContainer';
import OrderContentContainer from './Account/Orders/_containers/OrderContentContainer';
import OrdersContainer from './Account/Orders/_containers/OrdersContainer';
import PatternsContainer from './Account/Patterns/_containers/PatternsContainer';
import ImagesContainer from './Account/Images/_containers/ImagesContainer';
import CreditCardsContainer from './Account/CreditCards/_containers/CreditCardsContainer';
import AddressesContainer from './Account/Address/_containers/AddressesContainer';
import SecurityContainer from './Account/Security/_containers/SecurityContainer';
import PreferencesContainer from './Account/Preferences/_containers/PreferencesContainer';
import AccountHome from './Account/AccountHome/AccountHome';
import CartPaidContainer from './Cart/_containers/CartPaidContainer';
import CartContainer from './Cart/_containers/CartContainer';
import CheckoutContainer from './Cart/_containers/CheckoutContainer';
import WebsiteStore from 'WebsiteStore';
import { useTranslation } from 'react-i18next';

export const WebsiteRoutes = (tR:TFunction) => [
    { 
        path: '/',
        Component: Website,
        errorElement: process.env.NODE_ENV === 'production' ? <></> : undefined,
		children: [
			{path: tR('/'), Component: Home },
			{path: tR('/create'), Component: CreateContainer },
			{path: tR('/about-us'), Component: AboutUs },
			{path: tR('/contact-us'), Component: ContactUs },
			{path: tR('/order-sample-pack'), Component: OrderSamplePack },
			{path: tR('/sample-pack'), Component: SamplePack },
			{path: tR('/privacy-policy'), Component: PrivacyPolicy },
			{path: tR('/terms-of-service'), Component: TermsOfService },
			{path: tR('/printing-methods/sublimation'), Component:SublimationPrinting },
			{path: tR('/printing-methods/reactive'), Component:ReactivePrinting },
			{
				path: tR('/account'), 
				Component:Account,
				children: [
					{ path: tR('/account/orders/:refId'), Component: OrderContentContainer },
					{ path: tR('/account/orders'), Component: OrdersContainer },
					{ path: tR('/account/patterns'), Component: PatternsContainer },
					{ path: tR('/account/images'), Component: ImagesContainer },
					{ path: tR('/account/settings/creditcards'), Component: CreditCardsContainer },
					{ path: tR('/account/settings/addresses'), Component: AddressesContainer },
					{ path: tR('/account/settings/security'), Component: SecurityContainer },
					{ path: tR('/account/settings/preferences'), Component: PreferencesContainer },
					{ path: tR('/account'), Component:AccountHome },
				]
			},
			{
				path: tR('/auth'), 
				Component:AuthContainer, 
				children: [
					{path: tR('/auth/login'), Component: LoginContainer},
					{path: tR('/auth/account-not-validated'), Component: NotValidatedContainer},
					{path: tR('/auth/signup'), Component: SignupContainer},
					{path: tR('/auth/signup/confirm'), Component: ConfirmAccountContainer},
					{path: tR('/auth/signup/mail'), Component: MailSentContainer},
					{path: tR('/auth/signup/exists'), Component: AccountExistsContainer},
					{path: tR('/auth/account-validated'), Component: AccountValidated},
					{path: tR('/auth/invalid-token'), Component: InvalidToken},
					{path: tR('/auth/forgot-password'), Component: ForgotPasswordContainer},
					{path: tR('/auth/forgot-password-sent'), Component: ForgotPasswordSentContainer},
					{path: tR('/auth/password-reset'), Component: PasswordResetContainer},
					{path: tR('/auth/password-reset-done'), Component: PasswordResetDoneContainer},
				]
			},
			{
				path: tR('/cart'), 
				Component: CartRouterContainer, 
				children: [
					{path: tR('/cart'), Component: CartContainer },
					{path: tR('/cart/checkout'), Component: CheckoutContainer },
					{path: tR('/cart/paid'), Component: CartPaidContainer },
				]
			},
			{
				path: tR('/fabrics'), 
				Component:Fabrics, 
				children: [
					{ path: tR('/fabrics'), Component:FabricList },
					{ path: tR('/fabrics/cotton'), element: <FabricCategory category={FabricCategories['Cotton']} categoryContent={Cotton}/> },
					{ path: tR('/fabrics/hemp'), element: <FabricCategory category={FabricCategories['Hemp']} categoryContent={Hemp}/> },
					{ path: tR('/fabrics/silk'), element: <FabricCategory category={FabricCategories['Silk']} categoryContent={Silk}/> },
					{ path: tR('/fabrics/linen'), element: <FabricCategory category={FabricCategories['Linen']} categoryContent={Linen}/> },
					{ path: tR('/fabrics/modal'), element: <FabricCategory category={FabricCategories['Modal']} categoryContent={Modal}/> },
					{ path: tR('/fabrics/cashmere'), element: <FabricCategory category={FabricCategories['Cashmere']} categoryContent={Cashmere}/> },
					{ path: tR('/fabrics/polyester'), element: <FabricCategory category={FabricCategories['Polyester']} categoryContent={Polyester}/> },
					{ path: tR('/fabrics/vegan-leather'), element: <FabricCategory category={FabricCategories['Vegan Leather']} categoryContent={VeganLeather}/> },
				]
			},
			{path: tR('/help'), Component:FAQ, exact: true},
			{path: tR(''), Component:NotFound404, exact: false},
		]
	}
]

export default function Website() {
	const { i18n } = useTranslation()
	const [tR] = useTranslation('routes')
	const lang = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('lang'))

	const dispatch = useDispatch()
	const getSDKs = () => {
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: config.FB_APP_ID,
				autoLogAppEvents: true,
				xfbml: true,
				version: 'v6.0',
				cookie: true
			});
			dispatch(EditLoader('facebook', true))
		};

		window.googleAsyncInit = function () {
			window.gapi.load('auth2', function () {
				window.gapi.auth2.init({
					client_id: config.GOOGLE_CLIENT_ID,
					cookiepolicy: 'single_host_origin'
				}).then(
					(auth: any) => dispatch(EditLoader('google', true)),
					(error: any) => { /* */ }
				);
			});
		}

		return <>
			<Script key={Date.now()} src={"https://connect.facebook.net/en_US/sdk.js"} />
			<Script async defer src="https://apis.google.com/js/platform.js?onload=googleAsyncInit" />
		</>
	}
	
	return <>
		{getSDKs()}

		<Helmet
			titleTemplate="%s | Art Fabrics"
			defaultTitle="Art Fabrics | Custom Fabric printed on demand"
		>
			<meta name="description" content="Design and print patterns, artwork, and photos permanently on natural and synthetic fabrics." />
		</Helmet>
		<CssBaseline>
			<Suspense fallback={<Loader noModal />}>
				<Outlet />
			</Suspense>
			<ScrollRestoration />
			<ToastContainer />
			<OrderModalContainer />
		</CssBaseline>
	</>

}

