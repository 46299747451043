import React, { ReactNode } from 'react'

type Props = {
    slug: string
    title: ReactNode
}

export default function FabricListCategoryLink(props:Props) {
    return <div>
        <a href={"#" + props.slug} onClick={(e) => {
            e.preventDefault();
            let fabricSectionTop = document.getElementById(props.slug)?.offsetTop || 0;
            window.scrollTo({ top: fabricSectionTop - 75, behavior: 'smooth' });
        }}>{props.title}</a>
    </div>
}