import React, { useEffect, useState } from 'react';
import { PasswordStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Grid, TextField, Button, Container } from '@mui/material';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	status?: PasswordStatus
	onSubmit?:(fd:FormData)=>{}
}

type State = {
	email: string
}

export default function ForgotPassword(props:Props) {
	const [t] = useTranslation('auth')
	const [tR] = useTranslation('routes')
	const [email,setEmail] = useState('')
	const navigate = useNavigate()

	const handleRedirects = () => {
		switch(props.status) {
			case "forgot-sent":
				navigate(tR('/auth/forgot-password-sent'));
			break;
		}
	}

	useEffect(() => {
		handleRedirects()
	}, [props.status])

	function onSubmit(e:React.FormEvent) {
		e.preventDefault();
		let fd = new FormData();
		fd.append("email", email);
		props.onSubmit(fd);
	}

	return <>
		<Helmet>
			<title>{t('Forgot your password')}</title>
		</Helmet>

		<Container className="auth-page auth-page--forgot" maxWidth="md">
			<form onSubmit={(e:React.FormEvent) => onSubmit(e)}>
				<Container maxWidth="sm">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<h2>{t('Forgot your password?')}</h2>
						</Grid>
						<Grid item xs={12}>
							<p>{t('Follow the instructions in the email we send you.')}</p>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								variant="outlined"
								fullWidth
								autoComplete="email"
								label={t('Email')}
								value={email}
								onChange={(e) => setEmail(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button className="auth-button" type="submit" variant="contained" color="primary">{t('Submit')}</Button>
						</Grid>
					</Grid>
				</Container>
			</form>
		</Container>
	</>
}