import * as React from 'react';
import { SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Container } from '@mui/material';
import { Trans } from "react-i18next";
import { Helmet } from 'react-helmet';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	setStatus?: (status: SignupStatus)=>{}
}

export default class AccountExists extends React.Component<Props> {
	componentDidMount() {
		this.props.setStatus("signup");
	}

	render = () => <>
		<Helmet>
			<title>Sign up</title>
		</Helmet>

		<Container className="auth-page" maxWidth="md">
			<h2><T>Sign up</T></h2>
			<p><T>An Account with that email already exists.</T></p>
		</Container>
	</>
}