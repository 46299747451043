import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import PasswordReset, { Props } from '../_components/PasswordReset';
import { ResetPassword } from '../_actions/AuthActions';
import { EditPasswordStatus, PasswordStatus } from '@af-common/UIData/_actions/UIDataActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	status: store.getIn(['UIData', 'passwordStatus'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	setStatus: (status:PasswordStatus) => {dispatch(EditPasswordStatus(status))},
	onSubmit: (fd:FormData) => {dispatch(ResetPassword(fd))},
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(PasswordReset);