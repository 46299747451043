import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ForgotPassword, { Props } from '../_components/ForgotPassword';
import { ForgotPassword as ForgotPasswordAction } from '../_actions/AuthActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	status: store.getIn(['UIData', 'passwordStatus'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	onSubmit: (fd:FormData) => {dispatch(ForgotPasswordAction(fd))},
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(ForgotPassword);