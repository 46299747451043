import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Preferences, { Props } from '../_components/Preferences';
import { SaveUserPrefs, GetUserPrefs } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	userPrefs: store.getIn(['userData', 'userPrefs'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getPrefs: () => {dispatch(GetUserPrefs())},
	onSave: (fd:FormData) => {dispatch(SaveUserPrefs(fd))},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Preferences);