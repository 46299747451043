import * as Immutable from 'immutable';
import * as React from 'react';
import LayerStore from '@af-create/Layer/_stores/LayerStore';
import ModuleListContainer from '@af-create/ModuleList/_containers/ModuleListContainer';
import StageContainer from '@af-create/Stage/_containers/StageContainer';
import SmallDeviceMessageDialog from '@af-create/Stage/_components/SmallDeviceMessageDialog';
import Header from './Header';
import { DocumentStatusTypes } from '@af-create/Document/_stores/DocumentStore';
import IntroDialogContainer from '@af-create/Intro/_containers/IntroDialogContainer';
import SidebarContainer from '@af-create/Sidebar/_containers/SidebarContainer';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import uuidv4 from 'uuid/v4';
import { NewDocument } from '@af-create/Sidebar/_actions/SidebarActions';
import { OnLayerAdd } from '@af-create/LayerList/_actions/LayerListActions';
import { Helmet } from 'react-helmet';
import ZoomButtonContainer from '@af-create/Tools/_containers/ZoomButtonContainer';
import { Link } from '@mui/material';
import HeaderContainer from 'Create/_containers/HeaderContainer';

export type Props = {
	isFullWidth?: boolean,
	windowWidth?: number,
	windowHeight?: number,
	viewerWidth?: number,
	viewerHeight?: number,
	selectedLayerId?: string,
	documentStatus?: DocumentStatusTypes,
	previewFabricEnabled?: boolean,
	initLab: Function,
	onWindowResize?: Function,
	onViewerResize?: Function,
	onCenterDocument?: Function,
	onWindowBlur?: Function,
	hasModules?: boolean,
	moduleCreatorOpened?: boolean,
	layerListOpened?: boolean,
	onLayerListVisibilityToggle?: Function
	onModuleCreatorOpenToggle?: Function
}

export default class Create extends React.Component<Props> {
	static defaultProps = {
		windowWidth: 0,
		windowHeight: 0,
	}

	componentDidMount(){
		if(document) {
			this.setIds(true);

			this.props.initLab((dispatch:Function) => {
				//Add first layer if there are none
				if(this.props.documentStatus == DocumentStatusTypes.New) {
					dispatch(OnLayerAdd());
					dispatch(NewDocument());
				}
			});

			// Add global events on the store that can't go through react
			window.addEventListener('resize', this.onResize.bind(this));
			window.addEventListener('blur', (e) => this.props.onWindowBlur);

			this.props.onViewerResize(window.innerWidth - 100, window.innerHeight - 65);
		}
	}

	componentWillUnmount(){
		this.setIds(false);
		window.removeEventListener('resize', this.onResize.bind(this));
		window.removeEventListener('blur', (e) => this.props.onWindowBlur);
	}

	private closeOverlays() {
		if (this.props.moduleCreatorOpened) this.props.onModuleCreatorOpenToggle()
		if (this.props.layerListOpened) this.props.onLayerListVisibilityToggle()
	}

	private onResize() {
		this.props.onWindowResize(window.innerWidth, window.innerHeight);
		let designArea = document.getElementById('design-area');
		if(designArea) {
			this.props.onViewerResize(window.innerWidth - (window.innerWidth > 960 ? 100 : 135), window.innerHeight - 64);
			this.props.onCenterDocument();
		}
	}

	private setIds(on:boolean) {
		document.querySelector('html').id = on ? "create-html" : "";
		document.querySelector('body').id = on ? "create-body" : "";
	}

	render() {
		return <>
			<Helmet>
				<title>Design Fabric</title>
				<meta name="description" content="Design your own custom printed natural or synthetic fabric using our pattern lab."/>
			</Helmet>
			<>
				<div className="window-resizer" style={{width: "100%", height:"100%"}}>
					<div className='drawer-container'>
						<HeaderContainer onClick={this.closeOverlays.bind(this)} />
						<SidebarContainer />
					</div>

					{ typeof document !== 'undefined' && <>
						<div onClick={this.closeOverlays.bind(this)} id='design-area' className={this.props.isFullWidth ? 'full-width':''}>
							<StageContainer viewerWidth={this.props.viewerWidth} viewerHeight={this.props.viewerHeight}/>
							{ this.props.selectedLayerId && !this.props.previewFabricEnabled &&
								<ModuleListContainer />
							}
						</div>
						<IntroDialogContainer opened={!this.props.hasModules}/>
					</> }
					{!this.props.previewFabricEnabled && <>
						<div className="zoom-buttons">
							<ZoomButtonContainer type="in"/>
							<ZoomButtonContainer type="out"/>
						</div>
						<div className="fabric-direction-indicator">
							<img src="/static/img/lab/fabric-direction.svg" />
						</div>
					</>  }
				</div>
			</>
			<SmallDeviceMessageDialog />
		</>
	}
}