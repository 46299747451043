import React, { useCallback, useEffect, useRef, useState } from 'react';

type Props = React.PropsWithChildren<{
    onSelect: (slug:string) => void
    selectedFabric: string
}>

export default function FabricThumbnailList(props:Props) {
    const [navOffset, setNavOffset] = useState(0);
    const scrollerRef = useRef<HTMLDivElement>(null);

    const calcNavOffset = useCallback((newValue: number) => {
        setNavOffset(prevValue => {
            let scrollerWidth = scrollerRef.current ? scrollerRef.current.offsetWidth : 0;

            //Limit the scroll diff based on window size
            let diff = Math.min(Math.abs(prevValue - newValue), Math.floor(window.innerWidth / 240) * 240);
            newValue = newValue < prevValue ? prevValue - diff : prevValue + diff;

            //Set left and right scroll limits
            newValue = Math.min(newValue, 0);
            newValue = Math.max(newValue, -Math.max(0, scrollerWidth - window.innerWidth + 30))

            return newValue
        });
    }, []);

    const onResize = useCallback(() => {
        calcNavOffset(navOffset)
    }, [navOffset, calcNavOffset])

    useEffect(() => {
        if (document) {
            window.addEventListener('resize', onResize);
        }

        return () => {
            if (document) {
                window.removeEventListener('resize', onResize);
            }
        }
    }, [onResize])

    return <div id="fabric-landing" className="fabrics-type-navigation">
        <div className="arrow-left" onClick={() => calcNavOffset(navOffset + 600)}></div>
        <div className="arrow-right" onClick={() => calcNavOffset(navOffset - 600)}></div>
        <div ref={scrollerRef} className="fabric-type-scroller" style={{ marginLeft: navOffset.toString() + 'px' }}>
            { React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    onClick: () => {
                        props.onSelect(child.props.slug)
                    },
                    selected: props.selectedFabric === child.props.slug
                })
            })}
        </div>
    </div>
}