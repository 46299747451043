import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FabricCategoryContentProps } from './FabricCategory';
import tKey from 'Common/tKey';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';


export default function Modal(props: FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/modal');

	return <>
		<Helmet>
			<title>{t('Custom printed Modal')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of natural modal fabrics.')} />
		</Helmet>

		<div className="top-banner-fabrics fabric-modal">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Modal Fabric</span> on demand, <br />no minimums!')}
									components={{
										span: <span />,
										br: <br />,
									}}
								/>
							</h1>
							<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br />
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="silk_modal"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Silk</family> Modal')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/silk/silk-modal/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="cashmere_modal"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Modal</family> Cashmere')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/modal/cashmere-modal/fabric-styled.jpg"
					/>
				</FabricThumbnailList>

			</Container>
		</section>


		<FabricInfo
			active={props.currentFabric === "silk_modal"}
			slug="silk_modal"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Silk</family> Modal')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('The combination of modal and silk that make up this fabric is a match made in heaven! The modal is sourced from beechwood trees and when combined with silk gives the fabric strength while remaining lightweight. This fabric has a fluid drape, easy to work with, and easy to care for.')}
			printDescription={t('All modal silk fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('85% beechwood modal/ 15% silk'),
				width: t('52”/ 132 cm'),
				color: t('Off-white'),
				weight: t('95 g/m2'),
				shrinkage: t('2-3% in length and width'),
				printThrough: t('Medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/silk/silk-modal/fabric-front.jpg",
				back: "/static/img/pages/fabric/silk/silk-modal/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/silk/silk-modal/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/silk/silk-modal/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves and accessories, layered clothing, flowy garments'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cashmere_modal"}
			slug="cashmere_modal"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Modal</family> Cashmere')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Two wonder fibers combine to bring you modal cashmere, a fabric that is lightweight, soft, and strong. The high modal cotent makes this fabric fabric strong, breathable, and highly drapable, while the cashmere fibers enhance the softness and warmth. Modal cashmere is a wonderous blend of function and luxury.')}
			printDescription={t('All modal cashmere fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('85% modal/ 15% cashmere'),
				width: t('52”/ 132 cm'),
				color: t('Light beige'),
				weight: t('95g/m2'),
				shrinkage: t('2-3% in length and width'),
				printThrough: t('Medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/modal/cashmere-modal/fabric-front.jpg",
				back: "/static/img/pages/fabric/modal/cashmere-modal/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/modal/cashmere-modal/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/modal/cashmere-modal/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves and accessories, layered clothing, flowy garments'),
				},
			]}
		/>

		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Modal Fabric?')}</h3>
				<p>{t('Modal is a super interesting, unique fabric. It’s a bio-based, semi-synthetic fabric, which means in this case that it’s derived from natural materials but undergoes a processing step to turn it into a usable fibre. Modal is derived from beechwood trees, where the cellulose fibres from beechwood pulp are processed into yarn. Not only is the resulting modal fabric extremely soft and durable, but it makes for an environmentally-friendly alternative to cotton, one of its most comparable fabrics. Modal is considered a type of rayon, however, it tends to be much more durable and malleable than standard rayon, making it a great standalone fabric or as a blend with another fibre of choice.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{ top: '15rem' }}>
				<img src="/static/img/pages/fabric/modal/bg-image-1.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('It requires 10-20 times less water to grow beechwood when compared to an equivalent amount of cotton, making it much less environmentally taxing.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('A Brief History...')}</h3>
				<p>{t('Modal is a relatively new fabric, especially when compared to fabrics like silk or linen. Modal was first brought into creation in Japan in the 1950s, however it really gained momentum as a fabric when the Austrian company Lenzing began producing it in 1965. Lenzing has since trademarked the fabric and is the number 1 producer of modal in the world. Other fabric spinoffs have since been created - including Micro Modal and Modal Air - which just goes to show that the history and development of modal is still continuing today.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '58rem' }}>
				<img src="/static/img/pages/fabric/modal/bg-image-2.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('Modal is 50% more absorbent than cotton')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{ top: '18rem' }}>
				<h3>{t('How It\'s Made')}</h3>
				<p>{t('The production of modal is somewhat more complex than strictly natural fabrics. While modal fibres are derived from beechwood trees, which grow in abundance in northern and central Europe, the beechwood pulp must undergo a production process in order to be made into usable fibres. After the beechwood trees are harvested, the cellulose fibres are removed from the pulp. These fibres are spun in a spinnerette with a solution of sodium hydroxide, which creates modal fibres. The fibres are then soaked in sulfuric acid to create modal yarn. This yarn can be knitted or woven into soft, durable modal fabric.')}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/modal/bg-image-3.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right" style={{ top: '20rem', borderRadius: '0 50% 50% 50%', marginLeft: '12rem' }}>
				<p>{t('Because modal fibre is derived from beechwood trees, the fabric is 100% biodegradable')}</p>
			</div>
		</section>
	</>
}