import React, { useEffect, useState } from 'react';
import { SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Container, Grid, TextField, Button } from '@mui/material';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	status?: SignupStatus
	setStatus?: (status: SignupStatus)=>{}
	confirmAccount?: (fd:FormData)=>{}
}

type State = {
	email: string
	password: string
}

export default function ConfirmAccount(props:Props) {
	const [tR] = useTranslation('routes')
	const [email,setEmail] = useState('')
	const [password,setPassword] = useState('')
	const navigate = useNavigate()

	const handleRedirects = () => {
		switch(props.status) {
			case "success":
				navigate(tR('/account'));
			break;
			case "validation":
				navigate(tR('/auth/account-not-validated'));
			break;
		}
	}

	useEffect(() => {
		props.setStatus("signup");
	}, [])

	useEffect(() => {
		handleRedirects();
	}, [props.status])

	function onConfirmAccount(e:React.FormEvent) {
		e.preventDefault();
		let fd = new FormData();
		fd.append("password", password);
		props.confirmAccount(fd);
	}

	return <>
		<Helmet>
			<title>Confirm your account</title>
		</Helmet>
		<Container id="auth-confirm" className="auth-page" maxWidth="md">
			<h2><T>Sign Up</T></h2>
			<p><T>An Art Fabrics account with this email already exists. Please enter your password to confirm that this is you, and we'll link both accounts together.</T></p>
			<form onSubmit={(e:React.FormEvent) => onConfirmAccount(e)}>
				<Container maxWidth="xs">
					<Grid container spacing={2}>
						<Grid item xs={12} style={{display: "none"}}>
							<TextField
								variant="outlined"
								fullWidth
								autoComplete="email"
								label={<T>Email</T>}
								value={email}
								onChange={(e) => setEmail(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								autoComplete="current-password"
								label={<T>Current Password</T>}
								type="password"
								value={password}
								onChange={(e) => setPassword(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button type="submit" variant="contained" className="auth-button" color="primary"><T>Confirm</T></Button>
						</Grid>
					</Grid>
				</Container>
			</form>
		</Container>
	</>
}