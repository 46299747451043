import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import Cart, { Props } from '../_components/Cart';
import { GetUserCart } from '@af-common/UserData/_actions/UserCartActions';
import { EditCartStatus, CartStatus } from '@af-common/UIData/_actions/UIDataActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	cart: store.getIn(['userData', 'userCart']),
	cart_loading: store.getIn(['UIData', 'loaders', 'cart']),
	loggedIn: store.getIn(['userData', 'loggedIn']),
	status: store.getIn(['UIData', 'cartStatus']),
	fabrics: store.getIn(['UIData', 'fabrics']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getUserCart: () => dispatch(GetUserCart()),
	setStatus: (status:CartStatus) => {dispatch(EditCartStatus(status))}
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(Cart);