import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FabricCategoryContentProps } from './FabricCategory';
import tKey from 'Common/tKey';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';

export default function Linen(props:FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/linen')
	return <>
		<Helmet>
			<title>{t('Custom printed Linen')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of natural linen fabrics.')}/>
		</Helmet>

		<div className="top-banner-fabrics fabric-linen">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Linen Fabric</span> on demand, <br />no minimums!')}
									components={{
										span: <span/>,
										br: <br/>,
									}}
								/>
							</h1>
							<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br/>
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="essential_cotton_linen"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Essential Cotton</family> Linen')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/linen/essential-cotton-linen/fabric-styled.jpg"
					/>
					<FabricThumbnail
						slug="cotton_linen_canvas"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Cotton Linen</family> Canvas')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-styled.jpg"
					/>
				</FabricThumbnailList>
				
			</Container>
		</section>

		<FabricInfo
			active={props.currentFabric === "essential_cotton_linen"}
			slug="essential_cotton_linen"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Essential Cotton</family> Linen')}
				components={{
					family: <div className="fabric-family"/>
				}}
			/>}
			description={t('Just like the name says, this cotton linen blend is a staple! At an almost equal blend of cotton and linen, it is balanced and pleasing to the touch and has the classic linen look. The blend of linen and cotton means you get the best benefits of both fibers. Easy to work with and lightweight enough for most clothing basics, you’ll love working with this fabric.')}
			printDescription={t('All cotton linen fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('55% cotton/ 45% linen'),
				width: t('52”/132 cm'),
				color: t('light natural'),
				weight: t('140 g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low'),
			}}
			images={{
				front: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-front.jpg",
				back: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/linen/essential-cotton-linen/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Blouses, dresses, light clothing, light accessories and home decor'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "cotton_linen_canvas"}
			slug="cotton_linen_canvas"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Cotton Linen</family> Canvas')}
				components={{
					family: <div className="fabric-family"/>
				}}
			/>}
			description={t('Our cotton-linen blend is a medium weight, semi-structured fabric. It has large surface fibers so the canvas weave gives texture to your prints. It is the perfect choice when making tea towels, home decor items or loose-fitting garments. This fabric is highly absorbent. This fabric is not ideal for solid prints as it has some inherent flaws from seeds and thick fibers that may show small breaks in solid colors.')}
			printDescription={t('All cotton linen fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('68% cotton/32% linen'),
				width: t('57”/145cm'),
				color: t('White'),
				weight: t('280g/m2'),
				shrinkage: t('2-3% in length and 4-5% in width'),
				printThrough: t('Low, roughly 10%'),
			}}
			images={{
				front: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-front.jpg",
				back: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/cotton/cotton-linen-canvas/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t('Home decor'),
					description: t('including curtains, pillow cases, table cloths, and tea towels.'),
				},
				{
					title: t('Clothing'),
					description: t('including loose-fitting garments, skirts and slacks, overalls, blazers and light coats.'),
				},
				{
					title: t('Accessories'),
					description: t('including bags/totes, headwear, zipper pouches.'),
				},
			]}
		/>

		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Linen Fabric?')}</h3>
				<p>{t('Linen is a strong, absorbent, completely natural fabric that is made from the fibre of the flax plant. Linen is a type of bast fibre, which is essentially a plant fibre located in the inner bark of certain plants, such as flax and hemp. Bast fibres are soft and flexible, and they tend to have a higher tensile strength than other plant fibres. The somewhat coarse texture of linen fabric is often attributed to the irregular polygonal shapes of those fibres.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{top: '15rem'}}>
				<img src="/static/img/pages/fabric/linen/bg-image-1.jpg"/>
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('Dollar bills in the United States and various other countries are a textile made of 75% cotton and 25% linen.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('A Brief History...')}</h3>
				<p>{t('Linen textiles are among the oldest in the world. The earliest record of any linen-like fabric dates back to thirty-six thousand years ago, when dyed flax fibres were found in a cave in Georgia. In ancient times linen was considered both a luxury for the wealthy, upper class, and a clean, pure garment to be worn by priests. Linen was being produced up to 4000 years ago in ancient Egypt, then was eventually brought to Ireland by the Phoenicians sometime before the common era. Over time Belfast came to be the most famous linen producing centre in history, with the majority of the world\'s linen being produced there through the Victorian era.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{top: '58rem'}}>
				<img src="/static/img/pages/fabric/linen/bg-image-2.jpg"/>
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('Linen is the fabric of choice for painting canvas.')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{top: '18rem'}}>
				<h3>{t('How It\'s Made')}</h3>
				<p>{t('The harvesting technique is extremely important in the production of linen as the quality of the finished product is largely dependent upon having the longest possible fibres to eventually spin into yarn. For this reason, flax is typically harvested by hand, followed by a lengthy and intensive production process to ready the fibres for spinning. Following all of this, the now long, soft flax fibres are spun together into linen yarn to be either woven or knitted into linen textiles. An alternate method of linen production exists - known as "cottonizing" - which essentially puts the flax fibres through the same process as standard cotton production. This method is quicker and simpler, however, the finished linen product tends to lose much of its standard linen characteristics.')}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/linen/bg-image-3.jpg"/>
			</div>

			<div className="floating-bubble floating-bubble-right" style={{top: '20rem', borderRadius: '0 50% 50% 50%', marginLeft: '12rem'}}>
				<p>{t('The oldest known dress in the world was made of linen, dating back to around 2300 BC.')}</p>
			</div>
		</section>
	</>
}