import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SaveUserCurrency } from '@af-common/UserData/_actions/UserAccountActions';
import WebsiteStore from 'WebsiteStore';

export type Props = {
	opened: boolean
	onClose: ()=>void
}

export default function CurrencyChanger(props:Props) {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const userCurrency = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('currency'))

	const [currency,setCurrency] = useState(userCurrency)

	useEffect(() => {
		setCurrency(userCurrency)
	}, [userCurrency])

	const onConfirm = (e:React.MouseEvent) => {
		e.preventDefault();
		let fd = new FormData();
		fd.append("currency", currency);
		dispatch(SaveUserCurrency(fd))
		props.onClose();
	}

	return <Dialog open={props.opened} onClose={props.onClose}>
		<form>
			<DialogTitle>{t('Select Currency')}</DialogTitle>
			<DialogContent>
				<RadioGroup name="currency" value={currency} onChange={(e) => setCurrency(e.currentTarget.value)}>
					<FormControlLabel value="CAD" control={<Radio color="primary"/>} label="CAD" />
					<FormControlLabel value="USD" control={<Radio color="primary"/>} label="USD" />
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" autoFocus onClick={props.onClose}>{t('Cancel')}</Button>
				<Button color="secondary" className="secondary-primary" type="submit" onClick={onConfirm}>{t('Confirm')}</Button>
			</DialogActions>
		</form>
	</Dialog>
}