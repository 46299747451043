import AwareComponent from '@af-common/Common/_components/AwareComponent';
import PromptContainer from '@af-common/Common/_containers/PromptContainer';
import { Grid, Paper, TextField, Button } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Helmet } from 'react-helmet';

export type Props = {
	onSave?: Function
	getSecurity?: Function
	userSecurity?: Immutable.OrderedMap<string, any>
};

type State = {
	email: string
	email_error: string
	password_new: string
	password_new_confirm: string
	password_new_confirm_error: string
	confirmPassOpened: boolean
}

export default class Security extends AwareComponent<Props, State> {
	state:State = {
		email: "",
		email_error: "",
		password_new: "",
		password_new_confirm: "",
		password_new_confirm_error: "",
		confirmPassOpened: false
	};

	componentDidMount = () => {
		this.propsToState();
		this.props.getSecurity();
	}

	componentDidUpdate = (_prevProps:Props, _prevState:State) => {
		if(this.propChanged(_prevProps, "userSecurity")){
			this.propsToState();
		}

		if(this.stateChanged(_prevState, 'email')) {
			let valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				.test(this.state.email.toLowerCase());
			let error = "";
			if(!valid) error = "Please enter a valid email.";
			this.setState({email_error: error});
		}

		if(this.stateChanged(_prevState, 'password_new', 'password_new_confirm')) {
			let error = "";
			if(this.state.password_new !== this.state.password_new_confirm)
				error = "Passwords do not match.";
			this.setState({password_new_confirm_error: error});
		}
	}

	private propsToState = () => {
		this.setState({
			email: this.props.userSecurity.get("email"),
		});
	}

	private onSaveClick = (e:React.FormEvent) => {
		e.preventDefault();

		this.setState({
			confirmPassOpened: true
		});
	}

	private onSave = (password_check:string) => {
		let fd = new FormData();
		fd.append("email", this.state.email);
		fd.append("password_new", this.state.password_new);
		fd.append("password_new_confirm", this.state.password_new_confirm);
		fd.append("password_check", password_check);
		this.props.onSave(fd);
	}

	render = () => <>
		<Helmet>
			<title>Security</title>
		</Helmet>

		<h2 className="account-header">Security</h2>
		<Paper className="account-security" style={{padding: "1rem"}}>
			<form id="account-security-form" noValidate onSubmit={this.onSaveClick}>
				<Grid container spacing={2}>
					<Grid item sm={12}>
						<h3>Email</h3>
						<TextField
							variant="outlined"
							label='Email'
							type="email"
							autoComplete="email"
							helperText={this.state.email_error}
							FormHelperTextProps={{error: true}}
							value={this.state.email}
							onChange={(e) => this.setState({
								email: e.currentTarget.value
							})}
						/>
					</Grid>
					<Grid item sm={6}>
						<h3>Change my password</h3>
						<TextField
							variant="outlined"
							type="password"
							label='New password'
							autoComplete="new-password"
							value={this.state.password_new}
							onChange={(e) => this.setState({
								password_new: e.currentTarget.value
							})}
						/>
					</Grid>
					<Grid item sm={6}>
						<h3>Confirm password</h3>
						<TextField
							variant="outlined"
							type="password"
							label='Confirm password'
							helperText={this.state.password_new_confirm_error}
							FormHelperTextProps={{error: true}}
							value={this.state.password_new_confirm}
							autoComplete="off"
							onChange={(e) => this.setState({
								password_new_confirm: e.currentTarget.value
							})}
						/>
					</Grid>
					<Grid item sm={12}>
						<Button disabled={!!this.state.email_error || !!this.state.password_new_confirm_error} type="submit" variant="contained" color="primary" className="account-security-savebtn" form="account-security-form">Save</Button>
					</Grid>
				</Grid>
			</form>
		</Paper>
		<PromptContainer
			opened={this.state.confirmPassOpened}
			title={"Enter your password"}
			message={"We must verify your password in order to change your account settings."}
			name={"Current Password"}
			type={"password"}
			input={this.onSave}
			onClose={() => this.setState({
				confirmPassOpened: false
			})}
			dismissible
		/>
	</>
}
