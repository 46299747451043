import UserCartStore from '@af-common/UserData/_stores/UserCartStore';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';

export type Props = {
	getUserCart?:Function
	cart?: UserCartStore
	cart_loading?: boolean
	loggedIn?: boolean
};

export default class CartRouter extends React.Component<Props> {
	state = { paid: false }

	componentDidMount() {
		this.props.getUserCart();
	}

	render = () => { return this.props.cart_loading ? <></> :
		<>
			<Header />
				<div className="cart">
					<Outlet/>
				</div>
			<Footer />
		</>
	}
}