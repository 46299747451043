import { EditSignupStatus, SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ResendMail } from '../_actions/AuthActions';
import NotValidated, { Props } from '../_components/NotValidated';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	status: store.getIn(['UIData', 'signupStatus'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	setStatus: (status:SignupStatus) => {dispatch(EditSignupStatus(status))},
	resendMail: () => {dispatch(ResendMail())},
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(NotValidated);