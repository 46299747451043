import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import UserCartStore from '@af-common/UserData/_stores/UserCartStore';
import { UserAddressStore } from '@af-common/UserData/_stores/UserAddressesStore';
import Loader from '@af-common/Common/_components/Loader';
import AddressContainer from '../../Address/_containers/AddressContainer';
import { Grid, Typography, Divider } from '@mui/material';
import CartContentContainer from '../../../Cart/_containers/CartContentContainer';
import CartSummary from '../../../Cart/_components/CartSummary';
import Tag, { TagColor }from '@af-common/Common/_components/Tag';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GetUserOrder } from '@af-common/UserData/_actions/UserAccountActions';

export type Props =  {
	order?: UserCartStore,
	address?: UserAddressStore
};

export default function OrderContent(props:Props) {
	const dispatch = useDispatch()
	const [t] = useTranslation();
	const params = useParams<{ refId: string }>()

	useEffect(() => {
		let fd = new FormData();
		fd.append('id', params.refId);
		dispatch(GetUserOrder(fd))
	}, [params.refId]);

	const shipping = props.order.getIn(['availableShippingTypes', props.order.get('id_shipping_type')]);
	const trackingNumber = props.order.get('tracking_number');
	let trackingLink:string|undefined;

	if(trackingNumber) {
		// Carrier tracking numbers patterns from https://www.iship.com/trackit/info.aspx?info=24 AND https://www.canadapost.ca/web/en/kb/details.page?article=how_to_track_a_packa&cattype=kb&cat=receiving&subcat=tracking
		const isUPS = new RegExp('^1Z[A-H,J-N,P,R-Z,0-9]{16}$');               // UPS tracking numbers usually begin with "1Z", contain 18 characters, and do not contain the letters "O", "I", or "Q".
		const isFedEx = new RegExp('^[0-9]{12}$|^[0-9]{15}$');                 // FedEx Express tracking numbers are normally 12 digits long and do not contain letters AND FedEx Ground tracking numbers are normally 15 digits long and do not contain letters.
		const isUSPS = new RegExp('^[0-9]{20,22}$|^[A-Z]{2}[0-9,A-Z]{9}US$');  // USPS Tracking numbers are normally 20-22 digits long and do not contain letters AND USPS Express Mail tracking numbers are normally 13 characters long, begin with two letters, and end with "US".
		const isDHL = new RegExp('^[0-9]{10,11}$');                            // DHL tracking numbers are normally 10 or 11 digits long and do not contain letters.
		const isCAPost = new RegExp('^[0-9]{16}$|^[A-Z]{2}[0-9]{9}[A-Z]{2}$'); // 16 numeric digits (0000 0000 0000 0000) AND 13 numeric and alphabetic characters (AA 000 000 000 AA).
	
		if(isUPS.test(trackingNumber)) trackingLink = 'https://wwwapps.ups.com/WebTracking/processInputRequest?AgreeToTermsAndConditions=yes&loc=en_US&tracknum='+trackingNumber+'&Requester=trkinppg';
		if(isFedEx.test(trackingNumber)) trackingLink = 'https://www.fedex.com/apps/fedextrack/index.html?tracknumber='+trackingNumber+'';
		if(isUSPS.test(trackingNumber)) trackingLink = 'https://tools.usps.com/go/TrackConfirmAction?tLabels='+trackingNumber+'';
		if(isDHL.test(trackingNumber)) trackingLink = 'http://www.dhl.com/en/express/tracking.html?AWB='+trackingNumber+'&brand=DHL';
		if(isCAPost.test(trackingNumber)) trackingLink ='https://www.canadapost.ca/trackweb/en#/search?searchFor='+trackingNumber+'';
	}

	return props.order && props.order.get('ref_id') == params.refId ? <>
		<Helmet>
			<title>Order #{props.order.get('ref_id')}</title>
		</Helmet>

		<h2 className="account-header">Order #{props.order.get('ref_id')}<Tag text={props.order.get('status')} /></h2>
		{ trackingNumber ? <h4>
			{ t('Tracking number: {{value}}', { 
				value: trackingLink ? '' : trackingNumber
			}) }
			{ trackingLink ? <a href={trackingLink} target="_blank" style={{textDecoration: 'underline', color: '#c96142'}}>{ trackingNumber }</a> : null }
		</h4> : null }
		<Grid container spacing={2} className="cart-page--orders">
			<Grid item xs={6} className="user-cards">
				<h3 className="account-subheader">Shipping Address</h3>
				<AddressContainer id={props.order.get('id_shipping_address')} displayMode="cart" noEdit/>
			</Grid>
			<Grid item xs={6} className="user-cards">
				<h3 className="account-subheader">Billing Address</h3>
				<AddressContainer id={props.order.get('id_billing_address')} displayMode="cart" noEdit/>
			</Grid>
			{ shipping &&
				<Grid item xs={12} className="account-shippingservice">
					<Divider />
					<h3 className="account-subheader">Shipping Service</h3>
					<Typography variant="body2">{ shipping.get('name') }, { shipping.get('description') }</Typography>
				</Grid>
			}
			<Grid item xs={12}>
				<Divider />
				<CartContentContainer cart={props.order} subtotal={false} editable={false}/>
			</Grid>
			<Grid item xs={12}>
				<CartSummary noEdit cart={props.order} />
			</Grid>
		</Grid>
	</> : <Loader noModal/>
}