import DocumentHeightContainer from '@af-create/Document/_containers/DocumentHeightContainer';
import DocumentUnitsContainer from '@af-create/Document/_containers/DocumentUnitsContainer';
import DocumentWidthContainer from '@af-create/Document/_containers/DocumentWidthContainer';
import { DocumentUnitTypes } from '@af-create/Document/_stores/DocumentStore';
import * as CONSTS from '@af-create/_config/Consts';
import { AppBar, Hidden, Icon, IconButton, Toolbar, MenuItem, FormControl, InputLabel, Switch, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SidebarContainer from '../../Common/_containers/SidebarContainer';
import { Trans, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import LockRatioButtonContainer from '../_containers/LockRatioButtonContainer';
import FitToImageButtonContainer from '../_containers/FitToImageButtonContainer';
import DocumentActionButtonsContainer from '@af-create/Tools/_containers/DocumentActionButtonsContainer';
import { Fabric } from '@af-common/UIData/_data/Fabrics';
import { batch } from 'react-redux';
import UserNav from '../../Common/_components/UserNav';

type Props = {
	onClick?: (e: React.MouseEvent) => void
	onToggleRepeatMode?: Function
	repeatMode?: 'tile' | 'panel',
	fabrics?: Immutable.OrderedMap<number, Fabric>
	onChangeWidth?: (value: number) => void,
	onChangeHeight?: (value: number) => void,
	onChangeLockRatio?: (value: boolean) => void,
	documentWidth: number
	documentHeight: number
}

const T = ({ children, ...otherProps }: any) => <Trans ns="create" {...otherProps}>{children}</Trans>

export default function Header(props: Props) {
	const [tR] = useTranslation('routes')
	const [menuOpen, setMenuOpen] = useState(false)
	const [labelWidth, setLabelWidth] = useState(0)
	const [selectedFabric, setSelectedFabric] = useState(0)
	const inputRef = useRef<HTMLLabelElement>(null)

	useEffect(() => {
		setLabelWidth(inputRef.current.offsetWidth)
	}, [])

	useEffect(() => {
		//Reset fabric selector if document width/height was changed by something else and doesnt match anymore
		const fabricSize = props.fabrics.get(Number(selectedFabric))?.get('sizes').find(entry => entry.get('slug') == "meter");
		if (fabricSize && (fabricSize.get('width') * 150 != props.documentWidth || fabricSize.get('height') * 150 != props.documentHeight)) {
			setSelectedFabric(0)
		}
	}, [])

	return <>
		<div onClick={props.onClick}>
			<AppBar id="nav" position="static">
				<Toolbar className="top-nav">
					<div className="main-nav">
						<div className="left-wrapper">
							<IconButton className="menu-button" onClick={() => setMenuOpen(true)}>
								<Icon>menu</Icon>
							</IconButton>
							<div className="brand-wrapper">
								<div className="brand"><Link to={tR('/')}><img src="static/img/logo.svg" alt="Art Fabrics" width="220" height="40" /></Link></div>
								<div className="brand brand-icon"><Link to={tR('/')}><img src="static/img/icon.svg" alt="Art Fabrics" width="220" height="40" /></Link></div>
							</div>
							<span className="tile-size">
								<p><T>PANEL</T></p>
								<Switch checked={props.repeatMode === 'tile'} color="secondary" onChange={(e) => props.onToggleRepeatMode()} />
								<p className="active"><T>TILE</T></p>
							</span>

							{props.repeatMode === 'panel' ? <FormControl className="size-field" style={{ width: '12rem' }}>
								<InputLabel id="fabric-preset" ref={inputRef}><T>Fabric presets</T></InputLabel>
								<Select labelId="fabric-preset" name='fabric-preset' value={selectedFabric} onChange={(e) => {
									const fabricSize = props.fabrics.get(Number(e.target.value)).get('sizes').find(entry => entry.get('slug') == "meter");

									setSelectedFabric(Number(e.target.value))

									batch(() => {
										props.onChangeLockRatio(false);
										props.onChangeWidth(fabricSize.get('width') * 150);
										props.onChangeHeight(fabricSize.get('height') * 150);
									})
								}}>
									<MenuItem key={"disabled"} value={0} disabled selected><T>Select fabric...</T></MenuItem>
									{props.fabrics.entrySeq().map(([key, fabric]) => {
										return <MenuItem key={fabric.get('slug')} value={key}>{fabric.get('name')}</MenuItem>
									})}
								</Select>
							</FormControl> : null}
							<DocumentWidthContainer
								label={props.repeatMode === 'tile' ? <T>Tile Width</T> : <T>Panel Width</T>}
								type="number"
								useUnits
								className="size-field"
							/>
							<LockRatioButtonContainer />
							<DocumentHeightContainer
								label={props.repeatMode === 'tile' ? <T>Tile Length</T> : <T>Panel Length</T>}
								type="number"
								useUnits
								className="size-field"
							/>
							<FormControl className="size-field">
								<InputLabel id="document_units-label" ref={inputRef}><T>Units</T></InputLabel>
								<DocumentUnitsContainer labelId="document_units-label" name='document_units'>
									<MenuItem key={0} value={DocumentUnitTypes.Pixels}><T>Pixels</T></MenuItem>
									<MenuItem key={1} value={DocumentUnitTypes.Inches}><T>Inches</T></MenuItem>
									<MenuItem key={2} value={DocumentUnitTypes.Centimeters}><T>Centimeters</T></MenuItem>
								</DocumentUnitsContainer>
							</FormControl>
						</div>

						{props.repeatMode === 'tile' ? <div className="doc-action-btns-visibility on-header">
							<DocumentActionButtonsContainer flexDirection="row" />
						</div> : null}
					</div>

					<UserNav showCurrency={false} />
				</Toolbar>
			</AppBar>
			<SidebarContainer temporary menuOpen={menuOpen} onMenuClose={() => setMenuOpen(false)} />
		</div>
	</>
}