import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FabricCategoryContentProps } from './FabricCategory';
import tKey from 'Common/tKey';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';

export default function Cashmere(props: FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/cashmere');

	return <>
		<Helmet>
			<title>{t('Custom printed Cashmere')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of natural cashmere fabrics.')} />
		</Helmet>

		<div className="top-banner-fabrics fabric-cashmere">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Cashmere Fabric</span> on demand, <br />no minimums!')}
									components={{
										span: <span />,
										br: <br />,
									}}
								/>
							</h1>
							<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br />
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="cashmere_modal"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Modal</family> Cashmere')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/modal/cashmere-modal/fabric-styled.jpg"
					/>
				</FabricThumbnailList>

			</Container>
		</section>

		
		<FabricInfo
			active={props.currentFabric === "cashmere_modal"}
			slug="cashmere_modal"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Modal</family> Cashmere')}
				components={{
					family: <div className="fabric-family"/>
				}}
			/>}
			description={t('Two wonder fibers combine to bring you modal cashmere, a fabric that is lightweight, soft, and strong. The high modal cotent makes this fabric fabric strong, breathable, and highly drapable, while the cashmere fibers enhance the softness and warmth. Modal cashmere is a wonderous blend of function and luxury.')}
			printDescription={t('All modal cashmere fabrics are printed on-demand with our vibrant and permanent reactive inks.')}
			specs={{
				content: t('85% modal/ 15% cashmere'),
				width: t('52”/ 132 cm'),
				color: t('Light beige'),
				weight: t('95g/m2'),
				shrinkage: t('2-3% in length and width'),
				printThrough: t('Medium'),
			}}
			images={{
				front: "/static/img/pages/fabric/modal/cashmere-modal/fabric-front.jpg",
				back: "/static/img/pages/fabric/modal/cashmere-modal/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/modal/cashmere-modal/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/modal/cashmere-modal/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Scarves and accessories, layered clothing, flowy garments'),
				},
			]}
		/>

		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Cashmere Fabric?')}</h3>
				<p>{t('Cashmere - or more officially cashmere wool - is one of those fabrics that people buy with the intention of keeping forever. It is known as a luxurious fibre, with a reputation right up there with silk. It’s both incredibly strong and gorgeously soft, which has made it highly sought after for centuries. Cashmere fibres are collected from the wool of goats, who are either shorn much like sheep or brushed during their shedding season in order to collect their wool. As such, cashmere is a 100% natural, biodegradable fibre, making it a great sustainable fabric choice.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{ top: '15rem' }}>
				<img src="/static/img/pages/fabric/cashmere/bg-image-1.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('Cashmere is the lightest naturally occurring wool')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('A Brief History...')}</h3>
				<p>{t('Cashmere, as a fabric, has a very long history of use, dating back thousands of years to Nepal, Mongolia and India. Kashmir in northern India was predominantly responsible for developing the cashmere scarf industry back in the 16th century, featuring colourful patterns and motifs that were laboriously woven by hand. In time cashmere scarves developed into more products, including shawls. Within a couple hundred years cashmere shawls had become so popular globally that Kashmir began shipping their handmade shawls to Britain and France. In order to keep up with this high demand in Europe cashmere products moved away from hand stitching and began being embroidered with needles.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '58rem' }}>
				<img src="/static/img/pages/fabric/cashmere/bg-image-2.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('Cashmere wool can be up to 8 times warmer than regular sheep’s wool')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{ top: '18rem' }}>
				<h3>{t('How It\'s Made')}</h3>
				<p>{t('Cashmere fabric is a long and laborious process to create, which is one of the reasons this fabric is considered luxurious. Because cashmere, like wool, is an animal byproduct, its production is 100% dependent on the growth of the goat’s wool. It takes a single goat 1 full year to produce enough cashmere to make a single scarf. Once the wool is harvested from the goat the soft undercoat must be separated from the coarse guard hairs. The fine undercoat hairs are then spun into yarn which can be dyed, knitted or woven into cashmere fabric. Because cashmere fibres are so fine all this work must be done by hand, making the process that much longer and more labour intensive.')}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/cashmere/bg-image-3.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right" style={{ top: '20rem', borderRadius: '0 50% 50% 50%', marginLeft: '12rem' }}>
				<p>{t('Biblical and Afghan historical references to goat’s wool scarves in Kashmir prove that the practice of harvesting and using cashmere fabric dates back as far as the 3rd century BCE')}</p>
			</div>
		</section>
	</>
}