import * as React from 'react';
import { Skeleton, Grid } from '@mui/material';
import config from '../../../../../config/config.json'
import AwareComponent from '@af-common/Common/_components/AwareComponent';

export type Props = {
	newsletter?: boolean
	facebookSignup?: (fd:FormData)=>{}
	googleSignup?: (fd:FormData)=>{}
	facebookReady?: boolean
	googleReady?: boolean
}

type State = {
	facebookDone: boolean
	googleDone: boolean
}

export default class IntegrationButtons extends AwareComponent<Props, State> {
	state = { facebookDone: false, googleDone: false }

	componentDidMount() {
		this.setState({facebookDone: false, googleDone: false});
		this.registerCallbacks();
		this.createButtons();
	}

	componentDidUpdate(_prevProps:Props) {
		if(this.propChanged(_prevProps, 'googleReady')) {
			this.createGoogle();
		}

		if(this.propChanged(_prevProps, 'facebookReady')) {
			this.createFacebook();
		}
	}

	private createButtons() {
		this.createFacebook();
		this.createGoogle();
	}

	private createFacebook() {
		if(typeof(window.FB) !== "undefined") {
			window.FB.XFBML.parse(null, () => {
				this.setState({facebookDone: true});
			});
		}
	}

	private createGoogle() {
		if(typeof(window.gapi) == "undefined" || typeof(window.gapi.signin2) == "undefined") return;

		window.gapi.signin2.render('google-signin-button', {
			scope: "profile email",
			height: "40",
			theme: "dark",
			onsuccess: "onGoogleSignup"
		});

		this.setState({googleDone: true});
	}

	private registerCallbacks() {
		const me = this;

		if(typeof window === 'undefined') return;

		let fbSignup = () => {
			let fd = new FormData();
			fd.append("newsletter", me.props.newsletter ? '1' : '0');
			me.props.facebookSignup(fd);
		}

		window.onFacebookSignup = () => {
			window.FB.login(function(response:any) {
				if (response.status === 'connected') {
					fbSignup();
				} else {
					// The person is not logged into your webpage or we are unable to tell.
				}
			}, {
				auth_type: 'reauthorize',
				scope: 'email'
			});
		}

		window.onGoogleSignup = () => {
			const auth2 = window.gapi.auth2.getAuthInstance() || window.gapi.auth2.init({
				client_id: config.GOOGLE_CLIENT_ID,
				cookiepolicy: 'single_host_origin'
			});

			auth2.attachClickHandler(document.getElementById('google-signin-button'), {},
				(googleUser:any) => {
					let fd = new FormData();
					fd.append("id_token", googleUser.getAuthResponse().id_token);
					fd.append("newsletter", me.props.newsletter ? '1' : '0');
					me.props.googleSignup(fd);
				},
				(error:string) => {
					// console.log(error);
				}
			);
		}
	}

	private getFacebook = () => <div
		style={{display: this.state.facebookDone ? "block" : "none"}}
		className="fb-login-button"
		data-size="large"
		data-width="100%"
		data-button-type="continue_with"
		data-auto-logout-link="false"
		data-use-continue-as="true"
		data-onlogin="onFacebookSignup"
	/>

	private getGoogle = () => <div 
		style={{display: this.state.googleDone ? "block" : "none"}}
		id="google-signin-button"
	/>

	render = () => <Grid container spacing={2}>
		<Grid item xs={12}>
			<>
				{this.getFacebook()}
				{!this.state.facebookDone && <Skeleton variant="rectangular" width={"100%"} height={40}/>}
			</>
		</Grid>
		<Grid item xs={12}>
			<>
				{this.getGoogle()}
				{!this.state.googleDone && <Skeleton variant="rectangular" width={"100%"} height={40}/>}
			</>
		</Grid>
	</Grid>
}