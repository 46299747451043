import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Button, Checkbox, Container, FormControlLabel, Grid, Hidden, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IntegrationButtonsContainer from '../_containers/IntegrationButtonsContainer';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import tKey from 'Common/tKey';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	status?: SignupStatus
	websiteSignup?: (fd:FormData)=>{}
}

type State = {
	name: string
	email: string
	password: string
	newsletter: boolean
}

export default function Signup(props:Props) {
	const [t] = useTranslation('auth')
	const [tR] = useTranslation('routes')
	const [name,setName] = useState('')
	const [email,setEmail] = useState('')
	const [password,setPassword] = useState('')
	const [newsletter,setNewsletter] = useState(false)
	const navigate = useNavigate()

	const handleRedirects = () => {
		switch(props.status) {
			case "success":
				navigate(tR('/account'), { replace: true });
			break;
			case "validation":
				navigate(tR('/auth/account-not-validated'), { replace: true });
			break;
			case "mail":
				navigate(tR('/auth/signup/mail'), { replace: true });
			break;
			case "confirm":
				navigate(tR('/auth/signup/confirm'));
			break;
			case "exists":
				navigate(tR('/auth/signup/exists'));
			break;
		}
	}

	useEffect(() => {
		handleRedirects()
	}, [props.status])

	function onWebsiteSignup(e:React.FormEvent) {
		e.preventDefault();
		let fd = new FormData();
		fd.append("email", email);
		fd.append("name", name);
		fd.append("password", password);
		fd.append("newsletter", newsletter ? '1' : '0');
		props.websiteSignup(fd);
	}
	
	return <>
		<Helmet>
			<title>{t('Sign up')}</title>
		</Helmet>

		<Container className="auth-page" maxWidth="md">
			<h2>{t('Sign up')}</h2>
			<Typography component="p">{t('Already have an account?')} <Link to={tR('/auth/login')}>{t('Log in here')}</Link>.</Typography>
			<form onSubmit={(e:React.FormEvent) => onWebsiteSignup(e)}>
				<Grid container spacing={2}>
					<Grid item xs={"auto"} sm={2} md={1} />
					<Grid item xs={12} sm={8} md={4} container spacing={1}>
						<Grid item xs={12}>
							<p>{t('Save yourself some time!')}</p>
						</Grid>
						<Grid item xs={12}>
							<IntegrationButtonsContainer newsletter={newsletter} />
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={!!Number(newsletter)}
										onChange={(e) => setNewsletter(e.target.checked)}
										color="primary"
									/>
								}
								label={t('I want to receive the newsletter')}
							/>
						</Grid>
					</Grid>
					<Hidden mdUp>
						<Grid item xs={"auto"} sm={2} md={1} />
						<Grid item xs={"auto"} sm={2} md={1} />
					</Hidden>
					<Hidden smDown><Grid item sm={2} className="vertical_separator"/></Hidden>
					<Grid item xs={12} sm={8} md={4} container spacing={1}>
						<Grid item xs={12}>
							<p>{t('Or create a new account')}</p>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								variant="outlined"
								fullWidth
								autoComplete="name"
								label={t('Name')}
								value={name}
								onChange={(e) => setName(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								variant="outlined"
								fullWidth
								autoComplete="email"
								label={t('Email')}
								value={email}
								onChange={(e) => setEmail(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								variant="outlined"
								fullWidth
								autoComplete="new-password"
								label={t('Password')}
								type="password"
								value={password}
								onChange={(e) => setPassword(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={!!Number(newsletter)}
										onChange={(e) => setNewsletter(e.target.checked)}
										color="primary"
									/>
								}
								label={t('I want to receive the newsletter')}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button className="auth-button" type="submit" variant="contained" color="primary">{t('Sign up')}</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
			{ <small>
				<Trans
					t={t}
					i18nKey={tKey('By signing up with us, you agree to our <link>Terms of Service</link>.')}
					components={{
						link: <Link to={tR('/terms-of-service')} target="_blank"/>
					}}
				/>
			</small> }
		</Container>
	</>
}