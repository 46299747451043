import React, { useState } from 'react';
import { Typography, Grid, TextField, Button, Hidden } from '@mui/material';
import { Link } from 'react-router-dom';
import IntegrationButtonsContainer from '../_containers/IntegrationButtonsContainer';
import { Trans, useTranslation } from "react-i18next";

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	websiteLogin?: (fd:FormData)=>{}
}

export default function LoginContent(props:Props) {
	const [t] = useTranslation('auth')
	const [tR] = useTranslation('routes');

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	function onWebsiteLogin(e:React.FormEvent) {
		e.preventDefault();
		let fd = new FormData();
		fd.append("email", email);
		fd.append("password", password);
		props.websiteLogin(fd);
	}

	return <div id="auth-login" className="auth-page">
		<h2>{t('Login')}</h2>
		<Typography component="p">{t('Looking to register a new account?')} <Link to={tR('/auth/signup')}>{t('Click here')}</Link>.</Typography>
		<form onSubmit={(e) => onWebsiteLogin(e)} >
			<Grid container spacing={2}>
				<Grid item xs={"auto"} sm={2} md={1} />
				<Grid item xs={12} sm={8} md={4} container spacing={1}>
					<Grid item xs={12}>
						<p>{t('Save yourself some time!')}</p>
					</Grid>
					<Grid item xs={12}>
						<IntegrationButtonsContainer />
					</Grid>
				</Grid>
				<Hidden mdUp>
					<Grid item xs={"auto"} sm={2} md={1} />
					<Grid item xs={"auto"} sm={2} md={1} />
				</Hidden>
				<Hidden smDown><Grid item sm={2} className="vertical_separator"/></Hidden>
				<Grid item xs={12} sm={8} md={4} container spacing={1}>
					<Grid item xs={12}>
						<p>{t('Or login with your email')}</p>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							variant="outlined"
							fullWidth
							autoComplete="email"
							label={t('Email')}
							value={email}
							onChange={(e) => setEmail(e.currentTarget.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							variant="outlined"
							fullWidth
							autoComplete="new-password"
							label={t('Password')}
							type="password"
							value={password}
							onChange={(e) => setPassword(e.currentTarget.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<small>{t('Forgot your password?')} <Link to={tR('/auth/forgot-password')}>{t('Click here')}</Link>.</small>
					</Grid>
					<Grid item xs={12}>
						<Button className="auth-button" type="submit" variant="contained" color="primary">{t('Login')}</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	</div>
}