import React, { useEffect, useRef, useState } from 'react';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { CardElement, Elements, useElements, useStripe} from '@stripe/react-stripe-js';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Checkbox, FormControlLabel } from '@mui/material';
import ReCaptcha from "@af-common/Common/_components/ReCaptcha";
import config from '../../../../../../config/config.json'
import { AjaxResponse } from '@af-common/Common/_actions/AjaxAction';
import { Dispatch } from 'redux';
import Loader from '@af-common/Common/_components/Loader';
import ModalContainer from '@af-common/Common/_containers/ModalContainer';

export type Props = {
	opened: boolean
	onClose: (e:any)=>void
	isPayment?: boolean
	onToast?: Function
	onSubmit?: Function
	callback?: () => void
	loggedIn?: boolean
	cart_loading?: boolean
};

export default function CreditCardForm(props: Props) {
	const [name, setName] = useState('');
	const [nameChanged, setNameChanged] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [saveCard, setSaveCard] = useState(false);

	const recaptchaRef = useRef<ReCaptcha>(null);

	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		setName('');
		setNameChanged(false);
		setDisabled(false);
	}, [props.opened])

	const getSave = () => {
		if(!props.isPayment) return true;
		else if(!props.loggedIn) return false;
		else return saveCard;
	}

	const onValidate = (e:React.MouseEvent) => {
		setDisabled(true);

		e.preventDefault();
		if(!name) {
			props.onToast("Cardholder name cannot be empty");
			setDisabled(false);
			return;
		}

		recaptchaRef.current.execute();
	}

	const onSubmit = async () => {
		if(props.isPayment) {
			onPayment();
		} else {
			onSave();
		}
	}

	const onPayment = async () => {
		let error = "";

		setDisabled(true);

		let recaptchaToken = recaptchaRef.current.getResponse();
		if(!recaptchaToken) error = "An error occurred"

		let intentData = new FormData();
		intentData.append('recaptchaToken', recaptchaToken);
		let clientSecret = await fetch('ajax/checkout/stripe/get-payment-intent', {method: 'POST', body: intentData})
			.then(response => {
				if(!response.ok) throw new Error("An error occurred");
				return response;
			})
			.then(response => response.json())
			.then(response => response.data.secret)
			.catch(response => {});

		if(!clientSecret) error = "An error occurred"

		if(error == "") {
			stripe.confirmCardPayment(clientSecret, {
				save_payment_method: true,
				payment_method: {
					card: elements.getElement('card'),
					billing_details: {
						name: name,
					},
				}
			}).then(async function(result:any) {
				if (result.error) {
					props.onToast(result.error.message);
				} else {
					let confirmData = new FormData();
					confirmData.append('id', result.paymentIntent.id);
					confirmData.append('saveCard', String(+getSave()));
					let success = await fetch('ajax/checkout/stripe/confirm-payment-intent', {method: 'POST', body: confirmData})
						.then(response => {
							if(!response.ok) throw new Error("An error occurred");
							return response;
						})
						.then(function(response:any) {
							if(props.callback){
								props.callback();
							};
						});
				}
			}).then(function(response:any) {
				if(recaptchaRef.current) {
					recaptchaRef.current.reset();
				}
				setDisabled(false);
			});
		} else {
			props.onToast(error);
			recaptchaRef.current.reset();
			setDisabled(false);
		}
	}

	const onSave = async () => {
		let error = "";

		setDisabled(true);

		let recaptchaToken = recaptchaRef.current.getResponse();
		if(!recaptchaToken) error = "An error occurred"

		let intentData = new FormData();
		intentData.append('recaptchaToken', recaptchaToken);
		let clientSecret = await fetch('ajax/user/credit-cards/get-card-intent', {method: 'POST', body: intentData})
			.then(response => response.json())
			.then(response => response.data.secret)
			.catch(response => {});

		if(!clientSecret) error = "An error occurred"

		if(error == "") {
			stripe.confirmCardSetup(
				clientSecret, {
					payment_method: {
						card: elements.getElement('card'),
						billing_details: {
							name: name,
						},
					},
				}
			).then(function(result:any) {
				if (result.error) {
					props.onToast(result.error.message);
				} else {
					let formData = new FormData();
					formData.append('paymentMethod', result.setupIntent.payment_method);
					props.onSubmit(formData, (response: AjaxResponse, dispatch:Dispatch) => {
						if(props.callback){
							props.callback();
						}
					});
				}
				props.onClose(null);
				recaptchaRef.current.reset();
				setDisabled(false);
			});
		} else {
			props.onToast(error);
			recaptchaRef.current.reset();
			setDisabled(false);
		}
	}

	const isDisabled = disabled || props.cart_loading;
	let nameInvalid = !name && nameChanged;

	return (
		<Dialog className="credit-card-form" open={props.opened} onClose={props.onClose}>
			<form>
				<DialogTitle>Add Credit Card</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						fullWidth
						disabled={isDisabled}
						label='Cardholder name'
						variant="outlined"
						value={name}
						error={nameInvalid}
						helperText={nameInvalid ? "Cannot be empty" : ""}
						onChange={(e) => {
							setName(e.currentTarget.value);
							setNameChanged(true);
						}}
					/>
					<CardElement options={{disabled: isDisabled}}/>
					{ (props.isPayment && props.loggedIn) && <>
						<FormControlLabel 
							control={
								<Checkbox
									disabled={isDisabled}
									checked={saveCard}
									onChange={e => setSaveCard(e.currentTarget.checked)}
									color="primary"
								/>
							}
							label="Save this card in my account for future use."
						/>
					</> }
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={props.onClose} color="primary">Cancel</Button>
					<ReCaptcha 
						disabled={isDisabled}
						ref={e => recaptchaRef.current = e}
						siteKey={config.RECAPTCHA_KEY}
						action="Submit"
						onValidate={onValidate}
						onSubmit={onSubmit}
					/>
				</DialogActions>
			</form>
			{ isDisabled && <ModalContainer preventClose opened={true} animate content={<Loader />} /> }
		</Dialog>
	);
}