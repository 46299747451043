import UserCartStore from '@af-common/UserData/_stores/UserCartStore';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Hidden from 'af-common/src/Common/_components/Hidden';
import Dinero from 'dinero.js';
import * as React from 'react';
import { Trans } from 'react-i18next';
import CartItemContainer from '../_containers/CartItemContainer';

export type Props = {
	cart: UserCartStore
	subtotal?: boolean
	editable?: boolean
	lang?: string
	currency?: string
}

const T = ({children, ...otherProps}:any) => <Trans ns="cart" {...otherProps}>{children}</Trans>

export default class CartContent extends React.Component<Props>{
	render = () => {
		let discount:Dinero.Dinero = Dinero(this.props.cart.get('discount').toObject());
		return <Table>
			<TableHead>
				<TableRow>
					<TableCell><T>Pattern</T></TableCell>
					<Hidden smDown>
						<TableCell><T>Size</T></TableCell>
						<TableCell><T>Fabric</T></TableCell>
					</Hidden>
					<TableCell><T>Quantity</T></TableCell>
					<TableCell><span className="cart-page--price"><T>Price</T></span></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{this.props.cart.get('items').entrySeq().map(([id, item]) => {
					return <CartItemContainer key={id} id={id} item={item} editable={this.props.editable} />
				})}
				{ /*this.props.subtotal && <>
					<TableRow>
						<TableCell>
							<Typography variant="subtitle2"><T>Subtotal</T></Typography>
						</TableCell>
						<Hidden smDown>
							<TableCell></TableCell>
							<TableCell></TableCell>
						</Hidden>
						<TableCell></TableCell>
						<TableCell>
							<Typography variant="subtitle2" className="cart-page--price">{Dinero(this.props.cart.get('subtotal').toObject()).toFormat()}</Typography>
						</TableCell>
					</TableRow>
					{ discount.greaterThan(Dinero({amount: 0, currency: discount.getCurrency() as Dinero.Currency})) ?
						<TableRow>
							<TableCell>
								<Typography variant="subtitle2"><T>Discount</T></Typography>
							</TableCell>
							<Hidden smDown>
								<TableCell></TableCell>
								<TableCell></TableCell>
							</Hidden>
							<TableCell></TableCell>
							<TableCell>
								<Typography variant="subtitle2" className="cart-page--price discount-price">{discount.multiply(-1).toFormat()}</Typography>
							</TableCell>
						</TableRow>
					: null }
				</>*/ }
			</TableBody>
		</Table>
	}
}