import { EditSignupStatus, SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AccountExists, { Props } from '../_components/AccountExists';

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	setStatus: (status:SignupStatus) => {dispatch(EditSignupStatus(status))},
});

export default connect(
	null, mapDispatchToProps
)(AccountExists);