import { createAjaxAction, AjaxResponse } from '@af-common/Common/_actions/AjaxAction';
import { Toast, EditSignupStatus, EditPasswordStatus } from '@af-common/UIData/_actions/UIDataActions';
import { EditUserLoggedIn, EditUserFetched, EditUserValidated } from '@af-common/UserData/_actions/UserDataActions';
import { GetAccountData, RemoveAccountData } from '@af-common/UserData/_actions/UserAccountActions';
import { Dispatch, AnyAction } from "redux";
import { NewDocument } from '@af-create/Sidebar/_actions/SidebarActions';
import { OnLayerAdd } from '@af-create/LayerList/_actions/LayerListActions';

const signupSuccess = (response:AjaxResponse & {reason?: string, error?: string}, dispatch:Dispatch<AnyAction>, method: string) => {
	if(response.data.reason) {
		switch(response.data.reason) {
			case "validation":
				dispatch(EditUserLoggedIn(true));
				dispatch(EditSignupStatus("validation"));
			break;
			
			case "confirm":
				dispatch(EditUserLoggedIn(false));
				dispatch(EditSignupStatus("confirm"));
			break;
			
			case "mail":
				dispatch(EditUserLoggedIn(true));
				dispatch(EditSignupStatus("mail"));
			break;
		}
	} else {
		dispatch(EditUserValidated(true));
		dispatch(EditUserLoggedIn(true));
		dispatch(EditSignupStatus("success"));
		dispatch(EditUserFetched(false));
		dispatch(GetAccountData());
	}

	window.fbq('track', 'CompleteRegistration', {
		content_name: 'Sign up: '+method,
		status: true
	});
			
	window.gtag('event', 'sign_up', {
		method: method
	});
};

const signupFailure = (response:AjaxResponse & {reason?: string, error?: string}, dispatch:Dispatch<AnyAction>) => {
	if(!response.data.reason || response.data.reason == "error"){
		dispatch(EditUserLoggedIn(false));
		dispatch(Toast('An error occurred'));
	} else if(response.data.reason == "exists") {
		dispatch(EditUserLoggedIn(false));
		dispatch(EditSignupStatus("exists"));
	}
};

const loginSuccess = (response:AjaxResponse, dispatch:Dispatch<AnyAction>) => {
	if(response.data.success) {
		dispatch(EditUserValidated(true));
		dispatch(EditUserLoggedIn(true));
		dispatch(EditSignupStatus("success"));
		dispatch(EditUserFetched(false));
		dispatch(GetAccountData());
	} else if(response.data.reason && response.data.reason == "validation"){
		dispatch(EditUserLoggedIn(true));
		dispatch(EditSignupStatus("validation"));
	} else {
		dispatch(EditUserLoggedIn(false));
		dispatch(Toast('An error occurred'));
	}
};

const loginFailure = (response:AjaxResponse, dispatch:Dispatch<AnyAction>) => {
	if(response.data.reason && response.data.reason == "password") {
		dispatch(EditUserLoggedIn(false));
		dispatch(Toast('Wrong password.'));
	} else {
		dispatch(EditUserLoggedIn(false));
		dispatch(Toast('An error occurred'));
	}
};

const logout = (response:AjaxResponse, dispatch:Dispatch<AnyAction>) => {
	if(response.data.success) {
		dispatch(EditUserLoggedIn(false));
		dispatch(RemoveAccountData());
		dispatch(EditUserFetched(false));
		dispatch(GetAccountData());
		dispatch(OnLayerAdd());
		dispatch(NewDocument());
	} else {
		dispatch(Toast('An error occurred'));
	}
};

const resendMail = (response:AjaxResponse, dispatch:Dispatch<AnyAction>) => {
	if(response.data.success) {
		dispatch(EditSignupStatus("mail"));
	} else {
		dispatch(Toast('An error occurred'));
	}
};

const forgotPassword = (response:AjaxResponse, dispatch:Dispatch<AnyAction>) => {
	dispatch(EditPasswordStatus("forgot-sent"));
};

const newPasswordSuccess = (response:AjaxResponse, dispatch:Dispatch<AnyAction>) => {
	if(response.data.success) {
		dispatch(EditPasswordStatus("reset-done"));
	} else {
		dispatch(Toast('An error occurred'));
	}
};

const newPasswordFailure = (response:AjaxResponse, dispatch:Dispatch<AnyAction>) => {
	if(response.data.reason && response.data.reason == "mismatch") {
		dispatch(Toast('Passwords do not match'));
	} else if(response.data.reason && response.data.reason == "invalid-token") {
		dispatch(EditPasswordStatus("invalid-token"));
	} else {
		dispatch(Toast('An error occurred'));
	}
};

export const WebsiteSignup = (data:FormData) => createAjaxAction({
	url: "ajax/auth/websiteSignup",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => signupSuccess(response, dispatch, 'basic'),
	onFailure: (response, dispatch) => signupFailure(response, dispatch)
});

export const FacebookSignup = (data:FormData) => createAjaxAction({
	url: "ajax/auth/facebookSignup",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => signupSuccess(response, dispatch, 'facebook'),
	onFailure: (response, dispatch) => signupFailure(response, dispatch)
});

export const GoogleSignup = (data:FormData) => createAjaxAction({
	url: "ajax/auth/googleSignup",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => signupSuccess(response, dispatch, 'google'),
	onFailure: (response, dispatch) => signupFailure(response, dispatch)
});

export const ConfirmAccount = (data:FormData) => createAjaxAction({
	url: "ajax/auth/confirmAccount",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => loginSuccess(response, dispatch),
	onFailure: (response, dispatch) => loginFailure(response, dispatch)
});

export const WebsiteLogin = (data:FormData) => createAjaxAction({
	url: "ajax/auth/login",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => loginSuccess(response, dispatch),
	onFailure: (response, dispatch) => loginFailure(response, dispatch)
});

export const Logout = () => createAjaxAction({
	url: "ajax/auth/logout",
	method: "POST",
	data: null,
	onSuccess: (response, dispatch) => logout(response, dispatch),
	onFailure: (response, dispatch) => logout(response, dispatch)
});

export const ResendMail = () => createAjaxAction({
	url: "ajax/auth/resendMail",
	method: "POST",
	data: null,
	onSuccess: (response, dispatch) => resendMail(response, dispatch),
	onFailure: (response, dispatch) => resendMail(response, dispatch)
});

export const ForgotPassword = (data:FormData) => createAjaxAction({
	url: "ajax/auth/password-request",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => forgotPassword(response, dispatch),
	onFailure: (response, dispatch) => forgotPassword(response, dispatch)
});

export const ResetPassword = (data:FormData) => createAjaxAction({
	url: "ajax/auth/new-password",
	method: "POST",
	data: data,
	onSuccess: (response, dispatch) => newPasswordSuccess(response, dispatch),
	onFailure: (response, dispatch) => newPasswordFailure(response, dispatch)
});