import React, { useEffect, useState } from 'react';
import { PasswordStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Grid, TextField, Button, Container } from '@mui/material';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	status?: PasswordStatus
	onSubmit?:(fd:FormData)=>{}
	setStatus?: (status: PasswordStatus)=>{}
}

type State = {
	password: string
	confirm: string
	token: string
}

export default function PasswordReset(props:Props) {
	const [t] = useTranslation('auth')
	const [tR] = useTranslation('routes')
	const [password, setPassword] = useState('')
	const [confirm, setConfirm] = useState('')
	const [token, setToken] = useState('')
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	const handleRedirects = () => {
		switch(props.status) {
			case "reset-done":
				navigate(tR('/auth/password-reset-done'));
			break;
			case "invalid-token":
				navigate(tR('/auth/invalid-token'));
			break;
		}
	}

	useEffect(() => {
		props.setStatus("reset");
		setToken(searchParams.get('token'))
		handleRedirects();
	}, [])

	useEffect(() => {
		handleRedirects();
	}, [props.status])
	

	function onSubmit(e:React.FormEvent) {
		e.preventDefault();
		let fd = new FormData();
		fd.append("password", password);
		fd.append("confirm", confirm);
		fd.append("token", token);
		props.onSubmit(fd);
	}

	return <>
		<Helmet>
			<title>{t('Reset your password')}</title>
		</Helmet>

		<Container className="auth-page auth-page--forgot" maxWidth="md">
			<form onSubmit={(e:React.FormEvent) => onSubmit(e)}>
				<Container maxWidth="sm">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<h2>{t('Reset your password')}</h2>
						</Grid>
						<Grid item xs={12}>
							<p>{t('Please choose a new password for your account.')}</p>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								variant="outlined"
								fullWidth
								autoComplete="new-password"
								label={t('Password')}
								type="password"
								value={password}
								onChange={(e) => setPassword(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								variant="outlined"
								fullWidth
								autoComplete="off"
								label={t('Confirm')}
								type="password"
								value={confirm}
								onChange={(e) => setConfirm(e.currentTarget.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button className="auth-button" type="submit" variant="contained" color="primary">{t('Reset Password')}</Button>
						</Grid>
					</Grid>
				</Container>
			</form>
		</Container>
	</>
}