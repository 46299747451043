import * as React from 'react';
import * as ReactDOM from 'react-dom';
import config from '../../../../../config/config.json'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import Loader from '@af-common/Common/_components/Loader';
import ModalContainer from '@af-common/Common/_containers/ModalContainer';

export type Props = {
	lang?: string
	currency?: string
	onToast?: Function
	cart_loading?: boolean
	disabled?: boolean
	onPay: Function
}

type State = {
	alive: boolean
	disabled: boolean
	component: any
}

export default class PayPalButton extends React.Component<Props, State> {
	state = { alive: false, component: undefined as any, disabled: false }

	componentDidMount() {
		this.setState({alive: true}, this.getSDK);
	}

	componentWillUnmount() {
		this.setState({alive: false});
	}

	private createOrder = (data:any, actions:any) => {
		this.setState({disabled: true});
		let controller = new AbortController();
		let options:RequestInit = {method: 'POST', signal: controller.signal};

		return fetch('ajax/checkout/paypal/create-order', options)
			.then(response => {
				if(!response.ok) throw new Error("An error occurred");
				return response;
			})
			.then(response => response.json())
			.then(response => response.data.id)
			.catch(response => {
				this.setState({disabled: false});
				this.props.onToast("An error occurred");
			})
	}

	private onApprove = (data:any, actions:any) => {
		this.setState({disabled: true});
		let fd = new FormData();
		fd.append('id', data['orderID']);

		let controller = new AbortController();
		let options:RequestInit = {method: 'POST', signal: controller.signal, body: fd};

		return fetch('ajax/checkout/paypal/on-approve', options)
			.then(response => {
				if(!response.ok) throw new Error("An error occurred");
				return response;
			})
			.then(response => response.json())
			.then(response => {
				this.setState({disabled: false});
				this.props.onPay();
			})
			.catch(response => {
				this.setState({disabled: false});
				this.props.onToast("An error occurred");
			})
	}

	private getSDK = () => {
		if(typeof document === 'undefined') {
			return;
		}
		
		if(typeof paypal == "undefined"){
			let script = document.createElement("script");
			script.onload = () => {
				if(this.state.alive) this.setState({component: paypal.Buttons.driver('react', { React, ReactDOM })});
			};
			document.body.appendChild(script);
			script.src = "https://www.paypal.com/sdk/js?client-id=" + config.PAYPAL_CLIENT_ID + "&disable-funding=credit,card&locale=" + this.props.lang + "&currency=" + this.props.currency;
		} else {
			if(this.state.alive) this.setState({component: paypal.Buttons.driver('react', { React, ReactDOM })});
		}
	}

	render = () => {
		const disabled = this.props.cart_loading || this.props.disabled || this.state.disabled;
		let PayPalComponent = this.state.component;
		return <>
			{PayPalComponent && <div className={disabled ? 'paypal-disabled' : ''}>
				<PayPalComponent
					createOrder={ (data:any, actions:any) => this.createOrder(data, actions) }
					onApprove={ (data:any, actions:any) => this.onApprove(data, actions) }
					onCancel={() => {
						this.props.onToast("Payment was cancelled");
						this.setState({disabled: false});
					}}
					onError={() => {
						this.props.onToast("An error occurred");
						this.setState({disabled: false});
					}}
				/>
			</div>}
			{ this.state.disabled && <ModalContainer preventClose opened={true} animate content={<Loader />} /> }
		</>
}
}