import PaginationContainer from '@af-common/Common/Pagination/_containers/PaginationContainer';
import { CircularProgress, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as React from 'react';
import { Trans } from 'react-i18next';
import Breadcrumbs from '../../Breadcrumbs/_components/Breadcrumbs';
import OrderContainer from '../_containers/OrderContainer';
import { Helmet } from 'react-helmet';
import ItemList from '@af-common/Common/Pagination/ItemList';

export type Props = {
	compactVersion?: boolean
	maxItemsCount?: number
};

const T = ({children, ...otherProps}:any) => <Trans ns="account" {...otherProps}>{children}</Trans>

export default class Orders extends React.Component<Props> {
	getHeader = () => <TableHead>
		<TableRow>
			<TableCell role="columnheader" scope="col">Order #</TableCell>
			<TableCell className="hide-on-phone-only" role="columnheader" scope="col">Order Placed</TableCell>
			<TableCell role="columnheader" scope="col">Total</TableCell>
			<TableCell role="columnheader" scope="col">Status</TableCell>
			<TableCell className="hide-on-tablet-and-phone" role="columnheader" scope="col">Ship to</TableCell>
			<TableCell className="" role="columnheader" scope="col">Actions</TableCell>
		</TableRow>
	</TableHead>

	render() {
		let breadCrumbs = this.props.compactVersion ? <></> : <Breadcrumbs />;
		return <>
			{!this.props.compactVersion && <Helmet>
				<title>Orders</title>
			</Helmet> }

			<div className="orders_container" >
				{ /* breadCrumbs */ }
				<ItemList
					dataType="orders"
					limit={this.props.maxItemsCount}
					controlled={this.props.compactVersion}
					item={(item) => <OrderContainer item={item}/>}
					render={(items, links, loading) => 
						<>
							<Grid container className="pagination-header" spacing={this.props.compactVersion ? 0 : 2}>
								<Grid item xs={12} md={8}><h3>{ this.props.compactVersion ? <T>Recent Orders</T> : <T>Orders</T> }</h3></Grid>
							</Grid>
							{loading ? <CircularProgress className="pagination-loader"/> : 
							(items ? <>
								<TableContainer variant="outlined" component={Paper}>
									<Table>
										{this.getHeader()}
										<TableBody>
											{items}
										</TableBody>
									</Table>
								</TableContainer>
								{ !this.props.compactVersion ? links : null } </> :
								<span className="pagination-noitems">No Results</span>
							)}
						</>
					}
				/>
			</div>
		</>
	}
}
