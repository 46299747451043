import { UserAddressStore } from '@af-common/UserData/_stores/UserAddressesStore';
import UserCartStore from '@af-common/UserData/_stores/UserCartStore';
import Dinero from 'dinero.js';
import * as React from 'react';
import { TableCell, TableRow } from '@mui/material';
import {  useNavigate } from 'react-router';
import Tag from '@af-common/Common/_components/Tag';
import { useTranslation } from 'react-i18next';

export type Props = {
	item?: UserCartStore
	address?: UserAddressStore
};

export default function Order(props:Props) {
	const [tR] = useTranslation('routes')
	const navigate = useNavigate()
	const openOrder = (e:React.MouseEvent) => {
		navigate(tR('/account/orders/{{ref_id}}', {ref_id:  props.item.ref_id}));
	}

	const date = new Date(props.item.get('date_paid'));
	return <>
		<TableRow>
			<TableCell>
				{props.item.ref_id}
			</TableCell>
			<TableCell className="hide-on-phone-only">
				{date.toLocaleDateString('en-CA')}
			</TableCell>
			<TableCell>
				{Dinero(props.item.get('total').toObject()).toFormat()}
			</TableCell>
			<TableCell>
				<Tag text={props.item.get('status')} />
			</TableCell>
			<TableCell className="hide-on-tablet-and-phone">
				{props.address && props.address.get('name') }
			</TableCell>
			<TableCell>
				<div className="seemore" onClick={(e:React.MouseEvent) => openOrder(e)} key={props.item.ref_id}>See more</div>
			</TableCell>
		</TableRow>
	</>
}
