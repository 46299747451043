import AwareComponent from '@af-common/Common/_components/AwareComponent';
import Loader from '@af-common/Common/_components/Loader';
import { CartStatus } from '@af-common/UIData/_actions/UIDataActions';
import { CartAddressType } from '@af-common/UserData/_actions/UserCartActions';
import UserCartStore, { RegularItem } from '@af-common/UserData/_stores/UserCartStore';
import { Button, Container, Grid, Paper, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import AddressSelectorContainer from '../../Account/Address/_containers/AddressSelectorContainer';
import TopBanner from '../../Common/_components/TopBanner';
import PayPalContainer from '../../Common/_containers/PayPalContainer';
import StripeContainer from '../../Common/_containers/StripeContainer';
import CartSummaryContainer from '../_containers/CartSummaryContainer';
import CartContentContainer from '../_containers/CartContentContainer';
import PromptContainer from '@af-common/Common/_containers/PromptContainer';
import { Helmet } from 'react-helmet';
import Dinero from 'dinero.js';
import { Fabric } from '@af-common/UIData/_data/Fabrics';

export type Props = {
	status?: CartStatus
	setStatus?: (status: CartStatus) => {}
	fetchCart?: Function
	cart?: UserCartStore
	getUserCart?: Function
	saveCartAddress?: Function
	address_loading?: boolean
	cart_loading?: boolean
	userEmail?: string
	saveUserSecurity?: Function
	loggedIn?: boolean
	fabrics?: Immutable.OrderedMap<number, Fabric>
};

type State = {
	previousShipping: string
	checkoutGuestOpened: boolean
}

const T = ({ children, ...otherProps }: any) => <Trans ns="cart" {...otherProps}>{children}</Trans>

export default function Checkout(props: Props) {
	const [tR] = useTranslation('routes')
	const navigate = useNavigate()
	const [previousShipping, setPreviousShipping] = useState(undefined)
	const [checkoutGuestOpened, setCheckoutGuestOpened] = useState(false)

	const handleRedirects = () => {
		switch (props.status) {
			case "paid":
				navigate(tR('/cart/paid'));

				if (!props.cart) break;

				const subtotal = Dinero(props.cart.get('subtotal').toObject());
				const discount = Dinero(props.cart.get('discount').toObject());
				const shipping = Dinero(props.cart.get('shipping').toObject()).toUnit();
				const taxes = Dinero(props.cart.get('taxes').toObject()).toUnit();
				const value = subtotal.subtract(discount).toUnit();
				const currency = subtotal.getCurrency();
				let num_items = 0;
				let contents: { id: string, quantity: number }[] = [];
				let content_ids: string[] = [];
				let items: {
					item_id: string,
					item_name: string,
					price: number,
					quantity: number,
				}[] = [];


				props.cart.get('items').forEach((item) => {
					num_items += item.get('quantity');
					let id = item.get('type');
					let name: string = item.get('type');

					if (item instanceof RegularItem) {
						const fabricName = props.fabrics?.get(item.get('fabric').get('id_fabric'))?.get('name');
						const fabricSize = props.fabrics?.get(item.get('fabric').get('id_fabric'))?.get('sizes').get(item.get('fabric').get('id_size'))?.get('name');
						id += '-' + item.get('fabric').get('id_fabric') + '-' + item.get('fabric').get('id_size');
						name = 'Custom Fabric: ' + fabricName + ' | ' + fabricSize;
					}

					contents.push({
						id: id,
						quantity: item.get('quantity'),
					});
					content_ids.push(id);

					items.push({
						item_id: id,
						item_name: name,
						price: Dinero(item.get('price').toObject()).toUnit(),
						quantity: item.get('quantity'),
					})
				});

				window.fbq('track', 'Purchase', {
					content_type: 'product',
					content_ids: content_ids,
					value: value,
					num_items: num_items,
					currency: currency,
					contents: contents,
				});

				window.gtag('event', 'purchase', {
					transaction_id: props.cart.get('ref_id'),
					currency: currency,
					value: value,
					shipping: shipping,
					tax: taxes,
					items: items,
				});
				break;
		}
	}

	useEffect(() => {
		props.setStatus("checkout");
		props.getUserCart();
	}, [])

	useEffect(() => {
		handleRedirects();
	}, [props.status])

	/*useEffect(() => {
		if (props.cart.get('pickup') && !_prevProps.cart.get('pickup') && _prevProps.cart.get('id_shipping_address')) {
			setState({ previousShipping: String(_prevProps.cart.get('id_shipping_address')) })
		}
	}, [props.cart])*/

	const onPay = () => {
		props.setStatus("paid");
	}

	const setPickup = (value: boolean) => {
		let id = value ? null : previousShipping || null;
		saveCartAddress(id, 'shipping', value);
	}

	const saveCartAddress = (id: string, type: CartAddressType, pickup: boolean = false) => {
		let fd = new FormData();
		if (id) fd.append('id', id);
		fd.append('pickup', String(pickup));
		fd.append('type', type);
		props.saveCartAddress(fd);
	}

	const onCheckoutGuestSubmit = (email: string) => {
		let fd = new FormData();
		fd.append('email', email)
		props.saveUserSecurity(fd);
	}

	let nopay =
		!props.cart ||
		!props.cart.get('id_billing_address') ||
		(!props.cart.get('pickup') && !props.cart.get('id_shipping_address'));

	const contentStyle = props.cart_loading ? { opacity: 0.5, pointerEvents: 'none' as 'none' } : {};

	return <>
		<Helmet>
			<title>Checkout</title>
		</Helmet>
		<Container className="cart-page" maxWidth="lg" style={contentStyle}>
			<Grid container spacing={2} className={"user-cards" + (props.address_loading ? ' disabled' : '')}>
				<Grid item xs={12} md={6}>
					<span className="cart-page--title">
						<h3><T>Shipping Address</T></h3>
						{ /*<FormControlLabel
								control={<Switch
									checked={props.cart.get('pickup')}
									onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setPickup(e.target.checked)}}
									value="isPickup"
									color={"primary"}
								/>}
								label={<T>Pick up in Montreal</T>}
								labelPlacement={"start"}
							/> */ }
					</span>
					{props.cart.get('pickup') &&
						<Grid item xs={12} className="item-selector">
							<Button variant="outlined" color="primary" disabled>
								<span><T>If you place a pickup order, we will notify you when your order is ready. You can come pick it up at our office in Montreal.</T></span>
								<b><T>5534 Ferrier Street, Mont-Royal</T></b>
								<b><T>H4P 1M2, Quebec, Canada</T></b>
							</Button>
						</Grid>
					}
					<div style={props.cart.get('pickup') ? { display: 'none' } : {}}>
						<AddressSelectorContainer title="Click to select Shipping Address" id={props.cart ? props.cart.get('id_shipping_address') : null} callback={(id: string) => saveCartAddress(id, 'shipping')} />
					</div>
				</Grid>
				<Grid item xs={12} md={6}>
					<h3 className="cart-page--title"><T>Billing Address</T></h3>
					<AddressSelectorContainer title="Click to select Billing Address" id={props.cart ? props.cart.get('id_billing_address') : null} callback={(id: string) => saveCartAddress(id, 'billing')} />
				</Grid>
			</Grid>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<h3 className="cart-page--title" style={{ margin: '0.5rem 0 -1.25rem' }}><T>Cart Summary</T></h3>
				</Grid>
				<Grid item xs={12} md={8} className="cart-page--orders">
					<CartContentContainer cart={props.cart} />
				</Grid>
				<Grid item xs={12} md={4} className="cart-page--summary">
					<div className="cart-summary">
						<div className="cart-summary-box">
							<CartSummaryContainer cart={props.cart} />
						</div>
					</div>
					{!props.loggedIn ?
						props.userEmail === "" ? <>
							<div className="cart-page--login-buttons">
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Link to={tR('/auth/login')}><Button color="secondary">Login</Button></Link>
									</Grid>
									<Grid item xs={6}>
										<Link to={tR('/auth/signup')}><Button color="secondary">Sign up</Button></Link>
									</Grid>
									<Grid item xs={12}>
										<div className="or-separator">OR</div>
									</Grid>
									<Grid item xs={12}>
										<Button color="secondary" onClick={() => setCheckoutGuestOpened(true)}>Checkout as Guest</Button>
									</Grid>
								</Grid>
							</div>
						</> : <>
							<div className="cart-page--checkout-guest-email">
								<IconButton color="primary" aria-label="edit email" component="span" onClick={() => setCheckoutGuestOpened(true)}><i className="material-icons">edit</i></IconButton>
								<small className="label">Checkout as:</small><br />
								<div className="email">{props.userEmail}</div>
							</div>
							<div className="cart-page--buttons" style={{ marginLeft: '2rem', padding: '0 1rem' }}>
								<StripeContainer disabled={nopay} onPay={onPay} />
								<PayPalContainer disabled={nopay} onPay={onPay} />
							</div>
						</>
						:
						<div className="cart-page--buttons" style={{ marginLeft: '2rem', padding: '0 1rem' }}>
							<StripeContainer disabled={nopay} onPay={onPay} />
							<PayPalContainer disabled={nopay} onPay={onPay} />
						</div>
					}
				</Grid>
			</Grid>
			{props.cart_loading && <Loader noModal />}
		</Container>

		{/* Enter email for guest checkout */}
		<PromptContainer
			opened={checkoutGuestOpened}
			title={"Please enter your email"}
			message={"We will send you updates on your order and contact you if there are any issues. No spam from us!"}
			input={onCheckoutGuestSubmit}
			name={props.userEmail !== "" ? props.userEmail : "Email"}
			onClose={() => setCheckoutGuestOpened(false)}
			dismissible
		/>
	</>
}