import PatternDesignServiceReducer from '@af-common/PatternDesignService/_reducers/PatternDesignServiceReducer';
import UIDataReducer from '@af-common/UIData/_reducers/UIDataReducer';
import UserDataReducer from '@af-common/UserData/_reducers/UserDataReducer';
import { combineReducers } from 'redux-immutable';
import { DATA_VERSION } from './config';
import LabReducer from './Create/_reducers/LabReducer';

const defaultReducer = combineReducers({
	create: LabReducer,
	patternDesignService: PatternDesignServiceReducer,
	userData: UserDataReducer,
	UIData: UIDataReducer,
	version: () => DATA_VERSION
});

export default defaultReducer;