import { IUserCreditCardStore } from '@af-common/UserData/_stores/UserCreditCardsStore';
import { Button, Grid} from '@mui/material';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import * as Immutable from 'immutable';
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CreditCardSelectorContainer from '../../Account/CreditCards/_containers/CreditCardSelectorContainer';
import CreditCardFormContainer from '../../Account/CreditCards/_containers/CreditCardFormContainer'; 
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../../../config/config.json';
import { Trans } from 'react-i18next';

export type Props = {
	disabled?: boolean
	onToast?: Function
	id_card?: string
	creditCards?: Immutable.OrderedMap<string, Immutable.Record<IUserCreditCardStore>>
	getCreditCards?: Function
	loading?: boolean
	cart_loading?: boolean
	onPay: Function
	loggedIn?: boolean
}

const stripePromise = loadStripe(config.STRIPE_API_KEY);
const T = ({ children, ...otherProps }: any) => <Trans ns="cart" {...otherProps}>{children}</Trans>
export default function Stripe(props:Props) {
	const [name, setName] = useState('');
	const [nameChanged, setNameChanged] = useState(false);
	const [id_card, setId_card] = useState('');
	const [formOpened, setFormOpened] = useState(false);

	useEffect(() => {
		if(props.loggedIn) {
			props.getCreditCards();
		}
	}, []);
	
	const disabled = props.disabled || props.loading || props.cart_loading;
	let nameInvalid = !name && nameChanged;

	return <Elements stripe={stripePromise}>
		{ props.creditCards.size ? 
			<CreditCardSelectorContainer disabled={disabled} callback={props.onPay}/>
			: <>
				<Grid item xs={12} className="item-selector item-selector--button">
					<Button disabled={disabled} color="primary" className="dark" onClick={() => setFormOpened(true)}>
						<span><T>Pay by Credit Card</T></span>
					</Button>
				</Grid>
				<CreditCardFormContainer isPayment opened={formOpened} 
					onClose={() => setFormOpened(false)}
					callback={props.onPay}
				/>
			</> }
	</Elements>
}