import React, { useState } from 'react';
import { Trans as T, Trans, useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Grid, ListItemIcon, Container, Button } from '@mui/material';
import CurrencyChanger from './CurrencyChanger';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import tKey from 'Common/tKey';
import LangChanger from './LangChanger';

export default function Footer() {
	const [t] = useTranslation();
	const [tR] = useTranslation('routes');

	const currency = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('currency'))
	const lang = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('lang'))
	
	const [langChangerOpened, setLangChangerOpened] = useState(false);
	const [currencyChangerOpened, setCurrencyChangerOpened] = useState(false);

	let langName = "";
	switch (lang) {
		case "en_CA": langName = "English"; break;
		case "fr_CA": langName = "Français"; break;
	}
	let date = new Date().getFullYear();

	return <footer id="footer" className="fullfooter">
		<Container>
			<div className="footergrid3">
				<div className="footerleft top">
					<img src="/static/img/logo.svg" alt="Art Fabrics" width="220" height="40" />
					<p><Trans
						t={t}
						i18nKey={tKey('5534 Ferrier Street<br />Mont-Royal, Quebec, Canada<br />H4P 1M2')}
						components={{
							br: <br/>
						}}
					/></p>
					<a href="https://www.instagram.com/artfabrics" target="_blank">
						<img src="/static/img/footer/instagram-af.svg" alt="Instagram" />
					</a>
					<a href="https://www.facebook.com/artfabrics.studio/" target="_blank">
						<img src="/static/img/footer/facebook-af.svg" alt="Facebook" />
					</a>
				</div>
				<div className="footerright top">
					<p><Trans
						t={t}
						i18nKey={tKey('For any questions, comments,<br />feedback or custom inquiry.')}
						components={{
							br: <br/>
						}}
					/></p>
					<Link to={tR('/contact-us')}>{t('Contact us')}</Link>
				</div>
				<div className="footerfarright">
					<ul className="footer_links">
						<li><Link to={tR('/about-us')}>{t('About Us')}</Link></li>
						<li><Link to={tR('/fabrics')}>{t('Our Fabrics')}</Link></li>
						<li><a href="https://blog.artfabrics.com" target="_blank">{t('Blog')}</a></li>
						{ /* <li><Link to={tR('/help')}>FAQ</Link></li> */}
					</ul>
				</div>
			</div>
			<div className="footergrid">
				<div className="footerleft bottom">
					<small className="copyright">{t('© {{ date }} Art Fabrics. All rights reserved.', {date: date})}</small><br />
					<small className="copyright"><Link to={tR('/terms-of-service')}>{t('Terms of Service')}</Link> <Link to={tR('/privacy-policy')}>{t('Privacy Policy')}</Link></small>
				</div>

				<div className="footerright bottom">
					<span className="link currency" onClick={(e) => setLangChangerOpened(true)}>
						<img src="/static/img/footer/english.svg" alt={t('Language')} />
						{t('Language')} (<T>{langName}</T>)
					</span>
					<span className="link currency" onClick={(e) => setCurrencyChangerOpened(true)}>
						<img src="/static/img/footer/currency.svg" alt={t('Currency')} />
						{t('Currency')} ({currency})
					</span>
				</div>
			</div>
		</Container>

		<CurrencyChanger opened={currencyChangerOpened} onClose={() => setCurrencyChangerOpened(false)} />
		<LangChanger opened={langChangerOpened} onClose={() => setLangChangerOpened(false)} />
	</footer >
}