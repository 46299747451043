import { IUserCreditCardStore } from '@af-common/UserData/_stores/UserCreditCardsStore';
import { Grid, Button, Typography, Skeleton } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import CreditCardContainer from '../_containers/CreditCardContainer';
import CreditCardFormContainer from '../_containers/CreditCardFormContainer';
import { Helmet } from 'react-helmet';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import config from '../../../../../../config/config.json';
import AddCardIcon from '@mui/icons-material/AddCard';

export type Props = {
	creditCards: Immutable.OrderedMap<string, Immutable.Record<IUserCreditCardStore>>
	loading?: boolean
	working?: boolean
	getCreditCards?: Function
};

type State = {
	modalOpened: boolean
}

const stripePromise = loadStripe(config.STRIPE_API_KEY);

export default class CreditCards extends React.Component<Props, State> {
	state = { modalOpened: false }

	componentDidMount() {
		this.props.getCreditCards();
	}

	render = () => <Elements stripe={stripePromise}>
		<Helmet>
			<title>Credit Cards</title>
		</Helmet>

		<div className="user-cards">
			<h2 className="account-header">Credit Cards<Button disabled={this.props.loading || this.props.working} color="secondary" style={{float:"right"}} onClick={()=>{
				this.setState({
					modalOpened: true
				})
			}} startIcon={<AddCardIcon />}>Add Credit Card</Button></h2>
			
			<Grid container spacing={2}>
				{this.props.creditCards.size ? <>
					{this.props.creditCards.entrySeq().map(function (address: [string, Immutable.Record<IUserCreditCardStore>], id: number) {
						return <CreditCardContainer id={address[0]} key={address[0]}/>
					}.bind(this))}
					{this.props.working ? <Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={180}/></Grid> : <></>}
				</> : this.props.loading ? <>
					<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={180}/></Grid>
					<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={180}/></Grid>
					<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={180}/></Grid>
				</> : 
					<Typography className="noitems" variant="body1">No Results</Typography>
				}
			</Grid>
		</div>

		<CreditCardFormContainer opened={this.state.modalOpened} onClose={()=>{
			this.setState({
				modalOpened: false
			})
		}}/>
	</Elements>
}
