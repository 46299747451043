import * as React from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import tKey from 'Common/tKey';

export default function AboutUs() {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/about-us');

	return <>
		<Helmet>
			<title>{t('About us')}</title>
			<meta name="description" content={t('Art Fabrics is a factory at your fingertips! Combining traditional dye techniques with cutting-edge printing technology to offer you the best printed fabric.')} />
		</Helmet>

		<Header borderColor="color2" />

		<div className="about-banner">
			<Container>
				<Grid container spacing={3} alignItems="center">
					<Grid item sm={12} md={6}>
						<img src="/static/img/pages/about-us/about-banner.png" alt={t('Do you wanna know all about us?')} />
					</Grid>
					<Grid item sm={12} md={6}>
						<h1 className="center">{t('Get to know')}<span>{t('all about us!')}</span></h1>
						<p className="center">
							<Trans
								t={t}
								i18nKey={tKey('<span>Partner with us</span> to bring your textile dreams to life and benefit from our vast experience with printing on <span>fabrics of all types</span>.')}
								components={{
									span: <span/>
								}}
							/>
						</p>
					</Grid>
				</Grid>
			</Container>
		</div>

		<div className="about-printing-lines">
			<Container>
				<Grid container spacing={3} className="printing-types" alignItems="center">
					<Grid item sm={12} md={6}>
						<h2>
							<Trans
								t={t}
								i18nKey={tKey('We have <span>3 different types</span> of printing lines including:')}
								components={{
									span: <span/>
								}}
							/>
						</h2>
						<ul>
							<li>
								<img src="/static/img/pages/about-us/1.svg" alt="Icon 1" />
								<h3><span>{t('Reactive inks on natural fibers.')}</span> {t('Reactive printing is a multistep process for printing on natural fabrics where the ink penetrates into the fibers and is locked into the fiber cells. Reactive printed fabrics are permanent and remain vibrant even with high use.')}</h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/2.svg" alt="Icon 2" />
								<h3><span>{t('Sublimation inks on polyester inks.')}</span> {t('Sublimation printing is the industry standard for customizing polyester textiles. Sublimation prints are permanent, durable and extremely versatile.')}</h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/3.svg" alt="Icon 3" />
								<h3><span>{t('UV printing on vegan leather fabrics.')}</span> {t('Our vegan leathers are printed using UV print technology that permanently sets the inks on the surface of the fabric. Prints are flexible and suitable for projects that are both used indoor and outdoors.')}</h3>
							</li>
							 {/*<li>
								<img src="/static/img/pages/about-us/4.svg" alt="Icon 4" />
								<h3><T><span>Sublimation</span> printing on elastics <br /> – Coming Soon</T></h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/5.svg" alt="Icon 5" />
								<h3><T><span>Film transfer printing</span>for premade  garments – Coming Soon</T></h3>
							</li>*/}
						</ul>
					</Grid>
					<Grid item sm={12} md={6} className="bubblesimg">
						<img src="/static/img/pages/about-us/printing-types.png" alt={t('We have 5 different types of printing lines.')} />
						<img src="/static/img/pages/about-us/printing-type-mobile.png" className="mobile" alt={t('We have 5 different types of printing lines.')} />
					</Grid>
				</Grid>
			</Container>
		</div>

		<div className="want-to-print-for-you center">
			<div className="whattoprintwrapper">
				<h2>{t('We want to print for you!')}</h2>
				<p>
					<Trans
						t={t}
						i18nKey={tKey('We love bringing printed fabrics to life and we couldn’t do it without our <strong>dedicated team who work daily in our studios</strong>. As many of us are makers ourselves, we understand what you expect from your prints.')}
						components={{
							strong: <strong/>
						}}
					/>
				</p>
				<Link to={tR('/auth/signup')}><Button className="dark">{t('Create your account')}</Button></Link>
			</div>
		</div>

		<div className="values center">
			<div className="valueswrapper">
				<h2>
					<Trans
						t={t}
						i18nKey={t('People. <span>Creativity.</span> Dedication.')}
						components={{
							span: <span/>
						}}
					/>
				</h2>
				<p>
					<Trans
						t={t}
						i18nKey={t('Our main commitment is to be proud of each print we send out the door, as if we had ordered it ourselves. When you order with us, <strong>we become your production shop</strong>, working for you to make your fabrics come to life.')}
						components={{
							strong: <strong/>
						}}
					/>
				</p>
				<Link to={tR('/create')}><Button className="dark">{t('Start Creating')}</Button></Link>
			</div>
		</div>

		<div className="commitments">
			<Container>
				<Grid container spacing={3} alignItems="center">
					<Grid item sm={12} md={6}>
						<img src="/static/img/pages/about-us/commitments.png" className="commitment-img" alt={t('Our commitments to you')} />
					</Grid>
					<Grid item sm={12} md={6}>
						<h2>{t('Why we’re the right printing company for you:')}</h2>
						<ul>
							<li>
								<img src="/static/img/pages/about-us/checkmark.svg" alt="Checkmark" />
								<h3><strong>{t('We’ll help steer you in the right direction.')}</strong> {t('Contact us with your project details and we’ll advise you on the best way to proceed')}</h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/checkmark.svg" alt="Checkmark" />
								<h3><strong>{t('No project is too big or too small and every project is special.')}</strong> {t('Our minimums are low so you can start with only what you need.')}</h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/checkmark.svg" alt="Checkmark" />
								<h3><strong>{t('Our prints are the highest quality.')}</strong> {t('Our inks penetrate the fabrics resulting in soft, gorgeous, and highly touchable textiles')}</h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/checkmark.svg" alt="Checkmark" />
								<h3><strong>{t('We know what we’re doing.')}</strong> {t('We have 15+ years of digital printing experience and a constant thirst to seek out new and evolving printing systems.')}</h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/checkmark.svg" alt="Checkmark" />
								<h3><strong>{t('Product certifications.')}</strong> {t('We’re up-to-date on the OKEO-TEX certification for our imported fabrics and many of our stretch fabrics are made in Canada')}</h3>
							</li>
							<li>
								<img src="/static/img/pages/about-us/checkmark.svg" alt="Checkmark" />
								<h3><strong>{t('We’re always looking to the future.')}</strong> {t('We’re constantly testing new textiles and adding exciting fabrics to our line-up for you and  world to try.')}</h3>
							</li>
						</ul>
					</Grid>
				</Grid>
			</Container>
		</div>

		<div className="trusted-provider center">
			<h2>
				<Trans
					t={t}
					i18nKey={tKey('Trusted <span>print provider</span> for:')}
					components={{
						span: <span/>
					}}
				/>
			</h2>
			<div className="logos-wrapper">
				<img src="/static/img/pages/about-us/logo-fabricville.svg" />
				<img src="/static/img/pages/about-us/logo-yukon-university.svg" />
				<img src="/static/img/pages/about-us/logo-artofwhere.svg" />
				<img src="/static/img/pages/about-us/logo-sheridan.svg" />
				<img src="/static/img/pages/about-us/logo-lasalle-college.svg" />
			</div>
			<Link to={tR('/contact-us')}><Button className="dark">{t('Contact us')}</Button></Link>
		</div>

		<div className="environnement center">
			<Container>
				<div className="environnement-wrapper">
					<h4>{t('EASY TO CREATE WITH ART FABRICS')}</h4>
					<h2>{t('We’re committed to furthering safe and sustainable production.')}</h2>
					<img src="/static/img/pages/about-us/eco-friendly.svg" alt={t('Eco-Friendly')} />
					<p>{t('By owning our production equipment and printing in Canada, we’re able to control our production conditions. Our finishing machines are especially designed to use as little water and energy as possible. The base fabrics we import are OKEO-TEX certified and we work with a Montreal manufacturer for almost all of our stretch fabrics, some of which are made from a special ecopolyster fiber.')}</p>
					<p>{t('Since we print on demand, we only make what is ordered and don’t overproduce. By using cutting edge digital print technology, we ensure our processes are as efficient as possible. We’re extremely proud to own and operate our own production facility based in Montreal that produces high quality printed fabric while minimizing our impact on the environment.')}</p>
					<p>{t('Wanna know more about our fabrics?')}</p>
					<a href="https://blog.artfabrics.com/oeko-tex-and-ecopolyester-fabrics-find-out-more-about-what-these-mean/" target="_blank" className="btn btn-secondary">{t('Read more')}</a>
				</div>
			</Container>
		</div>

		<Footer />
	</>
}