import * as React from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Container, Grid, Button } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


export default function SamplePack() {
	const [tR] = useTranslation('routes')

	return <>
		<Helmet>
			<title>Thanks for trying us out!</title>
			<meta name="description" content="Got your sample pack? Time to start designing custom printed fabric"/>
		</Helmet>

		<Header/>
		<TopBanner gradient={"linear-gradient(-90deg, rgba(199, 204, 118, 0.7) 0%, rgba(58, 129, 183, 0.7) 100%)"}>
			<h1>Thanks for trying us&nbsp;out!</h1>
		</TopBanner>
		
		<div className="sample-pack-page">
			<Container maxWidth={"lg"}>
		    	<Grid container spacing={2}>
				    <Grid item xs={12}>
				    	<div className="video-container">
				    		<iframe style={{
				    			width: '100%',
				    			height: '100%',
				    			position: 'absolute',
				    			top: '0',
				    			left: '0',
				    		}} width="560" height="315" src="https://www.youtube.com/embed/mUfV-5xdvY4?rel=0&amp;modestbranding=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				    	</div>
				    	<div style={{textAlign: 'center', paddingBottom: '2rem'}}>
					    	<h3>Now that you've seen the quality and range of fabrics that we offer, it's time to start creating!</h3>
					    	<Link to={tR('/create')}><Button className="home--cta">Start Designing</Button></Link>
					    </div>
				    </Grid>
		    	</Grid>
			</Container>
		</div>
		<Footer/>
	</>
}