import * as React from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Container, Grid, Paper, Typography } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';

const T = ({children, ...otherProps}:any) => <Trans ns="pages/reactive-printing" {...otherProps}>{children}</Trans>

export default function ReactivePrinting() {
	const [t] = useTranslation('pages/reactive-printing');

	return <>
		<Helmet>
			<title>{t('Reactive printing')}</title>
			<meta name="description" content={t('Learn about our reactive printing process which is a permanent way of printing on natural fabrics to achieve the best colors.')}/>
		</Helmet>

		<div className="reactive-printing-page-container">
			<Header borderColor="color2" />

			<div className="printing-type-page-banner">
				<Container>
					<div className="content-wrapper">
						<h1><T>Reactive printing</T></h1>
						<p><T>An affordable way to print <strong>natural fabric with permanent</strong> never fade inks.</T></p>
					</div>
				</Container>
			</div>

			<div className="process-wrapper">
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/reactive/pretreat.svg"/>
					<div className="process-text">
						<h4><T>Pretreat</T></h4>
						<p><T>All of our fabrics receive a custom treatment before the printing process. Think of it as a fabric spa. This treatment involves each fabric being washed in a specially formulated liquid bath. This liquid is unique to each fabric type and is what eventually reacts with the dyes to achieve permanent printing results.</T></p>
					</div>
				</div>
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/reactive/reactive-print.svg"/>
					<div className="process-text">
						<h4><T>Print</T></h4>
						<p><T>Once the treated fabrics have dried they are able to begin their print journey. Our jumbo printer is specially designed to print with reactive dyes, and only reactive dyes. With its completely cutting-edge, space-age technology, Suzie - our printer’s official, unofficial name - can print millions colours, achieving a perfectly accurate print each and every time.</T></p>
					</div>
				</div>
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/reactive/steam.svg"/>
					<div className="process-text">
						<h4><T>Steam</T></h4>
						<p><T>Step 3 in the reactive process, the steaming step, is where all the magic happens! Our freshly printed fabric gets run through an elaborate steaming machine, coating it in 105 degree steam for about 10 minutes. The hot steam is what reacts with the dyes, permanently setting the colour into each textile fibre. It really is a day at the spa!</T></p>
					</div>
				</div>
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/reactive/wash-dry.svg"/>
					<div className="process-text">
						<h4><T>Wash &amp; Dry</T></h4>
						<p><T>Step 4 is the wash phase. This step is much like washing off a facial once it’s had time to sink into your pores. Once the fabric has been steamed and the setting reaction has taken place, we give the fabric a nice, cleansing wash and dry. At this point the colours are vibrant, intense and completely permanent.</T></p>
					</div>
				</div>
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/reactive/inspect.svg"/>
					<div className="process-text">
						<h4><T>Inspect</T></h4>
						<p><T>The inspection step is probably the most important step for us here at Art Fabrics, because it’s where we’re able to most tangibly fulfil our quality commitments to you. We thoroughly inspect each and every fabric that we print, ensuring it’s of a quality that we would expect from products that we ourselves order. Not until we’re completely satisfied do we let the textile leave the building.</T></p>
					</div>
				</div>
			</div>

			<div className="floating-boxes-wrapper" style={{
				paddingBottom: '85rem'
			}}>
				<img className="floating-picture floating-picture-left" src="/static/img/pages/printing-methods/reactive/reactive-printing-1.jpg"/>
				<div className="floating-text floating-text-right">
					<h3><T>What is Reactive printing?</T></h3>
					<p><T>Reactive printing is a permanent way of printing on natural fabrics by creating chemical reactions at a cellular level in fibers. It’s a multistep process that involves pretreating fabrics to prepare them for ink, printing digitally with special inks, steaming the fabric to initiate the reaction, and finally washing out excess ink and softening the fabric for use. While it’s not the easiest way to print fabric, reactive printing gives the best results every time in terms of color, softness, usability, and durability.</T></p>
				</div>

				<img className="floating-picture" src="/static/img/pages/printing-methods/reactive/reactive-vs-pigment.jpg" style={{
					top: '35rem',
				    maxWidth: '50rem',
				    marginLeft: '-55rem'
				}}/>
				<div className="floating-text" style={{
					left: '50%',
				    marginLeft: '-10rem',
				    top: '14rem'
				}}>
					<h3><T>Reactive vs. pigment printing</T></h3>
					<p><T>You may have heard of another form of printing on natural fabrics called pigment printing. Unlike reactive ink that penetrates into the fiber cell, pigment printing is a surface print. Pigment inks are a combination of color and glue. After the fabric is printed, the glue is heated and adheres the ink to the surface of the fabric. Since the inks do not transfer into the fabric fibers, the prints are susceptible to cracking, color loss during use and wash, and generally faded colors. The glue used in the inks tends to stiffen your fabric. While pigment printing is an easier process and suitable for low-use projects, the results are uncomparable to reactive prints in terms of vibrancy, softness, and print durability.</T></p>
				</div>


				<img className="floating-picture" src="/static/img/pages/printing-methods/reactive/environment.jpg" style={{
					top: '90rem',
				    maxWidth: '80rem',
				    marginLeft: '-35rem'
				}}/>
				<div className="floating-text" style={{
					left: '50%',
				    marginLeft: '-40rem',
				    top: '35rem',
				    maxWidth: '80rem'
				}}>
					<h3><T>Our environmental impact through reactive printing</T></h3>
					<p><T>It’s no secret that the fashion industry, very closely linked to the textile industry, is considered one of the dirtiest industries currently in the game. Here at Art Fabrics we don’t want to play that game, so we’ve implemented a few systems in order to reduce our environmental impact. These systems include the obvious but incredibly impactful practice of printing on demand, which means we only print exactly what’s ordered, when it’s ordered, reducing overproduction and therefore potential for waste. Any textile waste that is produced is repurposed as much as possible - into rags and other fabric goods for around the factory. We were also very intentional about purchasing machines that have integrated sustainable systems, such as reduced water and energy use. All of our fabrics are OKEO-TEX certified - you can find the certifications on our fabric landing pages - and our dyes are all BLUESIGN and GOTS approved. Our sustainability goal was to develop the habit of integrating environmentally friendly practices, both simple and complex, right from the beginning.</T></p>
				</div>
			</div>

			<Footer/>
		</div>
	</>
}