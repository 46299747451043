import TranslationProvider from '@af-common/Common/_components/TranslationProvider';
import { Button, Container, Grid } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FabricCategoryContentProps } from './FabricCategory';
import tKey from 'Common/tKey';
import FabricInfo from './FabricInfo';
import FabricThumbnailList from './FabricThumbnailList';
import FabricThumbnail from './FabricThumbnail';

export default function VeganLeather(props: FabricCategoryContentProps) {
	const [tR] = useTranslation('routes')
	const [t] = useTranslation('pages/fabrics/vegan-leather');

	return <>
		<Helmet>
			<title>{t('Custom printed Vegan Leather')}</title>
			<meta name="description" content={t('Design and print patterns, artwork and photos permanently on a variety of natural vegan leathers.')} />
		</Helmet>

		<div className="top-banner-fabrics fabric-vegan-leather">
			<Container>
				<Grid container>
					<Grid item md={6} sm={12}>
						<div className="banner-content-wrapper">
							<h1>
								<Trans
									t={t}
									i18nKey={tKey('Custom printed<span>Vegan Leathers</span> on demand, <br />no minimums!')}
									components={{
										span: <span />,
										br: <br />,
									}}
								/>
							</h1>
							<Link to={tR('/create')}><Button className="home--cta">{t('Start designing')}</Button></Link>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>

		<section className="fabric-intro">
			<Container maxWidth="xl">
				<div className="intro-text">
					<h2>
						<Trans
							t={t}
							i18nKey={tKey('Design and print patterns, artwork and photos <br/>permanently on fabrics.')}
							components={{
								br: <br />
							}}
						/>
					</h2>
					<p>{t('We combine traditional decoration methods with the latest in digital print technology to create quality printed fabrics for any project. On demand digital fabric printing for businesses, designers, and makers. Reach new markets with zero investment.')}</p>
				</div>

				<FabricThumbnailList
					onSelect={props.onSelect}
					selectedFabric={props.currentFabric}
				>
					<FabricThumbnail
						slug="crinkle_vegan_leather"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Vegan Leather</family> Crinkle')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/vegan-leather/crinkle-vegan-leather/fabric-front.jpg" />
					<FabricThumbnail
						slug="fine_grain_vegan_leather"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Vegan Leather</family> Fine Grain')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/vegan-leather/fine-grain-vegan-leather/fabric-front.jpg" />
					<FabricThumbnail
						slug="pebble_vegan_leather"
						title={<Trans
							t={t}
							i18nKey={tKey('<family>Vegan Leather</family> Pebble')}
							components={{
								family: <div className="fabric-family" />
							}}
						/>}
						img="/static/img/pages/fabric/vegan-leather/pebble-vegan-leather/fabric-front.jpg" />
				</FabricThumbnailList>

			</Container>
		</section>

		<FabricInfo
			active={props.currentFabric === "crinkle_vegan_leather"}
			slug="crinkle_vegan_leather"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Vegan Leather</family> Crinkle')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Crinkle-textured vegan leather brings a unique, tactile element to your custom prints. The distinctive surface adds depth and character, making your design details stand out. Prints on this material are permanent and incredibly durable. As one of our lightest vegan leathers, crinkle vegan leather is perfect for creating eye-catching clothing, outerwear, accessories, and décor pieces. ')}
			printDescription={t('Crinkle vegan leather is printed using vibrant and permanent UV inks.')}
			specs={{
				content: t('5% 45% Polyurethane, 30% PVC, 15% Polyester, 25% Plasticizers, 25% Calcium Powder'),
				width: t('52”/ 132cm'),
				color: t('Soft white (matte)'),
				weight: t('105 g/m2'),
				shrinkage: t('1.0mm'),
				printThrough: t('none'),
			}}
			images={{
				front: "/static/img/pages/fabric/vegan-leather/crinkle-vegan-leather/fabric-front.jpg",
				back: "/static/img/pages/fabric/vegan-leather/crinkle-vegan-leather/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/vegan-leather/crinkle-vegan-leather/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/vegan-leather/crinkle-vegan-leather/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Bags, home decor, outerwear, clothing'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "fine_grain_vegan_leather"}
			slug="fine_grain_vegan_leather"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Vegan Leather</family> Fine Grain')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Fine grain vegan leather is a luxurious, cruelty-free material ideal for custom printing. The smooth surface of this remarkable vegan leather allows you to print vibrant, detailed designs that are permanent and highly durable. Featuring a matte finish, this high-quality material will be the star of any design!')}
			printDescription={t('Smooth vegan leather is printed using vibrant and permanent UV inks.')}
			specs={{
				content: t('55% Polyester, 45% Polyurethane	'),
				width: t('52”/ 132cm'),
				color: t('Optic white (matte)'),
				weight: t('145 g/m2'),
				shrinkage: t('1.9mm'),
				printThrough: t('none'),
			}}
			images={{
				front: "/static/img/pages/fabric/vegan-leather/fine-grain-vegan-leather/fabric-front.jpg",
				back: "/static/img/pages/fabric/vegan-leather/fine-grain-vegan-leather/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/vegan-leather/fine-grain-vegan-leather/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/vegan-leather/fine-grain-vegan-leather/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Bags, home decor, outerwear'),
				},
			]}
		/>
		<FabricInfo
			active={props.currentFabric === "pebble_vegan_leather"}
			slug="pebble_vegan_leather"
			title={<Trans
				t={t}
				i18nKey={tKey('<family>Vegan Leather</family> Pebble')}
				components={{
					family: <div className="fabric-family" />
				}}
			/>}
			description={t('Pebble vegan leather has a refined, textured surface to bring your custom prints to life! The raised pattern adds dimension and sophistication while looking incredibly realistic .Prints on pebble vegan leather are durable, vibrant and permanent so your projects withstand the test of time. Add depth and intensity to your designs with pebble vegan leather. ')}
			printDescription={t('Pebble vegan leather is printed using vibrant and permanent UV inks.')}
			specs={{
				content: t('10% 45% Polyurethane, 50% PVC, 40% polyester'),
				width: t('52”/ 132cm'),
				color: t('Bright white (semi-gloss)'),
				weight: t('250 g/m2'),
				shrinkage: t('1.7mm'),
				printThrough: t('none'),
			}}
			images={{
				front: "/static/img/pages/fabric/vegan-leather/pebble-vegan-leather/fabric-front.jpg",
				back: "/static/img/pages/fabric/vegan-leather/pebble-vegan-leather/fabric-back.jpg",
				closeup: "/static/img/pages/fabric/vegan-leather/pebble-vegan-leather/fabric-white-closeup.jpg",
				paper: "/static/img/pages/fabric/vegan-leather/pebble-vegan-leather/fabric-white-paper.jpg",
			}}
			uses={[
				{
					title: t(''),
					description: t('Bags, home decor'),
				},
			]}
		/>

		<section className="floating-boxes-wrapper fabric-info-and-facts">
			<div className="floating-text floating-text-right">
				<h3>{t('What is Vegan Leather?')}</h3>
				<p>{t('Vegan leather is a cruelty-free alternative to traditional leather. Unlike regular leather, which is made from animal hides, vegan leather is made from synthetic or plant-based materials. This fabric looks and feels like genuine leather but without using any animal products. It\'s a great choice for those who want to avoid contributing to animal exploitation and are looking for a more sustainable, eco-friendly option.')}</p>
			</div>

			<div className="floating-picture floating-picture-left" style={{ top: '15rem' }}>
				<img src="/static/img/pages/fabric/vegan-leather/bg-image-1.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right">
				<p>{t('Did you know? Vegan leather options can be as durable as real leather, making them great for long-lasting projects.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('How is Vegan Leather Made?')}</h3>
				<p>{t('Vegan leather is crafted from synthetic or plant-based materials instead of animal hides. Synthetic vegan leather often uses polyurethane (PU) or polyvinyl chloride (PVC) layered onto a fabric backing to create a leather-like texture and durability. Plant-based vegan leather comes from renewable sources, like pineapple leaves, apple peels, or mushrooms, where fibers are processed, dried, and combined with natural or synthetic binders to mimic the appearance and feel of leather while remaining eco-friendly and cruelty-free.')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '58rem' }}>
				<img src="/static/img/pages/fabric/vegan-leather/bg-image-2.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('Did you know? Vegan leather can be made from innovative sources like mushrooms, cactus, and pineapple leaves!')}</p>
			</div>

			<div className="floating-text floating-text-right" style={{ top: '18rem' }}>
				<h3>{t('What Are the Benefits of Vegan Leather?')}</h3>
				<p>{<Trans
					t={t}
					i18nKey={tKey('<b>Animal-Friendly: </b>Made without animal products, appealing to those prioritizing animal welfare.')}
					components={{
						b: <strong />
					}}
				/>}</p>
				<p>{<Trans
					t={t}
					i18nKey={tKey('<b>Sustainable: </b>Often produced with less water and energy, sometimes using agricultural waste to reduce environmental impact.')}
					components={{
						b: <strong />
					}}
				/>}</p>
				<p>{<Trans
					t={t}
					i18nKey={tKey('<b>Stylish: </b>Available in various colors, textures, and finishes, allowing creative flexibility.')}
					components={{
						b: <strong />
					}}
				/>}</p>
				<p>{<Trans
					t={t}
					i18nKey={tKey('<b>Affordable: </b>Generally more budget-friendly than traditional leather.')}
					components={{
						b: <strong />
					}}
				/>}</p>
				<p>{<Trans
					t={t}
					i18nKey={tKey('<b>Water-Resistant & Durable: </b>Synthetic vegan leather is typically more water-resistant, requiring less maintenance.')}
					components={{
						b: <strong />
					}}
				/>}</p>
			</div>

			<div className="floating-picture floating-picture-left2">
				<img src="/static/img/pages/fabric/vegan-leather/bg-image-3.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-right" style={{ top: '20rem', borderRadius: '0 50% 50% 50%', marginLeft: '12rem' }}>
				<p>{t('Did you know? Unlike animal leather, vegan leather production often uses less water and has a smaller carbon footprint.')}</p>
			</div>

			<div className="floating-text floating-text-left">
				<h3>{t('Caring for your prints on vegan leather')}</h3>
				<p>{t('Caring for vegan leather, especially when printed with UV inks, ensures its longevity and vibrant appearance. Clean it regularly with a damp cloth to remove dirt and dust, avoiding harsh chemicals that can damage the print. For deeper cleaning, use a mild soap solution and a soft cloth, then wipe off excess moisture. Avoid repeated,  direct sunlight, which can fade both the print. If the vegan leather gets wet, dry it gently with a towel and air dry away from heat sources. Projects made from vegan leather like bags and jackets can last for years when cared for properly. ')}</p>
			</div>

			<div className="floating-picture floating-picture-right" style={{ top: '185rem' }}>
				<img src="/static/img/pages/fabric/vegan-leather/bg-image-4.jpg" />
			</div>

			<div className="floating-bubble floating-bubble-left">
				<p>{t('Did you know? Vegan leather is often a suitable option for individuals with allergies or sensitivities to animal products')}</p>
			</div>
		</section>
	</>
}

