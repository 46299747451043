import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CreditCards, { Props } from '../_components/CreditCards';
import { GetUserCreditCards } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	creditCards: store.getIn(['userData', 'userCreditCards']),
	loading: store.getIn(['UIData', 'loaders', 'creditCards_all']),
	working: store.getIn(['UIData', 'loaders', 'creditCards_single']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getCreditCards: () => {dispatch(GetUserCreditCards())}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CreditCards);