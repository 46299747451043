import React, { useEffect, useState } from 'react'
import * as Immutable from 'immutable';
import { Hidden, Button, Fade } from "@mui/material";
import { Link } from 'react-router-dom';
import { Trans as T, useTranslation, withTranslation, WithTranslation } from "react-i18next";
import { Desktop } from '@af-common/Common/_components/Devices';
import { BaseItem } from '@af-common/UserData/_stores/UserCartStore';
import { Logout } from 'Auth/_actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import CurrencyChanger from './CurrencyChanger';
import LangChanger from './LangChanger';

export type Props = {
	showCurrency?: boolean
}

export default function UserNav(props: Props) {
	const dispatch = useDispatch()
	const [t] = useTranslation();
	const [tR] = useTranslation('routes');

	const currency = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('currency'))
	const lang = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('lang'))
	const loggedIn = useSelector((state:WebsiteStore) => state.get('userData').get('loggedIn'))
	const cartItems = useSelector((state:WebsiteStore) => state.get('userData').get('userCart').get('items'))

	const [accountMenuOpened, setAccountMenuOpened] = useState(false)
	const [currencyChangerOpened, setCurrencyChangerOpened] = useState(false)
	const [langChangerOpened, setLangChangerOpened] = useState(false)

	const toggleAccountMenu = () => setAccountMenuOpened(value => !value);

	function countItems(): number {
		let count = 0;

		if (!cartItems) return count;

		cartItems.entrySeq().forEach((value) => {
			let [key, item] = value;
			count += item.quantity;
		});

		return count;
	}

	let langName = "";
	switch (lang) {
		case "en_CA": langName = "English"; break;
		case "fr_CA": langName = "Francais"; break;
	}

	let cartCount = countItems();

	return <ul className="right-nav">
		{props.showCurrency && <Hidden smDown>
			<li className="currency-btn" onClick={() => setCurrencyChangerOpened(true)}>
				$ {currency}
				<span className="arrow-down"></span>
			</li>
		</Hidden>}
		<li>
			<Link className="link" to={tR('/cart')}>
				{t('Cart')}{cartCount ? <span>({cartCount})</span> : null}
			</Link>
		</li>
		{loggedIn ? <>
			<li onClick={toggleAccountMenu} className="user-nav-menu">
				<i className="material-icons account-icon">person_outline</i>
				{accountMenuOpened ? <i className="material-icons">arrow_drop_up</i> : <i className="material-icons">arrow_drop_down</i>}
				<Fade in={accountMenuOpened}>
					<ul onMouseLeave={() => setAccountMenuOpened(false)}>
						<li>
							<Link className="link" to={tR('/account')}>
								<i className="material-icons">face</i>
								{t('My account')}
							</Link>
						</li>
						<li onClick={() => setLangChangerOpened(true)}>
							<span className="link">
								<i className="material-icons">language</i>
								{langName}
							</span>
						</li>
						{props.showCurrency && <Hidden smUp>
							<li onClick={() => setCurrencyChangerOpened(true)}>
								<span className="link">
									<i className="material-icons">public</i>
									$ {currency}
								</span>
							</li>
						</Hidden>}
						<li onClick={() => dispatch(Logout())}>
							<span className="link">
								<i className="material-icons">input</i>
								{t('Logout')}
							</span>
						</li>
					</ul>
				</Fade>
			</li>
			<LangChanger opened={langChangerOpened} onClose={() => setLangChangerOpened(false)} />
		</> :
			<Desktop>
				{ /*<li>
						<Link className="link" to={tR('/auth/login', { ns: 'routes'})}>
							<div className="account-icon"></div>
						</Link>
					</li> */ }
			</Desktop>
		}
		{!loggedIn && <>
			<Hidden smDown>
				<li>
					<Link to={tR('/auth/login')} className="link header-signup">{t('Login')}</Link>
				</li>
			</Hidden>
			<li>
				<Link to={tR('/auth/signup')} className="link header-signup">{t('Sign up')}</Link>
			</li>
		</>}
		{props.showCurrency && <CurrencyChanger opened={currencyChangerOpened} onClose={() => setCurrencyChangerOpened(false)} />}
	</ul>
}