import * as Immutable from 'immutable'
import UserDataStore from '@af-common/UserData/_stores/UserDataStore';
import CreateStore from '@af-create/_stores/CreateStore';
import PatternDesignServiceStore from '@af-common/PatternDesignService/_stores/PatternDesignServiceStore';
import UIDataStore, { Price } from '@af-common/UIData/_stores/UIDataStore';
import { DATA_VERSION } from './config';
import Fabrics, { Fabric, FabricInfo } from '@af-common/UIData/_data/Fabrics';
import { CartItem, BaseItem } from '@af-common/UserData/_stores/UserCartStore';

export interface IWebsiteStore {
	create: CreateStore,
	patternDesignService: PatternDesignServiceStore,
	userData: UserDataStore,
	UIData: UIDataStore,
	version: string
}

const defaultStore = Immutable.Record<IWebsiteStore>({
	create: new CreateStore,
	patternDesignService: new PatternDesignServiceStore,
	userData: new UserDataStore,
	UIData: new UIDataStore,
	version: DATA_VERSION
});

const stringToIntKeys = (sequence: Immutable.Collection.Keyed<string, any> | Immutable.Collection.Indexed<any>) => {
	let newSequence = Immutable.OrderedMap<number, any>();
	return newSequence.withMutations(newSequence => {
		sequence.entrySeq().forEach((value: [string, any] | [number, any]) => {
			let [id, entry] = value;
			newSequence.set(Number(id), entry);
		});
	});
}

export default class WebsiteStore extends defaultStore implements IWebsiteStore {
	constructor(storeObject:object) {
		let store = Immutable.Map({
			create: new CreateStore,
			userData: new UserDataStore,
			UIData: new UIDataStore,
			version: DATA_VERSION
		});

		store = Immutable.mergeDeep(store, Immutable.fromJS(storeObject, (key, sequence, path) => {
			if(path[0] === "UIData" && path[1] === "fabrics" && typeof path[2] !== 'undefined' && typeof path[3] === 'undefined') {
				let fabric = sequence.toOrderedMap() as any as Fabric;
				return fabric.set('info', new FabricInfo(Fabrics[fabric.get('slug')]));
			}

			if(path[0] === "userData" && path[1] === "userCart" && path[2] === "items" && typeof path[3] !== 'undefined' && typeof path[4] === 'undefined') {
				//let item = sequence.toOrderedMap() as any as Fabric;
				return CartItem(sequence.toJS());
			}

			if(key === 'price') {
				return new Price(sequence.toJS() as any);
			}

			if(path[0] === "UIData" && path[1] === "fabrics" && typeof path[2] === 'undefined' ||
				path[0] === "UIData" && path[1] === "fabrics" && path[3] === "sizes" && typeof path[4] === 'undefined' ||
				path[0] === "userData" && path[1] === "userAddresses" && typeof path[2] === 'undefined' ||
				path[0] === "userData" && path[1] === "userCart" && path[2] === "items" && typeof path[3] === 'undefined' ||
				path[0] === "userData" && path[1] === "userCart" && path[2] === "availableShippingTypes" && typeof path[3] === 'undefined') {
				return stringToIntKeys(sequence);
			} else {
				return Immutable.isKeyed(sequence) ? sequence.toOrderedMap() : sequence.toList();
			}
		}));

		super(store);
	}
}