import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { SaveUserLang } from '@af-common/UserData/_actions/UserAccountActions';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import { useTranslation } from 'react-i18next';

export type Props = {
	opened: boolean
	onClose: () => void
}

export default function LangChanger(props:Props) {
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const userLang = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('lang'))

	const [lang, setLang] = useState(userLang)

	useEffect(() => {
		setLang(userLang)
	}, [userLang])

	const onConfirm = (e:React.MouseEvent) => {
		e.preventDefault();
		let fd = new FormData();
		fd.append("lang", lang);
		dispatch(SaveUserLang(fd, () => {
			window.location.reload();
		}))
		props.onClose();
	}

	return <Dialog open={props.opened} onClose={props.onClose}>
		<form>
			<DialogTitle>{t('Select Language')}</DialogTitle>
			<DialogContent>
				<RadioGroup name="lang" value={lang} onChange={(e) => setLang(e.currentTarget.value)}>
					<FormControlLabel value="en_CA" control={<Radio color="primary"/>} label="English" />
					<FormControlLabel value="fr_CA" control={<Radio color="primary"/>} label="Français" />
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={props.onClose} color="secondary">{t('Cancel')}</Button>
				<Button type="submit" onClick={onConfirm} color="secondary" className="secondary-primary">{t('Confirm')}</Button>
			</DialogActions>
		</form>
	</Dialog>
}