import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AddressForm, { Props } from '../_components/AddressForm';
import { CreateUserAddress, UpdateUserAddress } from '@af-common/UserData/_actions/UserAccountActions';
import { AjaxCallback } from '@af-common/Common/_actions/AjaxAction';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	address: store.getIn(['userData', 'userAddresses', props.id]),
	countries: store.getIn(['UIData', 'countries']),
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	onSubmit: (data:FormData, callback:AjaxCallback) => {
		if(data.get('id')) dispatch(UpdateUserAddress(data, callback))
		else dispatch(CreateUserAddress(data, callback))
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddressForm);