import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Order, { Props } from '../_components/Order';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	address: store.getIn(['userData', 'userAddresses', props.item.get('id_billing_address')])
});

export default connect
	(mapStateToProps, null)
(Order);