import { connect } from 'react-redux';
import * as Immutable from 'immutable';
import Auth, { Props } from '../_components/Auth';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	loggedIn: store.getIn(['userData', 'loggedIn']),
	validated: store.getIn(['userData', 'validated']),
	status: store.getIn(['UIData', 'signupStatus'])
});

export default connect(
	mapStateToProps, null
)(Auth);