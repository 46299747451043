import React from 'react';
import WhatsNewContainer from '../WhatsNew/_containers/WhatsNewContainer';
import PatternsContainer from '../Patterns/_containers/PatternsContainer';
import OrdersContainer from '../Orders/_containers/OrdersContainer';
import ImagesContainer from '../Images/_containers/ImagesContainer';
import Breadcrumbs from '../Breadcrumbs/_components/Breadcrumbs';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import { useTranslation } from 'react-i18next';

export type Props = {
	name?: string
}

export default function AccountHome() {
	const [t] = useTranslation()
	const name = useSelector((state:WebsiteStore) => state.get('userData').get('userPrefs').get('name'))

	return <div className="home_container">
		{/*
			<Breadcrumbs />
		*/}
		{/*
			<div className="section">
				<WhatsNewContainer />
			</div>
		*/}
		<h1>{t('Welcome {{name}}!', { name: name })}</h1>
		<div className="section">
			<OrdersContainer maxItemsCount={4} compactVersion={true} />
		</div>
		<div className="section">
			<PatternsContainer maxItemsCount={4} compactVersion={true} />
		</div>
	</div>
}
