import PromptContainer from '@af-common/Common/_containers/PromptContainer';
import { DeletePaginationItem, RenamePaginationItem } from '@af-common/UserData/_actions/UserDataActions';
import { UserImageStore } from '@af-common/UserData/_stores/UserImagesStore';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Card, CardActions, CardContent, CardMedia, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export type Props = {
	item?: UserImageStore
}

export default function Image(props:Props) {
	const dispatch = useDispatch()
	const [t] = useTranslation()

	const [menuOpen,setMenuOpen] = useState(false)
	const [menuRenameOpen,setMenuRenameOpen] = useState(false)
	const [menuDeleteOpen,setMenuDeleteOpen] = useState(false)
	const [elem,setElem] = useState<HTMLElement|null>(null)
	
	const onMenuRename = (name:string) => {
		let fd = new FormData();
		fd.append('id', props.item.get('id'));
		fd.append('name', name)
		dispatch(RenamePaginationItem(fd, 'images'))
	}

	const onMenuDelete = () => {
		let fd = new FormData();
		fd.append('id', props.item.get('id'));
		dispatch(DeletePaginationItem(fd, 'images'))
		onMenuDeleteClose();
	}

	const onMenuRenameOpen = () => {
		setMenuOpen(false)
		setMenuRenameOpen(true)
	}
		
	const onMenuRenameClose = () => setMenuRenameOpen(false);

	const onMenuDeleteOpen = () => {
		setMenuOpen(false)
		setMenuDeleteOpen(true)
	}
	const onMenuDeleteClose = () => setMenuDeleteOpen(false);

	return <Grid item key={props.item.get('id')} xs={12}>
		<Card className="card-account-image">
			<CardContent>
				<CardMedia image={props.item.get('filename')} />
			</CardContent>
			<CardActions>
				<h4>{props.item.get('name')}</h4>
				<IconButton onClick={() => setMenuOpen(true)}>
					<span className='mdc-menu-surface--anchor' ref={setElem}>
						<MoreVert />
					</span>
				</IconButton>
			</CardActions>
			<Menu onClose={() => setMenuOpen(false)} open={menuOpen} anchorEl={elem} >
				<MenuItem onClick={onMenuRenameOpen} ><Edit /> {t('Rename')}</MenuItem>
				<MenuItem onClick={onMenuDeleteOpen} ><Delete /> {t('Delete')}</MenuItem>
			</Menu>
		</Card>

		{/* Rename Dialog */}
		<PromptContainer
			opened={menuRenameOpen}
			title={t('Rename Image')}
			input={onMenuRename}
			name={props.item.get('name')}
			onClose={onMenuRenameClose}
			dismissible
		/>

		{/* Delete Confirmation */}
		<PromptContainer
			opened={menuDeleteOpen}
			title={t('Delete {{name}}', props.item.get('name'))}
			message={t('Are you sure that you want to delete this image?')}
			onClose={onMenuDeleteClose}
			actions={{
				[t('Delete')]: onMenuDelete
			}}
			dismissible
		/>
	</Grid>
}
