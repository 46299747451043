import * as React from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Container, Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Tabs, Tab, } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export type Props = {}

type State = {
	activeAccordion: number,
	activeTab: number
}

export default class FAQ extends React.Component<Props> {
	state = {
		activeAccordion: -1,
		activeTab: 0
	}

	render = () => <>
		<Helmet>
			<title>About us</title>
			<meta name="description" content="Art Fabrics is a factory at your fingertips! Combining traditional dye techniques with cutting-edge printing technology to offer you the best printed fabric." />
		</Helmet>

		<Header />

		<div className="pageheader">
			<div className="pageheader_content" style={{ backgroundImage: "url('/static/img/pages/help/helpbannerimage.svg')" }}>
				<Container>
					<Grid container>
						<Grid item xs={12} md={12}>
							<h1>Frequently Asked Questions</h1>
							<h2>Fabrics. Printing. Designing. Payment & Shipping. Have questions? We’re here <span>to help</span>.</h2>
						</Grid>
					</Grid>
				</Container>
			</div>
		</div>
		<Container>
			<div className="helpmenu">
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={this.state.activeTab} onChange={(e, newValue) => this.setState({ activeTab: newValue })} aria-label="basic tabs example">
						<Tab className="helpmenu_item" label="Fabrics" />
						<Tab className="helpmenu_item" label="Printing" />
						<Tab className="helpmenu_item" label="Designing" />
						<Tab className="helpmenu_item" label="Payment & shipping" />
						<Tab className="helpmenu_item" label="Policies & Returns" />
					</Tabs>
				</Box>
			</div>
			<div className="helpcontent">
				<div className={"tabcontent"+(this.state.activeTab === 0 ? " active":"")} id="fabrics">
				<span className="mobileTitle">
					<h2>Fabrics</h2>
				</span>
					<Accordion elevation={0} className="helpaccordion"
						expanded={this.state.activeAccordion === 1}
						onChange={(e, expanded) => this.setState({ activeAccordion: expanded ? 1 : -1 })}
					>
						<AccordionSummary className="accordiontitle">
							<Typography>
								<h2>How do I care for my printed fabrics?</h2>
							</Typography>
						</AccordionSummary>
						<AccordionDetails className="accordiontext">
							<Typography>
								Specific care instructions will vary depending on the fabric. Each order comes with a care sheet so you know exactly how to wash and care for your fabrics
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion elevation={0} className="helpaccordion"
						expanded={this.state.activeAccordion === 2}
						onChange={(e, expanded) => this.setState({ activeAccordion: expanded ? 2 : -1 })}
					>
						<AccordionSummary className="accordiontitle">
							<Typography>
								<h2>Can you print on my own fabric?</h2>
							</Typography>
						</AccordionSummary>
						<AccordionDetails className="accordiontext">
							<Typography>
								We are only able to print on the fabrics we offer on our website. This is because the printing process relies on a unique pre-treatment that is formulated for each individual fabric type.All of our fabrics have been formulated to work on our equipment.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion elevation={0} className="helpaccordion"
						expanded={this.state.activeAccordion === 3}
						onChange={(e, expanded) => this.setState({ activeAccordion: expanded ? 3 : -1 })}
					>
						<AccordionSummary className="accordiontitle">
							<Typography>
								<h2>How do I decide on the right fabric for my project?</h2>
							</Typography>
						</AccordionSummary>
						<AccordionDetails className="accordiontext">
							<Typography>
								Specific care instructions will vary depending on the fabric. Each order comes with a care sheet so you know exactly how to wash and care for your fabrics
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion elevation={0} className="helpaccordion"
						expanded={this.state.activeAccordion === 4}
						onChange={(e, expanded) => this.setState({ activeAccordion: expanded ? 4 : -1 })}
					>
						<AccordionSummary className="accordiontitle">
							<Typography>
								<h2>Will printed fabrics shrink?</h2>
							</Typography>
						</AccordionSummary>
						<AccordionDetails className="accordiontext">
							<Typography>
								Specific care instructions will vary depending on the fabric. Each order comes with a care sheet so you know exactly how to wash and care for your fabrics
							</Typography>
						</AccordionDetails>
					</Accordion>
				</div>

				<div className={"tabcontent"+(this.state.activeTab === 1 ? " active":"")} id="printing">
				<span className="mobileTitle">
					<h2>Printing</h2>
				</span>

				</div>
				<div className={"tabcontent"+(this.state.activeTab === 2 ? " active":"")} id="designing">
				<span className="mobileTitle">
					<h2>Designing</h2>
				</span>

				</div>
				<div className={"tabcontent"+(this.state.activeTab === 3 ? " active":"")} id="payment-shipping">
				<span className="mobileTitle">
					<h2>Payment & shipping</h2>
				</span>

				</div>
				<div className={"tabcontent"+(this.state.activeTab === 4 ? " active":"")} id="policies-returns">
				<span className="mobileTitle">
					<h2>Policies & Returns</h2>
				</span>

				</div>
			</div>
		</Container>

		<Footer />
	</>
}