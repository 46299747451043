import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { Checkbox, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField, Button } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import { Helmet } from 'react-helmet';

export type Props = {
	onSave?: Function
	getPrefs?: Function
	userPrefs: Immutable.OrderedMap<string, any>
};

type State = {
	name:string
	lang:string
	currency:string
	newsletter:string
}

export default class Preferences extends AwareComponent<Props, State> {
	state = {name:"", lang:"", currency:"", newsletter:""}

	componentDidMount = () => {
		this.propsToState();
		this.props.getPrefs();
	}

	componentDidUpdate = (_prevProps:Props) => {
		if(this.propChanged(_prevProps, "userPrefs")){
			this.propsToState();
		}
	}

	private propsToState = () => {
		this.setState({
			name: this.props.userPrefs.get("name"),
			lang: this.props.userPrefs.get("lang"),
			currency: this.props.userPrefs.get("currency"),
			newsletter: this.props.userPrefs.get("newsletter")
		});
	}

	private onSave = (e:React.FormEvent) => {
		e.preventDefault();
		let fd = new FormData();
		fd.append("name", this.state.name);
		fd.append("lang", this.state.lang);
		fd.append("currency", this.state.currency);
		fd.append("newsletter", this.state.newsletter);
		this.props.onSave(fd);
	}

	render = () => <>
		<Helmet>
			<title>Preferences</title>
		</Helmet>

		<h2 className="account-header">Preferences</h2>
		<Paper className="account-prefs">
			<form id="account-prefs-form" noValidate onSubmit={this.onSave}>
				<Grid container spacing={2}>
					<Grid item sm={6}>
						<h3>Personal</h3>
						<TextField
							variant="outlined"
							label='Name'
							value={this.state.name}
							onChange={(e) => this.setState({
								name: e.currentTarget.value
							})}
						/>
					</Grid>
					<Grid item sm={6}>
						<h3>Newsletter</h3>
						<FormControlLabel
							control={
								<Checkbox
									checked={!!Number(this.state.newsletter)}
									onChange={(e) => this.setState({
										newsletter: e.target.checked ? "1" : "0"
									})}
									color="primary"
								/>
							}
							label="I want to receive the newsletter"
						/>
					</Grid>
					{ /* <Grid item sm={6}>
						<h3>Select Language</h3>
						<RadioGroup name="lang" value={this.state.lang} onChange={(e) => this.setState({
							lang: e.currentTarget.value
						})}>
							<FormControlLabel value="en_CA" control={<Radio color="primary"/>} label="English" />
							<FormControlLabel value="fr_CA" control={<Radio color="primary"/>} label="French" />
						</RadioGroup>
					</Grid> */ }
					<Grid item sm={6}>
						<h3>Select Currency</h3>
						<RadioGroup name="currency" value={this.state.currency} onChange={(e) => this.setState({
							currency: e.currentTarget.value
						})}>
							<FormControlLabel value="CAD" control={<Radio color="primary"/>} label="CAD" />
							<FormControlLabel value="USD" control={<Radio color="primary"/>} label="USD" />
						</RadioGroup>
					</Grid>
					<Grid item sm={12}>
						<Button type="submit" form="account-prefs-form" color="primary" className="account-prefs-savebtn">Save</Button>
					</Grid>
				</Grid>
			</form>
		</Paper>
	</>
}