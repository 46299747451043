import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import LoginContent, { Props } from '../_components/LoginContent';
import { WebsiteLogin } from '../_actions/AuthActions';

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	websiteLogin: (fd:FormData) => {dispatch(WebsiteLogin(fd))},
});

export default connect(
	null, mapDispatchToProps
)(LoginContent);