import { Link } from 'react-router-dom'
import * as React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Icon, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export type Props = {
	loggedIn?: boolean
	temporary?: boolean
	menuOpen?: boolean
	onMenuClose?: () => void
};

const CustomListItem = styled(ListItem) `
	padding: 5px 15px;
`

const CustomListItemIcon = styled(ListItemIcon) `
	min-width: 35px;
	color: '#151744',
`

export default function Sidebar(props:Props) {
	const [tR] = useTranslation('routes')
	
	const getContents = () => {
		let style = props.temporary ? {width: "17rem"} : {};
		return <>
			{ props.temporary && <>
				<List style={{width: "17rem"}} component="nav" subheader={
					<ListSubheader component="div" disableSticky={true}
					style={{
						color: '#151744',
						textTransform: 'uppercase',
						lineHeight: '1',
						paddingTop: '15px',
						paddingBottom: '10px',
						fontWeight: '600',
						fontFamily: 'Quicksand',
	
					}}>Art Fabrics</ListSubheader>
				}>
					<CustomListItem component={Link} to={tR('/')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>home</Icon></CustomListItemIcon>
						<ListItemText>Home</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/create')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>brush</Icon></CustomListItemIcon>
						<ListItemText>Start Designing!</ListItemText>
					</CustomListItem>

					{!props.loggedIn && <>
						<CustomListItem component={Link} to={tR('/auth/login')} onClick={props.onMenuClose}>
							<ListItemText>Login</ListItemText>
						</CustomListItem>

						<CustomListItem component={Link} to={tR('/auth/signup')} onClick={props.onMenuClose}>
							<ListItemText>Sign up</ListItemText>
						</CustomListItem>
					</> }

					<Divider />
					<ListSubheader component="div" disableSticky={true}
					style={{
						color: '#151744',
						textTransform: 'uppercase',
						lineHeight: '1',
						paddingTop: '15px',
						paddingBottom: '10px',
						fontWeight: '600',
						fontFamily: 'Quicksand',
	
					}}>Explore our fabrics</ListSubheader>
					<CustomListItem component={Link} to={tR('/order-sample-pack')} onClick={props.onMenuClose}>
						<ListItemText>Order sample pack</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/fabrics')} onClick={props.onMenuClose}>
						<ListItemText>All fabrics &amp; Prices</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/fabrics/cotton')} onClick={props.onMenuClose}>
						<ListItemText>Cotton</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/fabrics/silk')} onClick={props.onMenuClose}>
						<ListItemText>Silk</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/fabrics/hemp')} onClick={props.onMenuClose}>
						<ListItemText>Hemp</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/fabrics/polyester')} onClick={props.onMenuClose}>
						<ListItemText>Polyester</ListItemText>
					</CustomListItem>

					<Divider />
					<ListSubheader component="div" disableSticky={true}
					style={{
						color: '#151744',
						textTransform: 'uppercase',
						lineHeight: '1',
						paddingTop: '15px',
						paddingBottom: '10px',
						fontWeight: '600',
						fontFamily: 'Quicksand',
	
					}}>Printing method</ListSubheader>
					<CustomListItem component={Link} to={tR('/printing-methods/reactive')} onClick={props.onMenuClose}>
						<ListItemText>Reactive printing</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/printing-methods/sublimation')} onClick={props.onMenuClose}>
						<ListItemText>Sublimation printing</ListItemText>
					</CustomListItem>

					<Divider />
					<ListSubheader component="div" disableSticky={true}
					style={{
						color: '#151744',
						textTransform: 'uppercase',
						lineHeight: '1',
						paddingTop: '15px',
						paddingBottom: '10px',
						fontWeight: '600',
						fontFamily: 'Quicksand',
	
					}}>Our company</ListSubheader>
					<CustomListItem component={Link} to={tR('/about-us')} onClick={props.onMenuClose}>
						<ListItemText>About us</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/contact-us')} onClick={props.onMenuClose}>
						<ListItemText>Contact Us</ListItemText>
					</CustomListItem>
					<CustomListItem component="a" href="https://blog.artfabrics.com/" target="_blank" onClick={props.onMenuClose}>
						<ListItemText>Blog</ListItemText>
					</CustomListItem>
				</List>
				{ props.loggedIn && <Divider /> }
			</> }
			{ props.loggedIn && <>
				<List style={props.temporary ? {width: "17rem"} : {}} component="nav" subheader={
					<ListSubheader component="div" disableSticky={true}
					style={{
						color: '#151744',
						textTransform: 'uppercase',
						lineHeight: '1',
						paddingTop: '15px',
						paddingBottom: '10px',
						fontWeight: '600',
						fontFamily: 'Quicksand',
	
					}}>My Account</ListSubheader>
				}>
					<CustomListItem component={Link} to={tR('/account')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>account_circle</Icon></CustomListItemIcon>
						<ListItemText>Home</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/account/orders')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>shopping_basket</Icon></CustomListItemIcon>
						<ListItemText>Orders</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/account/patterns')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>waves</Icon></CustomListItemIcon>
						<ListItemText>Patterns</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/account/images')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>photo_size_select_actual</Icon></CustomListItemIcon>
						<ListItemText>Images</ListItemText>
					</CustomListItem>
				</List>
				<Divider />
				<List component="nav" subheader={
					<ListSubheader component="div" disableSticky={true}
					style={{
						color: '#151744',
						textTransform: 'uppercase',
						lineHeight: '1',
						paddingTop: '15px',
						paddingBottom: '10px',
						fontWeight: '600',
						fontFamily: 'Quicksand',
	
					}}>Settings</ListSubheader>
				}>
					<CustomListItem component={Link} to={tR('/account/settings/preferences')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>info</Icon></CustomListItemIcon>
						<ListItemText>Preferences</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/account/settings/security')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>security</Icon></CustomListItemIcon>
						<ListItemText>Security</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/account/settings/addresses')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>location_on</Icon></CustomListItemIcon>
						<ListItemText>Addresses</ListItemText>
					</CustomListItem>
					<CustomListItem component={Link} to={tR('/account/settings/creditcards')} onClick={props.onMenuClose}>
						<CustomListItemIcon><Icon>payment</Icon></CustomListItemIcon>
						<ListItemText>Credit Cards</ListItemText>
					</CustomListItem>
				</List>
			</> }
		</>
	}

	return <>
		{ props.temporary ? 
			<Drawer key="header-sidebar" open={props.menuOpen} onClose={props.onMenuClose}
				PaperProps={{
					style: {
						background: '#F3F0FA',
						color: '#151744',
						borderRadius: '0 15px 15px 0',
					}
				}}
			style={{
			}}>
				{getContents()}
			</Drawer>
		: getContents() }
	</>
}
