import React, { useEffect } from 'react';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { CartStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Container, Grid } from '@mui/material';
import TopBanner from '../../Common/_components/TopBanner';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type Props = {
	status?: CartStatus;
}

export default function CardPaid(props:Props) {
	const [tR] = useTranslation('routes')
	const navigate = useNavigate()
	const bouncer = () => {
		if(props.status !== "paid")
			navigate(tR('/cart'));
	}

	useEffect(() => {
		bouncer()
	}, [])

	useEffect(() => {
		bouncer()
	}, [props.status])

	return <>
		<Helmet>
			<title>Order complete</title>
		</Helmet>
		<div className="cart-page cart-page--thankyou">
			<Container maxWidth="lg">
				<Grid container spacing={2}>
					<Grid item md={6}>
						<p className="large">Let's get printing!</p>
						<h1>Thank you for your order</h1>
						<p>Your payment was successfully received and we sent you an email with your order details.</p>
					</Grid>
					<Grid item md={6}>
						<img src={"static/img/cart/thankyou.png?c="+Date.now()} alt="Thank you!"/>
					</Grid>
				</Grid>
			</Container>
		</div>
	</>
}