import { Container, Grid, Hidden } from '@mui/material';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import NotFound404 from '../../Common/_components/NotFound404';
import SidebarContainer from '../../Common/_containers/SidebarContainer';
import Header from '../../Common/_components/Header';
import Footer from '../../Common/_components/Footer';
import AccountHome from '../AccountHome/AccountHome';
import { createSearchParams, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WebsiteStore from 'WebsiteStore';
import { useTranslation } from 'react-i18next';

export default function Account() {
	const [tR] = useTranslation('routes')
	const navigate = useNavigate()
	const location = useLocation()
	const loggedIn = useSelector((state:WebsiteStore) => state.get('userData').get('loggedIn'))
	const validated = useSelector((state:WebsiteStore) => state.get('userData').get('validated'))

	useEffect(() => {
		if(!loggedIn) {
			navigate({
				pathname: tR('/auth/login'),
				search: createSearchParams({
					r: location.pathname
				}).toString()
			})
			return
		}

		if(!validated) {
			navigate(tR('/auth/account-not-validated')+'?r='+encodeURIComponent(location.pathname))
		}
	}, [tR, location, loggedIn, validated])

	return <>
		<Helmet
			titleTemplate="%s | My account | Art Fabrics"
			defaultTitle="My account | Art Fabrics"
		></Helmet>

		<div className="account-container">
			<Header />
			
			<Container className="account-panel">
				<Grid container spacing={4}>
					<Hidden mdDown>
						<Grid item md={3} className="navigation">
							<SidebarContainer />
						</Grid>
					</Hidden>
					<Grid item xs={12} md={9}>
						<div className="account-page-wrapper">
							<Outlet/>
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
		<Footer />
	</>
}