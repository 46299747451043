import * as React from 'react';
import Breadcrumbs from '../../Breadcrumbs/_components/Breadcrumbs';
import PaginationContainer from '@af-common/Common/Pagination/_containers/PaginationContainer';
import PatternContainer from '../_containers/PatternContainer';
import { Grid, CircularProgress } from '@mui/material';
import { Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ItemList from '@af-common/Common/Pagination/ItemList';

export type Props = {
	compactVersion?: boolean
	maxItemsCount?: number
};

const T = ({ children, ...otherProps }: any) => <Trans ns="account" {...otherProps}>{children}</Trans>

export default class Patterns extends React.Component<Props> {
	render() {
		let breadCrumbs = this.props.compactVersion ? <></> : <Breadcrumbs />;
		return <>
			{!this.props.compactVersion && <Helmet>
				<title>Patterns</title>
			</Helmet>}

			<div className="patterns_container">
				{ /* breadCrumbs */}
				<ItemList
					dataType="patterns"
					item={(item) => <PatternContainer item={item} />}
					limit={this.props.maxItemsCount}
					controlled={this.props.compactVersion}
					render={(items, links, loading) =>
						<Grid item xs={12} className="pagination-container">
							<Grid container className="pagination-header" spacing={this.props.compactVersion ? 0 : 2}>
								<Grid item xs={12} md={8}><h3>{this.props.compactVersion ? <T>Recent Patterns</T> : <T>Patterns</T>}</h3></Grid>
							</Grid>
							{loading ? <CircularProgress className="pagination-loader" /> :
								(items ? <>
									<Grid container spacing={2}>
										{items}
									</Grid> { !this.props.compactVersion ? links : null } </> :
									<span className="pagination-noitems">No Results</span>
								)}
						</Grid>
					}
				/>
			</div>
		</>
	}
}
