import Relax, { Props as RelaxProps } from '@af-common/Common/_components/Relax';
import AjaxMiddleware from '@af-common/Common/_middlewares/AjaxMiddleware';
import { GetAccountData } from '@af-common/UserData/_actions/UserAccountActions';
import * as Sentry from '@sentry/react';
import "regenerator-runtime/runtime";
import "core-js/stable";
import Dinero from 'dinero.js';
import 'fetch-everywhere';
import 'abortcontroller-polyfill'
import * as Immutable from 'immutable';
import React, { StrictMode, Suspense } from "react";
import { createRoot, hydrateRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit'
import { connect, Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import config from '../../../config/config.json';
import WebsiteContainer from './WebsiteContainer';
import WebsiteReducer from './WebsiteReducer';
import WebsiteStore from './WebsiteStore';
import createEmotionCache from 'createEmotionCache';
import { CacheProvider } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material';
import WebsiteTheme from 'WebsiteTheme';
import Loader from '@af-common/Common/_components/Loader';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { initReactI18next, useSSR, useTranslation } from 'react-i18next';
import { WebsiteRoutes } from 'Website';
import I18NextXhrBackend from 'i18next-xhr-backend';
import i18next from 'i18next';

declare global {
	interface Window {
		CREATE_CANVAS: Function
		FB: any
		gapi: any
		fbAsyncInit: Function
		onFacebookSignup: Function
		googleAsyncInit: Function
		onGoogleSignup: Function
		__PRELOADED_STORE__: any
	}
}

window.CREATE_CANVAS = () => document.createElement('canvas');

if (config.ENVIRONMENT == "prod" || config.ENVIRONMENT == "staging") {
	Sentry.init({
		dsn: config.sentry.dsn,
		release: config.sentry.release,
		environment: config.ENVIRONMENT
	});
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

let reactRoot = document.getElementById('root');

const init = async () => {
	// Initialize the store
	let initialStore = new WebsiteStore(window.__PRELOADED_STORE__ || {});

	let prefs = initialStore.get("userData").get("userPrefs")

	const i18n = await i18next
		.use(I18NextXhrBackend)
		.use(initReactI18next)
		.init({
			initImmediate: true,
			supportedLngs: ['en-CA','fr-CA'],
			lng: window.LOCALE.replace('_', '-'),
			fallbackLng: 'en-CA',
			ns: ['app','routes'],
			defaultNS: 'app',
			interpolation: {
				escapeValue: false,
			},
			load: 'currentOnly',
			backend: {
				loadPath: '/locale/get/{{lng}}/{{ns}}',
				addPath: '/locale/set/{{lng}}/{{ns}}',
				crossDomain: true,
			},
			keySeparator: '_key_',
			nsSeparator: '_ns_',
			pluralSeparator: '_plural_',
			contextSeparator: '_ctx_',
			saveMissing: false
		}, function (err, t) {
			//Initialized I guess
		});

	Dinero.globalLocale = prefs.get('currency') == 'USD' ? 'en-US' : prefs.get('lang').split('_')[0] + '-CA';

	let store = configureStore({
		reducer: WebsiteReducer,
		middleware: [AjaxMiddleware, thunkMiddleware],
		devTools: process.env.NODE_ENV !== 'production',
		preloadedState: initialStore
	})


	// Get user data
	store.dispatch(GetAccountData());

	//Remove server-side generated css
	/*const jssStyles = document.querySelector('#jss-server-side');
	if (jssStyles) {
		jssStyles.parentElement.removeChild(jssStyles);
	}*/

	const emotionCache = createEmotionCache();

	function App() {
		useSSR(window.PRELOADED_I18NEXT_STORE || {}, window.LOCALE.replace('_', '-'))
		const [tR] = useTranslation('routes');

		//const strictMode = process.env.NODE_ENV !== 'production';
		const strictMode = false;

		const app = <HelmetProvider>
			<Provider store={store}>
				<CacheProvider value={emotionCache}>
					<ThemeProvider theme={WebsiteTheme}>
						<Suspense>
							<Suspense fallback={<Loader noModal/>}>
								<RouterProvider router={sentryCreateBrowserRouter(WebsiteRoutes(tR))} />
							</Suspense>
						</Suspense>
					</ThemeProvider>
				</CacheProvider>
			</Provider>
		</HelmetProvider>;

		return strictMode ? <StrictMode>{ app }</StrictMode> : app;
	}

	// Render the store in root with a provider for redux
	let reactRoot = document.getElementById('root');
	if (reactRoot.hasChildNodes()) {
		let root = hydrateRoot(reactRoot, <App/>)
	} else {
		let root = createRoot(reactRoot)
		root.render(<App/>);
	}
}


init()