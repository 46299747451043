
import * as React from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from "react-i18next";

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export default function InvalidToken() {
	const [tR] = useTranslation('routes')

	return <Container className="auth-page" maxWidth="md">
		<h2><T>Sorry, this token is invalid.</T></h2>
		<p><T>If you're having problems, feel free to <Link to={tR('/contact-us')}>contact us</Link></T></p>
	</Container>
}