import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import IntegrationButtons, { Props } from '../_components/IntegrationButtons';
import { GoogleSignup, FacebookSignup } from '../_actions/AuthActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	facebookReady: store.getIn(['UIData', 'loaders', 'facebook']),
	googleReady: store.getIn(['UIData', 'loaders', 'google'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	googleSignup: (fd:FormData) => {dispatch(GoogleSignup(fd))},
	facebookSignup: (fd:FormData) => {dispatch(FacebookSignup(fd))},
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(IntegrationButtons);