import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Security, { Props } from '../_components/Security';
import { EditUserSecurity, SaveUserSecurity, GetUserSecurity } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	userSecurity: store.getIn(['userData', 'userSecurity'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getSecurity: () => {dispatch(GetUserSecurity())},
	onSave: (fd:FormData) => {dispatch(SaveUserSecurity(fd))},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Security);