import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import CartItem, { Props } from '../_components/CartItem';
import { UpdateCartItem, DeleteCartItem } from '@af-common/UserData/_actions/UserCartActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	fabrics: store.getIn(['UIData', 'fabrics']),
	lang: store.getIn(['userData', 'userPrefs', 'lang']),
	currency: store.getIn(['userData', 'userPrefs', 'currency'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	updateCartItem: (fd:FormData) => dispatch(UpdateCartItem(fd)),
	deleteCartItem: (fd:FormData) => dispatch(DeleteCartItem(fd))
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(CartItem);