import * as React from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Container, Grid, Paper, Typography } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import TranslationProvider from '@af-common/Common/_components/TranslationProvider';

const T = ({ children, ...otherProps }: any) => <Trans ns="pages/sublimation-printing" {...otherProps}>{children}</Trans>

export default function SublimationPrinting() {
	const [t] = useTranslation('pages/sublimation-printing');

	return <>
		<Helmet>
			<title>{t('Sublimation printing')}</title>
			<meta name="description" content={t('Learn about our sublimation printing process which is a permanent way of printing on synthetic fabrics to achieve the best colors.')} />
		</Helmet>

		<div className="sublimation-printing-page-container">
			<Header borderColor="color2" />

			<div className="printing-type-page-banner">
				<Container>
					<div className="content-wrapper">
						<h1><T>Sublimation printing</T></h1>
						<p><T>The most effective method of achieving <strong>permanent prints</strong> on synthetic fabrics.</T></p>
					</div>
				</Container>
			</div>

			<div className="process-wrapper">
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/sublimation/sublimation-print.svg" />
					<div className="process-text">
						<h4><T>Print</T></h4>
						<p><T>The sublimation process actually doesn’t even begin with fabric! It begins with paper, pressing paper specially formulated to transfer ink into fabric. The paper goes through a jumbo printer and gets coated in water-based ink, available in the widest range of achievable print colours. It then comes out the other side covered in the desired print and ready for a heat transfer.</T></p>
					</div>
				</div>
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/sublimation/sublimation-press.svg" />
					<div className="process-text">
						<h4><T>Press</T></h4>
						<p><T>This second step in the process involves heat, and lots of it. The desired fabric and the now printed pressing paper get fed into a fancy pressing machine - essentially a rolling sandwich press - where 400 degrees fahrenheit of heat are applied to the points of contact. The result is that the dyes are transferred from the pressing paper directly onto the fabric. The fabric is now completely washable and 100% fade-proof.</T></p>
					</div>
				</div>
				<div className="process-step">
					<img src="/static/img/pages/printing-methods/sublimation/sublimation-inspect.svg" />
					<div className="process-text">
						<h4><T>Inspect</T></h4>
						<p><T>This final step is super important to us here at Art Fabrics. Step 3, the inspect step, is where we get to take a look at the finished product we’ve made and evaluate whether or not it’s up to our extremely high standards. When we inspect our printed fabrics, we’re doing so on your behalf, so we try to put ourselves in your shoes and determine whether it’s good enough for you. If it’s not acceptable, it doesn’t leave our factory. You can rest easy knowing that we evaluate every item we print as if it were for our own projects!</T></p>
					</div>
				</div>
			</div>

			<div className="floating-boxes-wrapper" style={{
				paddingBottom: '30rem'
			}}>
				<img className="floating-picture" src="/static/img/pages/printing-methods/sublimation/synthetic-products.jpg" style={{
					maxWidth: '52rem',
					top: '5rem'
				}} />
				<div className="floating-text" style={{
					left: '50%',
					marginLeft: '-40rem',
					top: '0'
				}}>
					<h3><T>What is Sublimation Printing?</T></h3>
					<p><T>Sublimation printing is the most proven method of printing on any synthetic fabric, resulting in a 100% permanent print every time. This technologically progressive method of printing uses a digital printed to transfer water-based inks onto paper. The paper is then heat-pressed onto the synthetic fabric of choice. Sublimation printing transfers the dyes onto the fabric at a molecular level, essentially meaning the ink and the fabric are now one indistinguishable item, rather than the dye sitting on the fabric to simply wash off. This process results in a completely permanent, incredibly vibrant printed synthetic fabric.</T></p>
				</div>

				<img className="floating-picture" src="/static/img/pages/printing-methods/sublimation/environment.jpg" style={{
					top: '35rem',
					maxWidth: '50rem',
					marginLeft: '-55rem'
				}} />
				<div className="floating-text" style={{
					left: '50%',
					marginLeft: '-10rem',
					top: '14rem'
				}}>
					<h3><T>Our Environmental Impact Through Sublimation Printing:</T></h3>
					<p><T>Like all factory processes, the sublimation printing process does come hand in hand with a certain degree of waste. Here at Art Fabrics, we want to minimize that level of waste, something we believe you would also want. In an attempt to reduce the ecological footprint of our sublimation printing, we implement as many recycling systems as possible. For example, we upcycle as much waste fabric as possible into resources for around the factory. We also recycle all paper waste, which is an inevitable byproduct of sublimation printing. By utilizing print-on-demand methods we greatly limit all waste that is produced by only printing what is needed for each direct order. By reducing unnecessary production we automatically reduce waste!</T></p>
				</div>
			</div>

			<Footer />
		</div>
	</>
}