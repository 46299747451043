import { EditSignupStatus, SignupStatus } from '@af-common/UIData/_actions/UIDataActions';
import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ConfirmAccount, WebsiteSignup } from '../_actions/AuthActions';
import Signup, { Props } from '../_components/Signup';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	status: store.getIn(['UIData', 'signupStatus'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	websiteSignup: (fd:FormData) => {dispatch(WebsiteSignup(fd))},
	confirmAccount: (fd:FormData) => {dispatch(ConfirmAccount(fd))},
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(Signup);