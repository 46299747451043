import Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { OnToggleRepeatMode } from '@af-create/Tools/_actions/ToolsActions';
import Header from 'Create/_components/Header';
import { OnChangeHeight, OnChangeLockRatio, OnChangeWidth } from '@af-create/Document/_actions/DocumentActions';

const mapStateToProps = (store:Immutable.Record<any>, props: Props) => ({
	repeatMode: store.getIn(['create','tools','repeatMode','type']),
	fabrics: store.getIn(['UIData', 'fabrics']),
	documentWidth: store.getIn(['create','document','width']),
	documentHeight: store.getIn(['create','document','height']),
});

const mapDispatchToProps = (dispatch: Dispatch, props: Props) => ({
	onToggleRepeatMode: () => dispatch(OnToggleRepeatMode()),
	onChangeWidth: (value: number) => dispatch(OnChangeWidth(value)),
	onChangeHeight: (value: number) => dispatch(OnChangeHeight(value)),
	onChangeLockRatio: (value: boolean) => dispatch(OnChangeLockRatio(value)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);