import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import OrderContent, { Props } from '../_components/OrderContent';
import { GetUserOrder } from '@af-common/UserData/_actions/UserAccountActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => {
	let order = store.getIn(['userData', 'currentOrder']);

	return {
		order: order,
		address: order && order.get('id_billing_address') ? store.getIn(['userData', 'userAddresses', order.get('id_billing_address')]) : null
	}
};

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	getOrder: () => {
		
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderContent);