import React, { useEffect } from 'react';
import { PasswordStatus } from '@af-common/UIData/_actions/UIDataActions';
import { Grid, Container } from '@mui/material';
import AwareComponent from '@af-common/Common/_components/AwareComponent';
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

const T = ({children, ...otherProps}:any) => <Trans ns="auth" {...otherProps}>{children}</Trans>

export type Props = {
	setStatus?: (status: PasswordStatus)=>{}
}

export default function ForgotPasswordSent(props:Props) {
	const [t] = useTranslation('auth')

	useEffect(() => {
		props.setStatus("forgot");
	}, [])

	return <>
		<Helmet>
			<title>{t('Forgot your password')}</title>
		</Helmet>

		<Container className="auth-page auth-page--forgot" maxWidth="md">
			<Container maxWidth="sm">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<h2>{t('We sent you an email')}</h2>
					</Grid>
					<Grid item xs={12}>
						<p>{t('You should find an email with the instructions on how to create a new password in your inbox soon.')}</p>
					</Grid>
				</Grid>
			</Container>
		</Container>
	</>
}