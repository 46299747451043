import Immutable from 'immutable';
import { DocumentStatusTypes } from '@af-create/Document/_stores/DocumentStore';
import { AjaxActionStart, AjaxActionStartType } from '@af-common/Common/_actions/AjaxAction';
import CreateReducer from '@af-create/_reducers/CreateReducer';
import CreateStore from '@af-create/_stores/CreateStore';

const LabReducer = (store: Immutable.Record<any>, action: AjaxActionStart): Immutable.Record<any> => {
	store = CreateReducer(store as CreateStore, action as any);

	switch (action.type) {

		case AjaxActionStartType:
			if (action.url == "ajax/create/save-pattern") {
				store = store.setIn(['document', 'status'], DocumentStatusTypes.Saving);
			}
			break;
	}

	return store;
}

export default LabReducer