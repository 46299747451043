import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import CartContent, { Props } from '../_components/CartContent';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	lang: store.getIn(['userData', 'userPrefs', 'lang']),
	currency: store.getIn(['userData', 'userPrefs', 'currency'])
});

export default connect(
	mapStateToProps, null
)(CartContent);