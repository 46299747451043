import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as Immutable from 'immutable';
import PayPal, { Props } from '../_components/PayPal';
import { Toast } from '@af-common/UIData/_actions/UIDataActions';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	currency: store.getIn(['userData', 'userPrefs', 'currency']),
	lang: store.getIn(['userData', 'userPrefs', 'lang']),
	cart_loading: store.getIn(['UIData', 'loaders', 'cart']),
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
	onToast: (text:string) => {dispatch(Toast(text))}
});

export default connect(
	mapStateToProps, mapDispatchToProps
)(PayPal);


