import PromptContainer from '@af-common/Common/_containers/PromptContainer';
import { UserPatternStore } from '@af-common/UserData/_stores/UserPatternsStore';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, IconButton, Menu, MenuItem, MenuList } from '@mui/material';
import * as React from 'react';

// MISSING IMPORTS
const MenuListItem: React.Component<any> = null
const MenuListItemText: React.Component<any> = null
const MenuSurface: React.Component<any> = null

export type Props = {
	onRename?: Function
	onDelete?: Function
	item?: UserPatternStore
	orderProduct?: (id: string, productType: string) => void
};

type State = {
	menuOpen: boolean
	elem: HTMLElement | null
	menuRenameOpen: boolean
	menuDeleteOpen: boolean
}

export default class Pattern extends React.Component<Props> {
	state = {
		menuOpen: false,
		menuRenameOpen: false,
		menuDeleteOpen: false,
	} as State

	private orderPattern = () => {
		this.props.orderProduct(this.props.item.get('id'), 'pattern');
	}

	private setElem = (element: HTMLElement) => {
		if (this.state.elem) return;
		this.setState({ elem: element });
	}

	private onMenuRename = (name: string) => {
		let fd = new FormData();
		fd.append('id', this.props.item.get('id'));
		fd.append('name', name)
		this.props.onRename(fd);
	}

	private onMenuDelete = () => {
		let fd = new FormData();
		fd.append('id', this.props.item.get('id'));
		this.props.onDelete(fd);
	}

	private onMenuRenameOpen = () => this.setState({ menuOpen: false, menuRenameOpen: true });
	private onMenuRenameClose = () => this.setState({ menuRenameOpen: false });

	private onMenuDeleteOpen = () => this.setState({ menuOpen: false, menuDeleteOpen: true });
	private onMenuDeleteClose = () => this.setState({ menuDeleteOpen: false });

	render = () => <Grid item key={this.props.item.get('id')} xs={6} sm={4} md={3}>
		<Card className="condensed">
			<CardContent>
				<CardMedia onClick={this.orderPattern} image={'/user/patterns/pattern/' + this.props.item.get('id')}>
				</CardMedia>
			</CardContent>
			<CardActions>
				<h4>
					{this.props.item.get('name')}
					<IconButton onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}>
						<span className='mdc-menu-surface--anchor' ref={this.setElem}>
							<MoreVert />
						</span>
					</IconButton>
				</h4>
				<Button color="secondary" onClick={this.orderPattern}>Order Fabric</Button>
			</CardActions>
			<Menu onClose={() => this.setState({ menuOpen: false })} open={this.state.menuOpen} anchorEl={this.state.elem}>
				<MenuItem onClick={this.onMenuRenameOpen}><Edit />Rename</MenuItem>
				<MenuItem onClick={this.onMenuDeleteOpen}><Delete />Delete</MenuItem>
			</Menu>
		</Card>

		{/* Rename Dialog */}
		<PromptContainer
			opened={this.state.menuRenameOpen}
			title={"Rename Pattern"}
			input={this.onMenuRename}
			name={this.props.item.get('name')}
			onClose={this.onMenuRenameClose}
			dismissible
		/>

		{/* Delete Confirmation */}
		<PromptContainer
			opened={this.state.menuDeleteOpen}
			title={"Delete \"" + this.props.item.get('name') + "\" ?"}
			message={"Are you sure that you want to delete this pattern?"}
			onClose={this.onMenuDeleteClose}
			actions={{
				"Delete": this.onMenuDelete
			}}
			dismissible
		/>
	</Grid>
}
