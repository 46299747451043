import { UploadUserImage } from '@af-common/UserData/_actions/UserImagesActions';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Images, { Props } from '../_components/Images';

const mapStateToProps = (store:Immutable.Record<any>, props:Props) => ({
	loading: store.getIn(['UIData', 'loaders', 'images'])
});

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	onUpload: (fd:FormData) => dispatch(UploadUserImage(fd)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Images);