import * as React from 'react';
import TopBanner from '../../Common/_components/TopBanner';
import { Container } from '@mui/material';
import Footer from '../../Common/_components/Footer';
import Header from '../../Common/_components/Header';
import { Helmet } from 'react-helmet';

export default function TermsOfService() {
	return <>
		<Helmet>
			<title>Terms of Service</title>
			<meta name="description" content="Terms of service regarding Art Fabrics and the use of our website."/>
		</Helmet>

		<Header/>
		<TopBanner style={{
			backgroundSize: 'cover',
			marginBottom: '2rem',
		}} gradient={"linear-gradient(90deg, rgba(60, 60, 60, 0.5) 0%, rgba(58, 129, 183, 0.5) 100%)"} bgImg={null}>
			<h1 style={{
				fontSize: '4rem',
				marginBottom: '4rem',
			}}>Terms of Service</h1>
		</TopBanner>
		
		<Container maxWidth={"md"}>
		    <p>OVERVIEW</p>
		    <br/>
		    <p>This website is operated by Art Fabrics. Throughout the site, the terms &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to Art Fabrics. Art Fabrics offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.</p>
		    <br/>
		    <p>By visiting our site and/ or purchasing something from us, you engage in our &ldquo;Service&rdquo; and agree to be bound by the following terms and conditions (&ldquo;Terms of Service&rdquo;, &ldquo;Terms&rdquo;), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply &nbsp;to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.</p>
		    <br/>
		    <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.</p>
		    <br/>
		    <p>Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
		    <p>Art Fabrics is an on demand manufacturer of custom printed products. We print fabrics to order with artwork submitted by individuals around the world. </p>
		    <h2>Printing Variations</h2>
		    <p>Print variations can and will occur when printing artwork onto various fabrics.<br/>Variations that may occur within an order or from one order to another include but are not limited to:</p>
		    <ol>
		        <li>Image placement on the product. Placement of your image on the product may vary piece to piece. Some variations may be small and others may be noticeable.</li>
		        <li>Discrepancies between the online Design Lab tool and the final product can occur. The Design Lab provides an approximation of the final product. </li>
		        <li>The colour outcome of your printed fabric may differ from your original art for many reasons including the following:</li>
		    </ol>
		    <ol>
		        <li>Monitor differences. All monitors vary in colour representation. It is beyond our control to match your monitor settings.</li>
		        <li>Colour gamut limitations of our printers. Our print processes have different ranges of colour they can reproduce. We need to translate your artwork using our software into the colour range of our printers. Colours in your artwork that are outside of our achievable gamut will be replaced with the closest colour we can print. Neon colours, for example, are not printable by our systems.</li>
		        <li>We offer a very large range of fabrics to print on. Each fabric has different print results even when subject to the same print process. Some fabrics absorb ink quickly while others are more dense. The base fabrics are all different in their degree of white. Characteristics like these can cause variations in the final product colours.</li>
		        <li>Quality of the submitted artwork. Low quality artwork files will not print as accurately as high resolution images. Please follow our design guidelines when creating your artwork print files.</li>
		        <li>Colour space. We ask for images to be submitted in sRGB. Images set up in other colour spaces will translate incorrectly when printed. Our Design Labs attempt to approximate the print colours so if you do upload a CMYK file, the colours will look wrong in the lab.</li>
		    </ol>
		    <p>Art Fabrics has a hands-off printing system. This means that all incoming artwork files are sent directly to our printing equipment. We do not check for print quality including pixelation before printing. Please refer to our design guidelines when setting up your artwork and make sure it is in the correct colour space and file format. We are not responsible for reprinting items due to poor quality artwork or incorrectly made files.</p>
		    <p>ur issue) please report your concern as outlined below.</p>
		    <h2>Reporting a Concern</h2>
		    <ul>
		        <li>Contact us at info@artfabrics.com with detailed photos of the issue and include the order number.</li>
		        <li>We will try to respond within 48 hrs. We may request additional information.</li>
		        <li>All issues will be evaluated on a case by case basis using the information provided.</li>
		    </ul>
		    <h2>Refunds</h2>
		    <p>Custom printed fabrics are non-refundable. Products with mechanical issues or defaults are usually reprinted or replaced. Please contact us with your concerns and we will handle each concern individually.</p>
		    <h2>Customs Fees, Duties, and Shipment Delays</h2>
		    <p>Art Fabrics worldwide with Canada Post, USPS, and various courier services. Tracking numbers will be provided for shipments whenever possible. Any additional costs or customs fees occurring from shipments (both domestic or international) are not the responsibility of Art Fabrics. International orders under $100 do not have tracking.</p>
		    <p>Please contact your local post office if you are unsure of the customs, duties, or expected transit times for your country.</p>
		    <p>Art Fabrics allows our customers to request alternative and express shipping methods such as UPS or DHL at an additional cost. Please contact us if you would like to use an upgraded shipping method for your order and we will arrange it if possible.</p>
		    <p>Shipments delayed by the shipping service are beyond our control and personal costs resulting from delayed shipments are not the responsibility of Art Fabrics. We commit to shipping your order in a timely manner, doing our best to respect our estimated production times. Occasionally, an order takes longer than predicted due to various reasons including reprints, low inventory, out of stock fabric or technical issues. We will try to contact you if there is a severe delay on your order.</p>
		    <h2>Artwork Rights, Reproduction, and Photographs</h2>
		    <p>All artwork used to create designs through our Fabric Design Tool remains the property of the artist who created and uploaded it. By uploading artwork on our website, you are giving Art Fabrics the permission to print it onto the fabric as intended. You MUST have the rights to reproduce images that are uploaded to the Fabric Design Tool, including for licensed artwork, prints and logos. By uploading designs into the Design Tool, you are certifying that you have the rights to have the artwork printed on fabric. </p>
		    <p>Artwork files are stored on protected servers and are only accessed through secure encrypted connections. For production, the files are transferred to a local server so that they can be sent to the printers and stored temporarily. Once the item has shipped, the files are deleted.</p>
		    <br/>
		    <p>Time to time we take photos of our production studio. These photos will include fabrics with artwork printed on them. We reserve the right to post these photos on our website and social media without implication or without seeking consent first.</p>
		    <p>If you have concerns with any photos posted on Art Fabrics or our social media please get in touch with our customer service or email info@artfabrics.com</p>
		    <h2>Flawed fabrics and Misprints</h2>
		    <p>Sometimes we have fabrics that are incorrectly printed and do not pass our quality control processes. In order to reduce our impact on the environment we donate imperfect fabrics products to shelters, schools, reserves, and daycares. We never donate fabrics to an institution that would resell them.</p>
		    <br/>
		    <p>SECTION 1 - ONLINE STORE TERMS</p>
		    <br/>
		    <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
		    <br/>
		    <p>You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
		    <br/>
		    <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
		    <br/>
		    <p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
		    <br/>
		    <br/>
		    <p>SECTION 2 - GENERAL CONDITIONS</p>
		    <br/>
		    <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
		    <br/>
		    <p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.</p>
		    <br/>
		    <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
		    <br/>
		    <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
		    <br/>
		    <br/>
		    <p>SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</p>
		    <br/>
		    <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
		    <br/>
		    <p>This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.</p>
		    <br/>
		    <br/>
		    <p>SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</p>
		    <br/>
		    <p>Prices for our products are subject to change without notice.</p>
		    <br/>
		    <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.</p>
		    <br/>
		    <p>We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</p>
		    <br/>
		    <br/>
		    <p>SECTION 5 - PRODUCTS OR SERVICES </p>
		    <br/>
		    <p>Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.</p>
		    <br/>
		    <p>We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor&#39;s display of any color will be accurate.</p>
		    <br/>
		    <p>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.</p>
		    <br/>
		    <p>We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
		    <br/>
		    <p>PRINT ON DEMAND CUSTOMIZATION</p>
		    <br/>
		    <p>By creating and ordering fabrics on Art Fabrics and in the Design Tool on our website, you are claiming to have the rights to reproduce uploaded artwork onto fabric. Art Fabrics does not verify artwork rights before printing. As the creator of the fabric, you must have verified you have the rights to reproduce the image on fabric. Art Fabrics is not liable for any damages resulting from infringement of copyright or intellectual property by any user. </p>
		    <br/>
		    <p>Art Fabrics will cooperate with any company making claims of copyright infringement. </p>
		    <br/>
		    <p>Art Fabrics is a website that prints fabric on demand.</p>
		    <p>SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</p>
		    <br/>
		    <p>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.</p>
		    <br/>
		    <p>You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.</p>
		    <br/>
		    <p>For more detail, please review our Returns Policy.</p>
		    <br/>
		    <br/>
		    <p>SECTION 7 - OPTIONAL TOOLS</p>
		    <br/>
		    <p>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.</p>
		    <br/>
		    <p>You acknowledge and agree that we provide access to such tools &rdquo;as is&rdquo; and &ldquo;as available&rdquo; without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.</p>
		    <br/>
		    <p>Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).</p>
		    <br/>
		    <p>We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.</p>
		    <br/>
		    <br/>
		    <p>SECTION 8 - THIRD-PARTY LINKS</p>
		    <br/>
		    <p>Certain content, products and services available via our Service may include materials from third-parties.</p>
		    <br/>
		    <p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>
		    <br/>
		    <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party&#39;s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
		    <br/>
		    <br/>
		    <p>SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</p>
		    <br/>
		    <p>If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, &#39;comments&#39;), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.</p>
		    <br/>
		    <p>We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&rsquo;s intellectual property or these Terms of Service.</p>
		    <br/>
		    <p>You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.</p>
		    <br/>
		    <br/>
		    <p>SECTION 10 - PERSONAL INFORMATION</p>
		    <br/>
		    <p>Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy.</p>
		    <br/>
		    <br/>
		    <p>SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS</p>
		    <br/>
		    <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).</p>
		    <br/>
		    <p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>
		    <br/>
		    <br/>
		    <br/>
		    <p>SECTION 12 - PROHIBITED USES</p>
		    <br/>
		    <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</p>
		    <br/>
		    <p>SECTION 13- INTELLECTUAL PROPERTY</p>
		    <br/>
		    <p>You are responsible for your use of the Site, for any Content you provide, and for any consequences thereof. Any photograph, image, information, know-how, idea, suggestion, material, or other content you post to the Site through use of the Services, including but not limited to: posts in a forum or photo gallery and images uploaded for the creation of custom products (&quot;Products&quot;) to be supplied by Art Fabrics to the customer, will be collectively referred to herein as &quot;Content&quot;. Content that complies with the Terms may be used by the customer to create Products. Posting of Content not owned by you or which you have no permission to submit will be considered a violation of the Terms, and may be a violation of federal, provincial and/or state intellectual property and/or other applicable laws. You specifically represent that any Content is:</p>
		    <p>(i) your original work, or it is properly licensed, and does not violate the copyright or any other personal or property right of any third party; and that</p>
		    <p>(ii) you have obtained any and all releases and permissions necessary for submitting the Content.</p>
		    <br/>
		    <p>You retain your rights to any Content you submit, post or display on or through the Services. You may not use the Services in connection with any web site other than the Site or in any other medium, or with any Content or connection with any activity, that is sexually explicit or suggestive; unnecessarily violent or derogatory of any ethnic, national, racial, gender, religious, professional, sexual orientation, disability or age group; profane or pornographic, promotes alcohol, illegal drugs, tobacco, firearms/weapons (or the use of any of the foregoing); promotes any activities that may appear unsafe or dangerous; promotes any particular political agenda or message; defames, misrepresents, or contains disparaging remarks about other people or companies; infringes on the rights of any third party; or violates any law.</p>
		    <br/>
		    <p>The Services are intended for personal use only. You may not use photos of celebrities or other famous persons. If you create Products or any Content using the Services, you represent and warrant that: (i) you have received all necessary releases and permissions to use, modify and reproduce any photos or materials used to create the Products or that you have personally taken the photos; and (ii) your Creation does not violate, and its use will not violate, the rights of any third party or any applicable law or ordinance. If it is determined that you have, at any time, violated any of the foregoing provisions, you may thereafter be barred from submitting or posting any further material using the Services, and we may pursue any remedies available under these Terms of Use or at law.</p>
		    <br/>
		    <p>Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy.</p>
		    <br/>
		    <p>We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor&#39;s display of any color will be accurate</p>
		    <br/>
		    <p>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited.</p>
		    <br/>
		    <p>We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.</p>
		    <br/>
		    <p>We may contact you or any third parties to verify the information you provide to us, the rights you have secured with respect to the Products, obtain additional commentary, and to film, record and make a record of any statements made by you or others in the course of our conversations or correspondence with you. We may also send you information and notices regarding your Products by email, telephone or other means based on the information you provide to us.</p>
		    <br/>
		    <br/>
		    <p>SECTION 14 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</p>
		    <br/>
		    <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.</p>
		    <br/>
		    <p>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
		    <br/>
		    <p>You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.</p>
		    <br/>
		    <p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided &#39;as is&#39; and &#39;as available&#39; for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.</p>
		    <br/>
		    <p>In no case shall Art Fabrics, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.</p>
		    <br/>
		    <br/>
		    <br/>
		    <p>SECTION 15 - INDEMNIFICATION</p>
		    <br/>
		    <p>You agree to indemnify, defend and hold harmless Art Fabrics and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys&rsquo; fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.</p>
		    <br/>
		    <br/>
		    <p>SECTION 16 - SEVERABILITY</p>
		    <br/>
		    <p>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
		    <br/>
		    <br/>
		    <p>SECTION 17 - TERMINATION</p>
		    <br/>
		    <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
		    <br/>
		    <p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.</p>
		    <br/>
		    <p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
		    <br/>
		    <br/>
		    <p>SECTION 18 - ENTIRE AGREEMENT</p>
		    <br/>
		    <p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</p>
		    <br/>
		    <p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</p>
		    <br/>
		    <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>
		    <br/>
		    <br/>
		    <p>SECTION 19 - GOVERNING LAW</p>
		    <br/>
		    <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of 5534 Ferrier , Montreal, QC, H4P 1M2, Canada.</p>
		    <br/>
		    <br/>
		    <p>SECTION 20 - CHANGES TO TERMS OF SERVICE</p>
		    <br/>
		    <p>You can review the most current version of the Terms of Service at any time at this page.</p>
		    <br/>
		    <p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
		    <br/>
		    <br/>
		    <p>SECTION 21 - CONTACT INFORMATION</p>
		    <br/>
		    <p>Questions about the Terms of Service should be sent to us at info@artfabrics.com.</p>
		    <br/>
		</Container>
		<Footer/>
	</>
}