import * as Immutable from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ForgotPasswordSent, { Props } from '../_components/ForgotPasswordSent';
import { EditPasswordStatus, PasswordStatus } from '@af-common/UIData/_actions/UIDataActions';

const mapDispatchToProps = (dispatch:Dispatch, props:Props) => ({
	setStatus: (status:PasswordStatus) => {dispatch(EditPasswordStatus(status))},
});

export default connect(
	null, mapDispatchToProps
)(ForgotPasswordSent);