import { IUserAddressStore } from '@af-common/UserData/_stores/UserAddressesStore';
import { Button, Card, Grid, Typography, Skeleton } from '@mui/material';
import * as Immutable from 'immutable';
import * as React from 'react';
import AddressFormContainer from '../_containers/AddressFormContainer';
import AddressContainer from '../_containers/AddressContainer';
import ModalContainer from '@af-common/Common/_containers/ModalContainer';

export type Props = {
	id: Number,
	title?: string
	addresses?: Immutable.OrderedMap<Number, Immutable.Record<IUserAddressStore>>
	loading?: boolean
	getAddresses?: Function
	callback?: (id:Number) => void
};

type State = {
	formOpened: boolean
	modalOpened: boolean
}

export default class AddressSelector extends React.Component<Props, State> {
	state = { formOpened: false, modalOpened: false, id: "" }

	componentDidMount() {
		this.props.getAddresses();
	}

	private onSelect = (id:Number) => {
		this.setState({modalOpened: false, formOpened: false});
		if(this.props.callback && id !== this.props.id) this.props.callback(id);
	}

	private selectorClick = () => {
		if(this.props.addresses.size) this.setState({modalOpened: true})
		else this.setState({formOpened: true});
	}

	render() {
		const items = this.props.addresses.entrySeq().map((address: [Number, Immutable.Record<IUserAddressStore>], id: number) =>
			<AddressContainer key={String(address[0])} noEdit id={address[0]} displayMode={'browser'} action={{
				execute: this.onSelect,
				name: "Select",
					icon: "done"
			}}/>
		);

		const title = this.props.title || "Select Address";

		const contentStyle = this.props.loading ? {opacity: 0.5, pointerEvents: 'none' as 'none'} : {};

		return <>
			{ this.props.id && !this.props.loading ? 
				<AddressContainer id={this.props.id} displayMode={'cart'} action={{
					execute: ()=>{this.setState({modalOpened: true})},
					name: "Change Address",
					icon: "cached"
				}}/>
				:
				<Grid item xs={12} className="item-selector">
					<Button variant="outlined" color="primary" onClick={this.selectorClick}>
						<span>{title}</span>
					</Button>
				</Grid>
			}

			<ModalContainer darken opened={this.state.modalOpened} animate onOpen={() => this.setState({modalOpened: true})} onClose={() => this.setState({modalOpened: false})} zIndex={1000} content={
				<div className="library-browser">
					<Card elevation={24}>
						<Grid container className="library-browser-header">
							<Grid item xs={12}>
								<h3>Saved Addresses</h3>
							</Grid>
						</Grid>
						<div className="library-browser-body">
							<Grid container className="pagination-container" style={contentStyle}>
								<Grid item xs={12} className="pagination-container user-cards">
									<Grid container spacing={2}>
										{this.props.loading ? 
											<>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
												<Grid item xs={12} sm={6} lg={4}><Skeleton variant="rectangular" width={"100%"} height={270}/></Grid>
											</> 
										: items.size ? items :
											<Typography className="noitems" variant="body1">No Results</Typography>
										}
									</Grid>
								</Grid>
							</Grid>
						</div>
						<div className="library-browser-footer">
							<Button disabled={this.props.loading} variant="contained" color="primary" style={{marginRight: '1rem'}} onClick={()=>{
								this.setState({
									formOpened: true
								})
							}}>Add Address</Button>
							<Button variant="contained" color="primary" className="modal-close">Cancel</Button>
						</div>
					</Card>
				</div>
			}/>

			<AddressFormContainer opened={this.state.formOpened} 
				onClose={()=>{
					this.setState({
						formOpened: false
					})
				}}
				callback={(addresses: (IUserAddressStore & {id:number})[])=>{
					if(addresses.length){
						this.onSelect(addresses[0].id);
					}
				}}
			/>
		</>
	}
}