import * as React from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import PaginationContainer from '@af-common/Common/Pagination/_containers/PaginationContainer';
import Breadcrumbs from '../../Breadcrumbs/_components/Breadcrumbs';
import { Button, Grid, CircularProgress } from '@mui/material';
import Loader from '@af-common/Common/_components/Loader';
import { Helmet } from 'react-helmet';
import Image from './Image';
import ItemList from '@af-common/Common/Pagination/ItemList';

export type Props = {
	compactVersion?: boolean
	maxItemsCount?: number
	onUpload?: Function
	loading?: boolean
}

type State = {
	isDragActive: boolean
}

export default class Images extends React.Component<Props, State> {
	private dropzoneRef:React.RefObject<DropzoneRef>;

	state = {
		isDragActive: false,
	} as State

	constructor(props:Props, state:State){
		super(props);
		this.dropzoneRef = React.createRef();
	}

	private onDragEnter = () => this.setState({isDragActive: true});
	private onDragLeave = () => this.setState({isDragActive: false});

	private onUpload = (files:File[]) => {
		this.setState({isDragActive: false});
		let file:File;
		while(file = files.splice(0, 1)[0]){
			let fd = new FormData();
			fd.append("file", file);
			this.props.onUpload(fd);
		}
	}

	private openDropzone = () => {
		if(!this.dropzoneRef.current) return;
		this.dropzoneRef.current.open();
	}

	render() {
		let breadCrumbs = this.props.compactVersion ? <></> : <Breadcrumbs />;
		return <>
			<Helmet>
				<title>Images</title>
			</Helmet>

			<div className={"images-container" + (this.props.loading ? " images-container--loading" : "")}>
				{ /* breadCrumbs */ }
				<Dropzone ref={this.dropzoneRef} noClick multiple accept={{'image/*': ['.png', '.gif', '.jpeg', '.jpg']}} onDrop={acceptedFiles => this.onUpload(acceptedFiles)} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave}>
					{({getRootProps, getInputProps}) => (
						<div {...getRootProps()}>
							<input {...getInputProps({disabled: false})} />
							<ItemList
								dataType="images"
								item={(item) => <Image item={item}/>}
								render={(items, links, loading) => 
									<Grid item xs={12} className="pagination-container">
										<Grid container className="pagination-header" spacing={this.props.compactVersion ? 0 : 2}>
											<Grid item xs={12} md={8}>
												<h3>Your Images</h3>
												{!this.props.compactVersion && 
													<Button color="secondary" onClick={this.openDropzone} className="pagination-action-button" startIcon={<i className="material-icons">cloud_upload</i>}>Upload</Button>
												}
											</Grid>
											<Grid item xs={12} md={4}>
												{/*!this.props.compactVersion && search*/}
											</Grid>
										</Grid>
										{loading ? <CircularProgress className="pagination-loader"/> : 
										(items ? <>
											<Grid container spacing={2}>
												{items}
											</Grid> {links} </> :
											<span className="pagination-noitems">No Results</span>
										)}
									</Grid>
								}
							/>
							{/*<PaginationContainer
								dataType={'images'}
								{...(this.props.compactVersion && { compactVersion: this.props.compactVersion })}
								{...(this.props.maxItemsCount && { maxItemsCount: this.props.maxItemsCount })}
								item={<Image />}
								render={(items:JSX.Element, search:JSX.Element, links:JSX.Element, loading:boolean) => 
									<Grid item xs={12} className="pagination-container">
										<Grid container className="pagination-header" spacing={this.props.compactVersion ? 0 : 2}>
											<Grid item xs={12} md={8}>
												<h3>Your Images : </h3>
												{!this.props.compactVersion && 
													<Button color="secondary" onClick={this.openDropzone} className="pagination-action-button" startIcon={<i className="material-icons">cloud_upload</i>}>Upload</Button>
												}
											</Grid>
											<Grid item xs={12} md={4}>
												{!this.props.compactVersion && search}
											</Grid>
										</Grid>
										{loading ? <CircularProgress className="pagination-loader"/> : 
										(items ? <>
											<Grid container spacing={2}>
												{items}
											</Grid> {links} </> :
											<span className="pagination-noitems">No Results</span>
										)}
									</Grid>
								}
							/>*/ }
							{this.state.isDragActive &&
								<div className="dropzone-active">
									<div className="dropzone-text">Drop your files here</div>
								</div>
							}
						</div>
					)}
				</Dropzone>
			</div>
			{ this.props.loading && <Loader noModal /> }
		</>
	}
}
